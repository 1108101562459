import React, { useContext, useEffect, useState } from 'react'
import {
    Container,
    Form,
    Button,
    Row,
    Col,
    Spinner
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useAuth } from '../../context/AuthContext';
import { UserContext } from '../../context/UserContext';
import { types } from '../../types/types';
import Table from 'react-bootstrap/Table';
import './users.css';
import { ClientContext } from '../../context/ClientContext';
import { useNavigate } from 'react-router-dom';
import { useRole } from '../../context/RoleContext';
import { Badge } from '../../components/Badge/Badge';
import COLORS from '../../constants/colors';
import { sweetAlertProps } from '../../constants/sweetAlertProps';
import { FILTER_ATTRIBUTES } from '../../constants/filter';
import ROLES from '../../constants/roles';
import { setMessage, setShowMessage, setShowModal } from '../../actions/modal';
import { MODAL_TYPES } from '../../constants/modals';
import { useDispatch, useSelector } from 'react-redux';
import { ModalActivateUsers } from '../../components/Modal/ModalActivateUsers';
import moment from 'moment';
import { BADGE_TYPES } from '../../constants/badgesTypes';
import { DICTIONARY } from '../../constants/dictionary';
import { translate } from '../../utils/translate';

const IndexUser = (props) => {

    const {
        showAsSection = false,
        isForDetailSentNotification = false,
        isActive = '',
        hideState = false } = props;
    const dispatch = useDispatch();

    const { current: { dictionary } } = useSelector((state) => state.translation);
    let isAdmin = false;
    let isCustomerOrAdmin = false;
    const userRole = sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : '';

    if (userRole !== '') {
        isAdmin = userRole === ROLES.ADMIN;
        isCustomerOrAdmin = userRole === ROLES.CUSTOMER || userRole === ROLES.ADMIN;
    }
    const paramsForLoaded = '&_s=841110&_l=1';
    const limit = 25;

    const {
        dispatch: dispatchUser,
        state: userState,
        setSelectedUsers,
        deleteUser,
        getClientNameById,
        filterUsers,
        getUsersWithPagination
    } = useContext(UserContext);


    const { users, selectedUsers, filteredUsers, isUsingFilter, filters } = userState;
    const { state: stateAuth } = useAuth();
    const { dispatch: dispatchRole, getAllRoles } = useRole();
    const { state: stateClient, getAllClients } = useContext(ClientContext);
    let usersData = (filteredUsers && filteredUsers.length > 0) || isUsingFilter ? filteredUsers : users;

    const { notification } = useSelector((state) => state);
    const { usersReceiverList } = notification;

    if (isForDetailSentNotification) {
        usersData = usersReceiverList;
    }

    const { clients } = stateClient;

    const [selectAllChecked, setSelectAllChecked] = useState(false);

    const selectAllUsers = (ev) => {
        const { checked } = ev.target;
        setSelectAllChecked(checked);
        if (checked) dispatchUser({ type: types.userSelectAll });
        if (!checked) dispatchUser({ type: types.userUnselectAll });
    }

    const setSelectedUserForDelete = (ev, userId) => {
        const { checked } = ev.target;
        if (checked) dispatchUser({ type: types.userSelectOne, payload: userId });
        if (!checked) dispatchUser({ type: types.userUnselectOne, payload: userId });
        setSelectedUsers(dispatchUser, userId);
    }

    const handleDeleteUser = () => {
        if (selectedUsers && selectedUsers.length > 0) {
            Swal.fire({
                ...sweetAlertProps,
                html: translate(DICTIONARY.MESSAGE.USER_DELETE, dictionary),
                showDenyButton: true,
                confirmButtonText: translate(DICTIONARY.BUTTON.CONFIRM_DELETE, dictionary),
                denyButtonText: translate(DICTIONARY.BUTTON.CANCEL_DELETE, dictionary),
                confirmButtonColor: COLORS.buttonRed,
                denyButtonColor: COLORS.button
            }).then((result) => {
                if (result.isConfirmed) {
                    selectedUsers.forEach(async (sUser) => {
                        await deleteUser(dispatchUser, stateAuth.token, sUser);

                    });
                    // if (countDeleted === selectedUsers.length) {
                    //     window.location.reload();
                    // }

                    // navigate('/user');
                    // window.location.replace('/user');
                } else if (result.isDenied) {
                }
            });
        }
    }

    let timer = null;
    const handleFilter = (attribute, search) => {
        const _payload = {
            attribute,
            search,
            dispatchUser,
            isActive
        }
        const delayTime = 500;
        clearTimeout(timer);
        timer = setTimeout(() => {
            dispatchUser({ type: types.userFilter, payload: _payload });
        }, delayTime);
    }

    let navigate = useNavigate();

    const handleEditUser = (idUser) => {
        dispatchUser({
            type: types.userResetData
        });
        window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/user/edit/${idUser}?_i=${stateAuth.token}${paramsForLoaded}`;
        // navigate(`/user/edit/${idUser}?_i=${stateAuth.token}${paramsForLoaded}`, { replace: false });
    }

    const nextPage = sessionStorage.getItem('nextPage');
    const handleScroll = () => {
        const allUsersLoaded = sessionStorage.getItem('allUsersLoaded');
        const actualScroll = window.innerHeight + window.scrollY + 400;
        if (!isForDetailSentNotification) {
            if (!!allUsersLoaded && actualScroll >= document.body.offsetHeight) {
                const userPaginationObj = {
                    token: stateAuth.token,
                    limit,
                    page: nextPage,
                    userRole: sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : '',
                    isActive
                }
                getUsersWithPagination(userPaginationObj);
                window.removeEventListener('scroll', handleScroll);
            }
        }
    }

    useEffect(() => {
        if (isAdmin) {
            getAllClients(stateAuth.token);
        }
    }, []);

    useEffect(() => {
        getAllRoles(dispatchRole, stateAuth.token);
        if (!isUsingFilter) {
            if (!users) {
                sessionStorage.setItem('allUsersLoaded', false);

                // token, limit, page
                const userPaginationObj = {
                    token: stateAuth.token,
                    limit,
                    page: 1,
                    userRole: sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : '',
                    isActive
                }
                getUsersWithPagination(userPaginationObj);
            }
        } else {
            if (filters) {
                const _filters = {
                    ...filters,
                    isActive
                }
                filterUsers(dispatchUser, _filters);
            }
        }
    }, [isUsingFilter]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [nextPage]);



    const handleOpenModalActivateUsers = () => {
        dispatch(setShowModal(
            MODAL_TYPES.ACTIVATE_USERS,
            true
        ))
    }

    const { modals, showMessage } = useSelector((state) => state.modal);
    const modalFilteredForMessage = modals.filter((item) => item.id === MODAL_TYPES.ACTIVATE_USERS);
    const { message } = modalFilteredForMessage[0];

    useEffect(() => {
        if (message !== '' && showMessage) {
            Swal.fire({
                ...sweetAlertProps,
                icon: 'success',
                text: message,
                confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
                confirmButtonColor: COLORS.button
            }).then(async (sweet) => {
                if (sweet.isConfirmed) {
                    dispatch(setShowMessage(false));
                    dispatch(setMessage({
                        id: MODAL_TYPES.ACTIVATE_USERS,
                        message: '',
                        icon: 'success'
                    }));
                    if ((filteredUsers && filteredUsers.length > 0) || isUsingFilter) {
                        await filterUsers(dispatchUser, filters);
                        await setSelectAllChecked(false);
                    } else {
                        window.location.reload();

                    }
                }
            });
        }
    }, [message, showMessage]);

    const handleCreateUser = () => {
        sessionStorage.removeItem('u:start');

        window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/user/create?_i=${stateAuth.token}${paramsForLoaded}`;
    }
    return (
        <>
            <Container
                fluid
                className={`${showAsSection ? 'notification-indexUser' : ''}`}
            >
                {
                    !showAsSection &&
                    <div className="row mb-5">
                        <div className="col-xl-4 col-lg-5 col-md-4 col-xs-5 ">
                            <div className='section-name'>
                                <span className="material-icons-round section-icon">
                                    people
                                </span>
                                <div>{translate(DICTIONARY.TITLE.USER_INDEX, dictionary)}</div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-2 col-md-2 col-xs-1 ">

                        </div>
                        {
                            isCustomerOrAdmin &&
                            <div className="col-xl-4 col-lg-5 col-md-6 col-xs-6 ">
                                <Button
                                    variant="light"
                                    className="w-25 float-end button-delete button"
                                    onClick={handleDeleteUser}
                                    disabled={!selectedUsers || selectedUsers.length === 0}
                                >{translate(DICTIONARY.BUTTON.DELETE, dictionary)}</Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleCreateUser}
                                    className="w-25 float-end button"
                                >{translate(DICTIONARY.BUTTON.CREATE, dictionary)}</Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleOpenModalActivateUsers}
                                    className="float-end"
                                    disabled={!selectedUsers || selectedUsers.length === 0}
                                >{translate(DICTIONARY.BUTTON.ACTIVATE_DEACTIVATE, dictionary)}</Button>
                            </div>
                        }
                    </div>
                }

                <Row>
                    <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        xxl="12"
                    >
                        {
                            !usersData &&
                            <>
                                <div className="spinner">
                                    <Spinner animation="border" role="status"></Spinner>
                                </div>
                                <div className="spinner">
                                    <div className="spinner-message">{translate(DICTIONARY.LABEL.USER_LOADING, dictionary)}</div>

                                </div>
                            </>
                        }
                        {
                            usersData &&
                            <Table borderless responsive className="table-launcher">
                                <thead>
                                    <tr>
                                        {
                                            !isForDetailSentNotification &&
                                            <th className="tableHeader-filter__checkbox">
                                                <Form.Check type="checkbox" onChange={selectAllUsers} checked={selectAllChecked} />
                                            </th>
                                        }
                                        <th>
                                            <div className="tableHeader-filter">
                                                {
                                                    isForDetailSentNotification
                                                        ?
                                                        <div>{translate(DICTIONARY.LABEL.USER, dictionary)}</div>
                                                        :
                                                        <div className="tableHeader-filter__input">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={translate(DICTIONARY.PLACEHOLDER.USER, dictionary)}
                                                                onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.USERNAME, ev.target.value)}
                                                                defaultValue={filters?.username && filters?.username !== '' ? filters?.username : ''}
                                                            />
                                                        </div>
                                                }
                                            </div>
                                        </th>

                                        <th>
                                            <div className="tableHeader-filter">
                                                {
                                                    isForDetailSentNotification
                                                        ?
                                                        <div>{translate(DICTIONARY.LABEL.NAME, dictionary)}</div>
                                                        :
                                                        <div className="tableHeader-filter__input">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={translate(DICTIONARY.PLACEHOLDER.NAME, dictionary)}
                                                                onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.NAME, ev.target.value)}
                                                                defaultValue={filters?.name && filters?.name !== '' ? filters?.name : ''}
                                                            />
                                                        </div>
                                                }
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tableHeader-filter">
                                                {
                                                    isForDetailSentNotification
                                                        ?
                                                        <div>{translate(DICTIONARY.LABEL.EMAIL, dictionary)}</div>
                                                        :
                                                        <div className="tableHeader-filter__input">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={translate(DICTIONARY.PLACEHOLDER.EMAIL, dictionary)}
                                                                onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.EMAIL, ev.target.value)}
                                                                defaultValue={filters?.email && filters?.email !== '' ? filters?.email : ''}
                                                            />
                                                        </div>
                                                }
                                            </div>
                                        </th>
                                        <th>
                                            <div className="tableHeader-filter">
                                                {
                                                    isForDetailSentNotification
                                                        ?
                                                        <div>{translate(DICTIONARY.LABEL.ROLE, dictionary)}</div>
                                                        :
                                                        <div className="tableHeader-filter__input">
                                                            <Form.Control
                                                                type="text"
                                                                placeholder={translate(DICTIONARY.PLACEHOLDER.ROLE, dictionary)}
                                                                onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.ROLE, ev.target.value)}
                                                                defaultValue={filters?.role && filters?.role !== '' ? filters?.role : ''}
                                                            />
                                                        </div>
                                                }
                                            </div>
                                        </th>
                                        {
                                            isAdmin &&
                                            <th>
                                                <div className="tableHeader-filter">
                                                    {
                                                        isForDetailSentNotification
                                                            ?
                                                            <div>{translate(DICTIONARY.LABEL.CLIENT, dictionary)}</div>
                                                            :
                                                            <div className="tableHeader-filter__input">
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder={translate(DICTIONARY.PLACEHOLDER.CLIENT, dictionary)}
                                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.CUSTOMER, ev.target.value)}
                                                                    defaultValue={filters?.customer && filters?.customer !== '' ? filters?.customer : ''}
                                                                />
                                                            </div>
                                                    }
                                                </div>
                                            </th>
                                        }
                                        {
                                            (!isForDetailSentNotification && !hideState) &&
                                            <th className="tableHeader-filter">
                                                {translate(DICTIONARY.LABEL.STATE, dictionary)}
                                            </th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        usersData && usersData.length > 0 && usersData.map((user, index) => {

                                            const {
                                                customerName: _customerName,
                                                email,
                                                firstName,
                                                id,
                                                lastName,
                                                role,
                                                checked,
                                                username,
                                                currentlyIsActive,
                                                isActiveUpdateDate,
                                                newIsActive
                                            } = user;
                                            const fullName = `${firstName} ${lastName}`;

                                            const dateDeactivate = moment(isActiveUpdateDate).format('DD/MM/YYYY');
                                            let customerName = _customerName;

                                            if (!customerName) {
                                                customerName = getClientNameById(clients, user?.customerId ? user?.customerId : -1);
                                            }

                                            const dateIsAfter = moment(isActiveUpdateDate).isSameOrAfter(moment());

                                            let badgeType = BADGE_TYPES.ACTIVE;
                                            let badgeText = translate(DICTIONARY.LABEL.ACTIVATE, dictionary);
                                            if (currentlyIsActive && newIsActive) {

                                            } else if (dateIsAfter) {
                                                badgeType = BADGE_TYPES.ACTIVE_UNTIL;
                                                badgeText = `${translate(DICTIONARY.LABEL.ACTIVE_UNTIL, dictionary)} ${dateDeactivate}`;
                                            } else if (!newIsActive) {
                                                badgeType = BADGE_TYPES.DEACTIVE;
                                                badgeText = translate(DICTIONARY.LABEL.DEACTIVATE, dictionary);
                                            }
                                            return (
                                                <tr
                                                    className="table-hoverRow"
                                                    key={`${id}_${index}`}
                                                >
                                                    {
                                                        !isForDetailSentNotification &&
                                                        <td >
                                                            <div
                                                                className="table-checkbox">
                                                                <Form.Check
                                                                    className="table-checkbox"
                                                                    type="checkbox"
                                                                    name={`user[${id}]`}
                                                                    checked={checked}
                                                                    onChange={(ev) => setSelectedUserForDelete(ev, id)}
                                                                />
                                                            </div>

                                                        </td>
                                                    }
                                                    <td
                                                        onClick={() => handleEditUser(id)}>
                                                        {username}
                                                    </td>
                                                    <td
                                                        onClick={() => handleEditUser(id)}>
                                                        {fullName}
                                                    </td>

                                                    <td
                                                        onClick={() => handleEditUser(id)}>
                                                        {email}
                                                    </td>
                                                    <td
                                                        onClick={() => handleEditUser(id)}>
                                                        <Badge text={role} />
                                                    </td>
                                                    {
                                                        isAdmin &&
                                                        <td
                                                            onClick={() => handleEditUser(id)}>
                                                            {customerName}
                                                        </td>
                                                    }
                                                    {
                                                        (!isForDetailSentNotification && !hideState) &&
                                                        <td>
                                                            <Badge
                                                                text={badgeText}
                                                                type={badgeType}
                                                            />
                                                        </td>
                                                    }
                                                </tr>

                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row >
            </Container>
            <ModalActivateUsers
                id={MODAL_TYPES.ACTIVATE_USERS}
                title={translate(DICTIONARY.TITLE.ACTIVATE_DEACTIVATE_USER, dictionary)}
                icon="people"
            />
        </>
    )
}

// const IndexUser = React.memo(_IndexUser);
export default IndexUser;