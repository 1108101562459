import moment from 'moment';
import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import * as rdrLocales from 'react-date-range/dist/locale';
import { DATES_VARIABLES } from '../../constants/filter';

export const CalendarPicker = (props) => {

    const {
        extraClass = '',
        callBackFilter = () => { return false },
        callBackToggleCalendar = () => { return false },
        attribute
    } = props;
    const [date, setDate] = useState(null);

    const handleOnChangeDate = (item) => {
        const selectedDate = `${moment(item).format(DATES_VARIABLES.dateFormat)}`;
        setDate(item);
        // const { startDate, endDate } = item.selection;
        // dispatch(setDatePickerSetStartDate(startDate));
        // dispatch(setDatePickerSetEndDate(endDate));
        // // dispatch(setDatePickerToggle(false));
        callBackToggleCalendar();
        callBackFilter(
            attribute,
            selectedDate
        );
    }

    return (
        <div style={{ display: 'flex', flexFlow: 'column nowrap' }}>
            <Calendar
                onChange={handleOnChangeDate}
                date={date}
                locale={rdrLocales.es}
                className={`calendarPicker  ${extraClass}`}

            />
        </div>
    )
}
