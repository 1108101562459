import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import {
    Container,
    Image,
    Form,
    Button
} from 'react-bootstrap';
import { APP_SECTIONS, userAccessManager } from '../../components/UI/Launcher/checkPermissions';
import { getUserData } from '../../components/UI/Launcher/fetchLauncher';
import { useAuth } from '../../context/AuthContext';
import { ClientContext } from '../../context/ClientContext';
import { types } from '../../types/types';
import COLORS from '../../constants/colors';
import { sweetAlertProps } from '../../constants/sweetAlertProps';
import { useNavigate } from 'react-router-dom';
import { MODAL_TYPES } from '../../constants/modals';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage, setShowMessage, setShowModal } from '../../actions/modal';
import { ModalActivateUsers } from '../../components/Modal/ModalActivateUsers';
import moment from 'moment';
import { DICTIONARY } from '../../constants/dictionary';
import { translate } from '../../utils/translate';

const IndexClients = () => {

    const dispatch = useDispatch();
    const { current: { dictionary } } = useSelector((state) => state.translation);
    const paramsForLoaded = '&_s=841110&_l=1';
    const { state: { token } } = useAuth();
    const { dispatch: dispatchClient, state: stateClient, getAllClients, setSelectedClients, deleteClient } = useContext(ClientContext);
    const { clients, filteredClients, selectedClients } = stateClient;
    const clientData = filteredClients && filteredClients.length > 0 ? filteredClients : clients;


    const handleFilter = (search) => {

        dispatchClient({ type: types.clientFilter, payload: search })
    }

    const handleDelete = () => {
        if (selectedClients) {
            Swal.fire({
                ...sweetAlertProps,
                html: translate(DICTIONARY.MESSAGE.CLIENT_DELETE, dictionary),
                showDenyButton: true,
                confirmButtonText: translate(DICTIONARY.BUTTON.CONFIRM_DELETE, dictionary),
                denyButtonText: translate(DICTIONARY.BUTTON.CANCEL_DELETE, dictionary),
                confirmButtonColor: COLORS.buttonRed,
                denyButtonColor: COLORS.button
            }).then((result) => {
                if (result.isConfirmed) {
                    selectedClients.forEach(async (sClient) => {
                        await deleteClient(dispatchClient, token, sClient);
                    });

                    // window.location.replace(`/client?_i=${token}${paramsForLoaded}`);
                    window.location.reload();
                } else if (result.isDenied) {
                }
            });
        }
    }

    const setSelectedClientForDelete = (clientId) => {
        setSelectedClients(dispatchClient, clientId);
    }
    let navigate = useNavigate();
    const handleClickClient = (id) => {
        window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/client/edit/${id}?_i=${token}${paramsForLoaded}`;
        // navigate(`/client/edit/${id}?_i=${token}${paramsForLoaded}`, { replace: false });
    }

    useEffect(() => {
        getAllClients(token);
    }, []);

    const [showMenuAdmin, setShowMenuAdmin] = useState(false);

    useEffect(() => {
        const getUserFromToken = async () => {
            const userData = await getUserData(token);
            if (userData) {
                const allowMenuAdmin = userAccessManager(APP_SECTIONS.deleteCreateCustomer, userData.role);
                setShowMenuAdmin(allowMenuAdmin);

            }
        }
        getUserFromToken();
    }, [token]);

    const handleOpenModalActivateClients = () => {
        dispatch(setShowModal(
            MODAL_TYPES.ACTIVATE_CUSTOMERS,
            true
        ))
    }

    const { modals, showMessage } = useSelector((state) => state.modal);
    const modalFilteredForMessage = modals.filter((item) => item.id === MODAL_TYPES.ACTIVATE_CUSTOMERS);
    const { message, icon } = modalFilteredForMessage[0];

    useEffect(() => {
        if (message !== '' && showMessage) {
            Swal.fire({
                ...sweetAlertProps,
                icon,
                text: message,
                confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
                confirmButtonColor: COLORS.button
            }).then((sweet) => {
                if (sweet.isConfirmed) {
                    dispatch(setShowMessage(false));
                    dispatch(setMessage({
                        id: MODAL_TYPES.ACTIVATE_USERS,
                        message: '',
                        icon: 'success'
                    }));
                }
            });
        }
    }, [message, showMessage]);


    return (
        <>
            <Container
                fluid
            >
                <div className="row mb-5">
                    <div className="col-xl-4 col-lg-3 col-md-4 col-xs-5">
                        <div className='section-name'>
                            <span className="material-icons-round section-icon">
                                business
                            </span>
                            <div>{translate(DICTIONARY.TITLE.CLIENT_INDEX, dictionary)}</div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 col-xs-1">
                        <div className="input-group flex-nowrap">
                            <Form.Control
                                type="text"
                                placeholder={translate(DICTIONARY.PLACEHOLDER.SEARCHBAR, dictionary)}
                                onInput={(ev) => handleFilter(ev.target.value)}
                            />
                            <div className="input-search-icon">
                                <span className="material-icons-round">
                                    search
                                </span>
                            </div>
                        </div>
                    </div>
                    {
                        showMenuAdmin &&
                        <div
                            className="col-xl-4 col-lg-5 col-md-6 col-xs-6 offset-xl-0 offset-lg-0 offset-md-6 offset-xs-6"

                        >
                            <Button
                                variant="light"
                                className="w-25 float-end ideauto-button button-delete"
                                onClick={handleDelete}
                                disabled={!selectedClients || selectedClients.length === 0}
                            >{translate(DICTIONARY.BUTTON.DELETE, dictionary)}</Button>
                            <Button
                                variant="secondary"
                                href={`${process.env.REACT_APP_BROWSER_ROUTE}/client/create?_i=${token}${paramsForLoaded}`}
                                className="w-25 float-end button"
                            >{translate(DICTIONARY.BUTTON.CREATE, dictionary)}</Button>
                            <Button
                                variant="secondary"
                                onClick={handleOpenModalActivateClients}
                                className="float-end"
                                disabled={!selectedClients || selectedClients.length === 0}
                            >{translate(DICTIONARY.BUTTON.ACTIVATE_DEACTIVATE, dictionary)}</Button>
                        </div>
                    }
                </div>
                <div className="row">
                    {
                        clientData && clientData.map((client, index) => {
                            const { id, logo, name, currentlyIsActive, isActiveUpdateDate, newIsActive } = client;
                            const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
                            const logoUrl = logo ? `${apiURL}/customers/uploads/photo/${id}?token=${token}` : `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/logoIdeauto.png`;

                            const dateDeactivate = moment(isActiveUpdateDate).format('DD/MM/YYYY');
                            const dateIsSameOrBefore = moment(isActiveUpdateDate).isSameOrBefore(moment());
                            const dateIsAfter = moment(isActiveUpdateDate).isAfter(moment());
                            const classDeactivate = dateIsSameOrBefore && !currentlyIsActive ? 'deactivateClient' : '';

                            const messageDeactivate = dateIsAfter ?
                                `${translate(DICTIONARY.LABEL.DEACTIVATE_SCHEDULED, dictionary)} ${dateDeactivate}`
                                :
                                `${translate(DICTIONARY.LABEL.DEACTIVATE_FROM, dictionary)} ${dateDeactivate}`

                            return (
                                <div key={`client_${index}`} className="client-wraper col-xl-3 col-lg-4 col-md-6 col-xs-12">
                                    <div className={`client ${classDeactivate}`}>
                                        <Form.Check type="checkbox" onChange={() => setSelectedClientForDelete(id)} />
                                        <div className="client-logo">
                                            <div
                                                className="client-logo-link"
                                                onClick={() => handleClickClient(id)}
                                            >
                                                {/* <Link to={`/client/edit/${id}`}> */}
                                                <Image src={logoUrl} className="client-logo-image" />
                                                {/* </Link> */}
                                            </div>
                                        </div>
                                        <div className='client-name-wrapper'>
                                            {
                                                !newIsActive &&
                                                <div className='client-name client-deactivate'>{messageDeactivate}</div>
                                            }
                                            <div className='client-name'>{name}</div>
                                        </div>
                                    </div>
                                </div>

                            )
                        })
                    }
                </div>
            </Container>
            <ModalActivateUsers
                id={MODAL_TYPES.ACTIVATE_CUSTOMERS}
                title={translate(DICTIONARY.TITLE.ACTIVATE_DEACTIVATE_CLIENT, dictionary)}
                icon="business"
            />
        </>
    )
}

export default IndexClients