import React, { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ProductAvailableList } from "../../components/UI/ProductAvailableList/ProductAvailableList";
import { useAuth } from "../../context/AuthContext";
import { ClientContext } from "../../context/ClientContext";
import { useColor } from "../../context/ColorContext";
import { ProductContext } from "../../context/ProductContext";
import { useForm } from "../../hooks/useForm";
import { SketchPicker } from "react-color";
import COLORS from "../../constants/colors";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import { getUserData } from "../../components/UI/Launcher/fetchLauncher";
import {
  APP_SECTIONS,
  userAccessManager,
} from "../../components/UI/Launcher/checkPermissions";
import { getCustomTemplate } from "../../actions/permission";
import { MODAL_TYPES } from "../../constants/modals";
import { useDispatch, useSelector } from "react-redux";
import { ModalJSONPermissions } from "../../components/Modal/ModalJSONPermissions";
import { setShowModal } from "../../actions/modal";
import ROLES from "../../constants/roles";
import { ClientContact } from "../../components/Section/ClientContact";
import {
  getContactOfCustomer,
  overwriteDefaultContact,
} from "../../actions/contact";
import { DICTIONARY } from "../../constants/dictionary";
import { translate } from "../../utils/translate";

const EditClient = () => {
  const dispatch = useDispatch();
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);
  const { clientId } = useParams();
  const inputPhoto = useRef(null);
  const [photo, setPhoto] = useState({});
  const userRole = sessionStorage.getItem("userRole")
    ? sessionStorage.getItem("userRole")
    : "";
  let isAdmin = false;
  if (userRole !== "") {
    isAdmin = userRole === ROLES.ADMIN;
  }
  const [showColorPicker, setShowColorPicker] = useState(false);

  const {
    state: stateProduct,
    getAllProducts,
    dispatch: dispatchProduct,
  } = useContext(ProductContext);
  const { dispatch: dispatchColor } = useColor();
  const {
    state: { token },
  } = useAuth();
  const {
    dispatch: dispatchClient,
    state: stateClient,
    uploadClientImage,
    getClientData,
    editClient,
    updateRedirect,
  } = useContext(ClientContext);

  const { selectedProducts, clientData: _clientData } = stateClient;
  const initialStateClient = {
    name: _clientData ? _clientData?.name : "",
    colorHex: _clientData ? _clientData?.colorHex : "",
    initialProductId: _clientData ? _clientData?.initialProductId : "",
    products: _clientData ? _clientData?.products : [],
  };

  const {
    data: { contact },
  } = useSelector((state) => state.contact);

  const redirectToOldPage =
    _clientData && _clientData?.redirect ? _clientData?.redirect : false;
  const allowAccessMobileApp =
    _clientData && _clientData?.allowMobileAccess
      ? _clientData?.allowMobileAccess
      : false;

  const [srcImage, setSrcImage] = useState("");
  const [clientName, setClientName] = useState("");
  const [colorHex, setColorHex] = useState({});
  const [formClientValues, handleClientInputChange] =
    useForm(initialStateClient);
  const [isSwitchOn, setIsSwitchOn] = useState(
    redirectToOldPage !== undefined ? redirectToOldPage : false
  );
  const [isSwitchOnHasMobileApp, setIsSwitchOnHasMobileApp] = useState(
    allowAccessMobileApp !== undefined ? allowAccessMobileApp : false
  );

  const onSwitchActionHasMobileApp = () => {
    setIsSwitchOnHasMobileApp(!isSwitchOnHasMobileApp);
  };
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleInputPhoto = (ev) => {
    inputPhoto.current.click();
  };
  const handleChangeFile = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSrcImage(imageUrl);
    setPhoto(file);
  };

  const handleEditClient = async (ev) => {
    ev.preventDefault();
    const { id } = _clientData;

    const { name, initialProductId } = formClientValues;
    const plainSelectedProducts = selectedProducts.map((_item) => _item.id);
    const deletedProducts = _clientData.products.filter(
      (_prodc) => !plainSelectedProducts.includes(_prodc)
    );

    let _initialProductId = initialProductId
      ? initialProductId
      : _clientData.initialProductId;
    if (!_initialProductId) {
      _initialProductId = selectedProducts[0].id;
    }

    const data = {
      name: name ? name : _clientData.name,
      colorHex: colorHex.hex,
      initialProductId:
        selectedProducts && selectedProducts.length > 0
          ? _initialProductId
          : null,
      products: selectedProducts.map((sProduct) => {
        return sProduct.id;
      }),
      deletedProducts,
      allowMobileAccess: isSwitchOnHasMobileApp,
    };
    const payloadRedirect = {
      redirectToOld: isSwitchOn,
    };

    const response = await editClient(dispatchClient, data, token, id);
    if (isAdmin) {
      await updateRedirect(dispatchClient, payloadRedirect, token, id);
    }
    const { contactName, email, phone } = formClientValues;

    const payloadContact = {
      customerId: id,
      name: contactName ? contactName : contact.name,
      email: email ? email : contact.email,
      phone: phone ? phone : contact.phone,
    };
    const responseContact = await dispatch(
      overwriteDefaultContact({ payload: payloadContact })
    );

    if (response.success && responseContact.success) {
      uploadClientImage(id, token, photo);
      await uploadClientImage(id, token, photo);
      Swal.fire({
        ...sweetAlertProps,
        text: translate(DICTIONARY.MESSAGE.CLIENT_SAVE, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {});
    } else {
      let message = "";
      if (!response.success) {
        message = response.message;
      } else if (!responseContact.success) {
        message = responseContact.message;
      }
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {});
    }
  };

  const getProductNameById = (products, productId) => {
    let productName = "";
    if (products) {
      const filteredProduct = products.filter(
        (_product) => _product.id === productId
      );
      if (filteredProduct?.length > 0) {
        productName = filteredProduct[0].name;
      }
    }
    return productName;
  };
  const handleOnclickShowPermissions = () => {
    dispatch(setShowModal(MODAL_TYPES.PERMISSIONS_CUSTOMERS, true));
  };

  useEffect(() => {
    getAllProducts(dispatchProduct);
  }, [getAllProducts, dispatchProduct, token, dispatchColor]);

  useEffect(() => {
    //Get data from client id
    const getClientDataAsync = async () => {
      const tokenFromSession = JSON.parse(sessionStorage.getItem("ideauto_u"));
      const token = tokenFromSession?.token ? tokenFromSession.token : "";
      const customer = await getClientData(dispatchClient, clientId, token);
      setColorHex({
        hex: customer?.colorHex ? customer?.colorHex : "#d3dee7ff",
      });
      const _clientName = customer?.name ? customer?.name : "";
      setClientName(_clientName);
      const _clientRedirectToOld = customer?.redirectToOld
        ? customer?.redirectToOld
        : false;
      setIsSwitchOn(_clientRedirectToOld);

      const allowAccessMobileApp =
        customer && customer?.allowMobileAccess
          ? customer?.allowMobileAccess
          : false;
      setIsSwitchOnHasMobileApp(allowAccessMobileApp);

      const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
      const logoUrl = customer?.logo
        ? `${apiURL}/customers/uploads/photo/${customer?.id}?token=${token}`
        : `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/logoIdeauto.png`;

      setSrcImage(logoUrl);
    };
    getClientDataAsync();
  }, [clientId]);

  useEffect(() => {
    dispatch(
      getCustomTemplate({
        id: clientId,
        modal: MODAL_TYPES.PERMISSIONS_CUSTOMERS,
      })
    );
  }, [clientId]);

  const [showProductsAsClient, setShowProductsAsClient] = useState(false);
  useEffect(() => {
    const getUserFromToken = async () => {
      const userData = await getUserData(token);
      if (userData) {
        const allowShowProductsAsClient = userAccessManager(
          APP_SECTIONS.showProductsAsClient,
          userData.role
        );
        setShowProductsAsClient(allowShowProductsAsClient);
      }
    };
    getUserFromToken();
  }, [token]);

  useEffect(() => {
    if (_clientData != null) {
      const { id } = _clientData;
      const customerId = id;
      dispatch(
        getContactOfCustomer({
          customerId,
        })
      );
    }
  }, [_clientData]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="section-name">
            <span className="material-icons-round section-icon">business</span>
            <div>{translate(DICTIONARY.TITLE.CLIENT_EDIT, dictionary)}</div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col className="section-content mt-3 ml-2">
          <div className="subsection-name">
            {translate(DICTIONARY.TITLE.CLIENT_DATA, dictionary)}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form className="mt-3" onSubmit={handleEditClient}>
            <Row className="user-row">
              <Col xs="12" sm="5" md="4" lg="3" xl="3" xxl="3" className="">
                <div className="avatar">
                  <div className="avatar-image">
                    {srcImage && (
                      <Image className="client-image" src={srcImage} />
                    )}
                  </div>
                  <div className="avatar-button">
                    <Form.Control
                      ref={inputPhoto}
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleChangeFile}
                    />
                    <Button
                      variant="secondary"
                      className="avatar-change-button"
                      onClick={handleInputPhoto}
                    >
                      {translate(
                        DICTIONARY.BUTTON.CLIENT_CHANGE_LOGO,
                        dictionary
                      )}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col
                xs="12"
                sm="7"
                md="8"
                lg="9"
                xl="9"
                xxl="9"
                className="offset-controls-end editClient-adjust-top"
              >
                {isAdmin && (
                  <>
                    <Row>
                      <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                        <Form.Check
                          type="switch"
                          onChange={onSwitchActionHasMobileApp}
                          id="switch-allowAccessMobileApp"
                          label={translate(
                            DICTIONARY.LABEL.CLIENT_HAS_ACCESS_MOBILE_APP,
                            dictionary
                          )}
                          checked={isSwitchOnHasMobileApp}
                          name="allowMobileAccess"
                          value={true}
                          className="input-switch"
                        />
                      </Col>
                    </Row>
                    <Row className="">
                      <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                        <Form.Check
                          type="switch"
                          onChange={onSwitchAction}
                          id="switch-allowEmailNotification"
                          label={translate(
                            DICTIONARY.LABEL.CLIENT_REDIRECT_OLD_PAGE,
                            dictionary
                          )}
                          checked={isSwitchOn}
                          name="redirect"
                          value={true}
                          className="input-switch"
                        />
                      </Col>
                    </Row>
                  </>
                )}
                {/* {
                                    isAdmin &&
                                    <Row>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="5"
                                            xl="5"
                                            xxl="4"
                                        >
                                            <Form.Control
                                                type="text"
                                                placeholder="ID *"
                                                onClick={() => setShowColorPicker(false)}
                                                disabled={true}
                                                defaultValue={clientIdFromDB}
                                            />
                                        </Col>
                                    </Row>
                                } */}
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <div
                      className="colorPicker-button form-control div-asInput"
                      onClick={() => setShowColorPicker((_state) => !_state)}
                    >
                      {colorHex ? (
                        <div className="colorPicker-preview">
                          <div
                            className="colorPicker-square"
                            style={{ backgroundColor: colorHex.hex }}
                          ></div>
                          {colorHex.hex}
                        </div>
                      ) : (
                        `${translate(
                          DICTIONARY.PLACEHOLDER.CLIENT_PRIMARY_COLOR,
                          dictionary
                        )}`
                      )}
                    </div>
                    {showColorPicker && (
                      <div className="">
                        <SketchPicker
                          color={colorHex.hex}
                          onChange={(color) => setColorHex(color)}
                          onChangeComplete={(color) => setColorHex(color)}
                          disableAlpha={true}
                          presetColors={[]}
                          width={235}
                          className="colorPicker-picker"
                        />
                      </div>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Control
                      type="text"
                      placeholder={translate(
                        DICTIONARY.PLACEHOLDER.CLIENT_NAME,
                        dictionary
                      )}
                      name="name"
                      defaultValue={clientName}
                      onChange={handleClientInputChange}
                      onClick={() => setShowColorPicker(false)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col className="section-content mt-3 ml-2">
                <div className="subsection-name">
                  <div className="title">
                    <div>
                      {translate(DICTIONARY.TITLE.CONTACT_DATA, dictionary)}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <ClientContact
              handleClientInputChange={handleClientInputChange}
              setShowColorPicker={setShowColorPicker}
            />
            <ProductAvailableList
              isUsedInClient={true}
              isUsedInUser={false}
              showProductsAsClient={showProductsAsClient}
            />
            <Row className="starting-products">
              <Col xs="3" sm="2" md="2" lg="3" xl="3" xxl="3">
                <Form.Select
                  // defaultValue={initialProductId}
                  name="initialProductId"
                  onChange={handleClientInputChange}
                >
                  <option disabled>
                    {translate(
                      DICTIONARY.PLACEHOLDER.CLIENT_STARTING_PRODUCT,
                      dictionary
                    )}
                  </option>

                  {selectedProducts &&
                    selectedProducts.map((product, index) => {
                      const isSelectedProduct =
                        product.id ===
                        stateClient?.clientData?.initialProductId;
                      const productName = getProductNameById(
                        stateProduct.products,
                        product.id
                      );

                      return (
                        <option
                          key={`starting_app_${index}_${product.id} `}
                          value={`${product.id}`}
                          selected={isSelectedProduct}
                        >
                          {productName}
                        </option>
                      );
                    })}
                </Form.Select>
              </Col>
            </Row>
            <Button
              variant="secondary"
              className="w-25 float-end button-form button"
              type="submit"
            >
              {translate(DICTIONARY.BUTTON.SAVE, dictionary)}
            </Button>
            {isAdmin && (
              <>
                <Button
                  variant="secondary"
                  className="w-25 float-end button-form "
                  onClick={handleOnclickShowPermissions}
                >
                  {translate(DICTIONARY.BUTTON.SHOW_PERMISSION, dictionary)}
                </Button>
                <ModalJSONPermissions
                  id={clientId}
                  modalId={MODAL_TYPES.PERMISSIONS_CUSTOMERS}
                  title={translate(
                    DICTIONARY.TITLE.CLIENT_PERMISSION,
                    dictionary
                  )}
                  icon={`business`}
                />
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditClient;
