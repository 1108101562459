/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 28/09/2022                                        
* @Description All sections in app                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/


export const SECTION = {
    CLIENT_CREATE: "client/create",
    CLIENT_EDIT: "client/edit",
    CLIENT_INDEX: "client",
    CONTACT_EDIT: "contact/edit",
    NOTIFICATION_CREATE: "notification/create",
    NOTIFICATION_RECEIVED_DETAIL: "notification/received/",
    NOTIFICATION_RECEIVED_INDEX: "notification/received",
    NOTIFICATION_SENT_DETAIL: "notification/sent/",
    NOTIFICATION_SENT_INDEX: "notification",
    PERMISSION_EDIT: "permission/edit",
    PRODUCT_CREATE: "product/create",
    PRODUCT_EDIT: "product/edit",
    PRODUCT_INDEX: "product",
    PROFILE: "profile",
    REPORTING_INDEX: "reporting",
    USER_CREATE: "user/create",
    USER_EDIT: "user/edit",
    USER_INDEX: "user",

}