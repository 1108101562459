/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 28/02/2022                                        
* @Description Color context for clients                                                     
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { createContext, useContext, useReducer } from 'react';
import { fetchWithToken } from '../helpers/fetch';
import { types } from '../types/types';
import { redirectToNoAuthorizedURL } from '../utils/redirectToNoAuthorizedURL';

const ColorContext = createContext([]);

const initialState = {
    colors: [],
    // filteredUsers: [],
    // editUserData: {}
}

const colorReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.colorGetAll:
            return {
                ...state,
                colors: action.payload
            }
        default:
            return state;
    }
}


const setAllColors = (colors) => ({
    type: types.colorGetAll,
    payload: colors
})


const getAllColors = async (dispatch, token, limit = '', page = '') => {
    try {
        const resp = await fetchWithToken(
            `colors?limit=${limit}&page=${page}`,
            {},
            'GET',
            token
        );
        const body = await resp.json();
        if (body.success) {
            const { data: { colors } } = body;
            dispatch(setAllColors(colors));
            // return colors;
        } else {
            redirectToNoAuthorizedURL(resp);
        }
    } catch (error) {
        console.error(error);
    }

}


const ColorProvider = ({ children }) => {
    const [state, dispatch] = useReducer(colorReducer, []);
    const value = { state, dispatch, getAllColors, setAllColors };

    return <ColorContext.Provider value={value}>{children}</ColorContext.Provider>;
}

const useColor = () => {
    const context = useContext(ColorContext);

    if (context === undefined) {
        throw new Error('useColor must be used with ColorProvider');
    }
    return context;
}


export {
    ColorProvider,
    useColor
}