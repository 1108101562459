/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 19/07/2022
 * @Description Actions for inputSelectReducer
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import {
  INPUT_SELECTS,
  INPUT_SELECTS_STATE_VARIABLE,
} from "../constants/inputSelects";
import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";

const setClients = (payload) => ({
  type: types.inputSelectSetClients,
  payload,
});
// START Generic
export const setInputSelectOptions = (payload) => ({
  type: types.inputSelectSetOptions,
  payload,
});
export const switchClientMenu = (payload) => ({
  type: types.inputSelectSwitchClientsMenu,
  payload,
});
export const switchInputSelectMenu = (payload) => ({
  type: types.inputSelectSwitchOptionsMenu,
  payload,
});
const switchCheckedClient = (payload) => ({
  type: types.inputSelectSetClientChecked,
  payload,
});
export const switchInputSelectCheckedOption = (payload) => ({
  type: types.inputSelectSetOptionChecked,
  payload,
});
const selectAllClients = (payload) => ({
  type: types.inputSelectSelectAllClients,
  payload,
});
const unselectAllClients = (payload) => ({
  type: types.inputSelectUnselectAllClients,
  payload,
});
export const setDefaultSelectedClients = (payload) => ({
  type: types.inputSelectSetDefaultClients,
  payload,
});
export const setInputSelectCloseAll = () => ({
  type: types.inputSelectCloseAll,
});
export const selectAllOptions = (params) => {
  return async (dispatch) => {
    try {
      const { inputSelectType, dictionary } = params;
      switch (inputSelectType) {
        case INPUT_SELECTS.CLIENTS:
          dispatch(selectAllClients(dictionary));
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };
};
export const unSelectAllOptions = (params) => {
  return async (dispatch) => {
    try {
      const { inputSelectType, dictionary } = params;
      switch (inputSelectType) {
        case INPUT_SELECTS.CLIENTS:
          dispatch(unselectAllClients(dictionary));
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };
};
export const setFilterValue = () => {
  return async (dispatch) => {
    try {
    } catch (error) {
      console.error(error);
    }
  };
};
export const toggleCheckOption = (params) => {
  return async (dispatch) => {
    try {
      const { inputSelectType, id, dictionary = null } = params;
      switch (inputSelectType) {
        case INPUT_SELECTS.CLIENTS:
          await dispatch(
            switchCheckedClient({
              id,
              dictionary,
            })
          );
          break;
        case INPUT_SELECTS.NOTIFICATION.SEND_TYPE:
          await dispatch(
            switchInputSelectCheckedOption({
              optionId: id,
              inputSelectStateProp:
                INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.SEND_TYPE,
              defaultSelectedLabel: "",
              dictionary,
            })
          );
          break;
        case INPUT_SELECTS.NOTIFICATION.STATE:
          await dispatch(
            switchInputSelectCheckedOption({
              optionId: id,
              inputSelectStateProp:
                INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.STATE,
              defaultSelectedLabel: "",
              dictionary,
            })
          );
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };
};
export const getAllClients = (token, query = "", limit = "100", page = "") => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(
        `customers?query=${query}&limit=${limit}&page=${page}`,
        {},
        "GET",
        token
      );
      const body = await resp.json();
      if (body.success) {
        const {
          data: { customers: _customers },
        } = body;
        const customers = _customers.map((item) => {
          return {
            ...item,
            checked: false,
          };
        });
        dispatch(setClients(customers));
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};
