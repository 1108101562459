import getTokenFromUrl from "./getTokenFromUrl";

export const redirectToNoAuthorizedURL = (resp) => {
    if (resp) {
        const { status } = resp;
        if (status === 401) {
            let ideautoU = JSON.parse(sessionStorage.getItem('ideauto_u'));
            if (!ideautoU) {
                ideautoU = getTokenFromUrl();
            }
            if (ideautoU) {
                const token = ideautoU?.token ? ideautoU.token : ideautoU;
                sessionStorage.clear();
                window.location.href = `${process.env.REACT_APP_NO_AUTHORIZED_URL}${token}`;
            }
        }
    }
}