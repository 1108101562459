/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 26/07/2022                                        
* @Description Badge types                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/



export const BADGE_TYPES = {
    ACTIVE: 'ACTIVADO', //ACTIVADO' 
    DEACTIVE: 'DESACTIVADO', //DESACTIVADO' 
    ACTIVE_UNTIL: 'ACTIVADO HASTA' //ACTIVADO HASTA [fecha]
}