/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/07/2022                                        
* @Description Modal for json permissions clients/products                                                      
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setDisabledButtons, setShowModal } from '../../actions/modal';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/es';
import {
    deleteCustomTemplate,
    getCustomTemplate,
    setErrorInJSON,
    setIsDeleted,
    setIsSubmited,
    setPermissionMessage,
    setUpdateCustomTemplate,
    updateCustomTemplate
} from '../../actions/permission';
import { MODAL_TYPES } from '../../constants/modals';
import { DICTIONARY } from '../../constants/dictionary';
import { translate } from '../../utils/translate';


export const ModalJSONPermissions = (props) => {

    const { id, modalId, title, icon } = props;
    const dispatch = useDispatch();
    const { current: { dictionary } } = useSelector((state) => state.translation);
    const { modals, buttons } = useSelector((state) => state.modal);
    const { acceptDisabled } = buttons;
    const { custom, error, isSubmited, message: messageFromPermission, isDeleted } = useSelector((state) => state.permission);
    const { permission, update, parentIsTemplate } = custom;
    const { json } = permission;
    const { show } = modals.filter((item) => item.id === modalId)[0];
    const errorMessage = `Existe un error en el JSON, corrija el error y pulse Aceptar de nuevo`;


    const handleClose = () => {
        dispatch(setShowModal(
            modalId,
            !show
        ));
        dispatch(setIsSubmited(false));
        dispatch(setErrorInJSON(null));
    }

    const handleSubmit = async (ev) => {
        ev.preventDefault();

        await dispatch(setPermissionMessage(''));
        dispatch(setIsSubmited(true));
        dispatch(setIsDeleted(false));
        if (error === null) {
            let payload = {
            }
            switch (modalId) {
                case MODAL_TYPES.PERMISSIONS_CUSTOMERS:
                    payload = {
                        customerId: id,
                        json: update
                    }
                    break;
                case MODAL_TYPES.PERMISSIONS_USERS:
                    payload = {
                        userId: id,
                        json: update
                    }
                    break;
                default:
                    break;
            }

            await dispatch(updateCustomTemplate({
                modal: modalId,
                payload
            }));
            handleClose();
            await dispatch(getCustomTemplate({
                id,
                modal: MODAL_TYPES.PERMISSIONS_USERS
            }));
        }
    }

    const handleOnChangeJSON = (ev) => {
        const { error, jsObject } = ev;
        dispatch(setDisabledButtons(true));
        if (error) {
        } else {

            dispatch(setDisabledButtons(false));
        }
        if (!error) {
            dispatch(setIsSubmited(false));
            dispatch(setErrorInJSON(null));
            dispatch(setUpdateCustomTemplate(jsObject));
        } else {
            dispatch(setErrorInJSON(errorMessage));
        }
    }

    const handleDeleteTemplate = async () => {
        await dispatch(deleteCustomTemplate({
            id,
            modal: modalId
        }));
        dispatch(setIsDeleted(true));
        // handleClose();
        await dispatch(getCustomTemplate({
            id,
            modal: MODAL_TYPES.PERMISSIONS_USERS
        }));
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    <div className='section-name'>
                        <span className="material-icons-round section-icon">
                            {icon}
                        </span>
                        <div>{title}</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{ maxWidth: "1400px", maxHeight: "100%" }}>
                    <JSONInput
                        id='jsoneditorPermissions'
                        placeholder={json}
                        locale={locale}
                        height='400px'
                        width='100%'
                        onChange={handleOnChangeJSON}
                        waitAfterKeyPress={300}
                    />
                </div>
                {
                    error !== null && isSubmited &&
                    <div className="errorMessage">{error}</div>
                }
                {
                    messageFromPermission !== null && isSubmited &&
                    <div className="errorMessage">{messageFromPermission}</div>
                }
                {
                    messageFromPermission !== null && isDeleted &&
                    <div className="okMessage">{messageFromPermission}</div>
                }
                <Button
                    variant="light"
                    className="w-25 float-end button-form button button-delete"
                    onClick={handleDeleteTemplate}
                    disabled={parentIsTemplate}
                >
                    {translate(DICTIONARY.BUTTON.DELETE, dictionary)}
                </Button>
                <Button
                    variant="secondary"
                    className="w-25 float-end button-form "
                    onClick={handleSubmit}
                    disabled={acceptDisabled}
                >
                    {translate(DICTIONARY.BUTTON.ACCEPT, dictionary)}
                </Button>
            </Modal.Body>
        </Modal >
    )
}
