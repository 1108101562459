import moment from "moment";
import { DATES_VARIABLES } from "../constants/filter";



export const formatDatesInArray = (list) => {
    const arrayWithFormatedDates = list.map((item) => {

        const { startDate, endDate } = item;
        return {
            ...item,
            formatedStartDate: moment(startDate).format(`${DATES_VARIABLES.spanishDateFormat} HH:mm`),
            formatedEndDate: moment(endDate).format(`${DATES_VARIABLES.spanishDateFormat} HH:mm`),
        }
    });

    return arrayWithFormatedDates;
}   