import React, { useContext, useEffect, useState } from "react";
import IndexClient from "../pages/clients/IndexClients";
import CreateClient from "../pages/clients/CreateClient";
import EditClient from "../pages/clients/EditClient";
import IndexUser from "../pages/users/IndexUser";
import CreateUser from "../pages/users/CreateUser";
import EditUser from "../pages/users/EditUser";

import { Routes, Route, Outlet } from "react-router-dom";
import { renewLogin, useAuth } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import { parseJwt } from "../components/UI/Launcher/jwt";
import IndexProduct from "../pages/products/IndexProduct";
import CreateProduct from "../pages/products/CreateProduct";
import EditProduct from "../pages/products/EditProduct";
import { EditPermission } from "../pages/permission/EditPermission";
import { EditContact } from "../pages/contact/EditContact";
import IndexNotification from "../pages/notifications/IndexNotification";
import CreateNotification from "../pages/notifications/CreateNotification";
import ReadNotification from "../pages/notifications/ReadNotification";
import DetailSentNotification from "../pages/notifications/DetailSentNotification";
import IndexReporting from "../pages/reporting/IndexReporting";
import { useDispatch, useSelector } from "react-redux";
import { translationGetCurrentDictionary } from "../actions/translation";
import { getCorrectListCodesForSection } from "../utils/getCorrectListCodesForSection";
import {
  getLanguageCode,
  getProductAccessParameters,
} from "../actions/parameters";

export const AppRouter = () => {
  const { dispatch: dispatchAuth } = useAuth();

  const dispatch = useDispatch();
  const { getUserRole } = useContext(UserContext);
  const [userRole, setUserRole] = useState("");

  const isAdmin =
    userRole && userRole !== "customer_admin" && userRole !== "user";
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);
  const isDictionaryReady = dictionary !== undefined && dictionary !== null;

  // useEffect(() => {
  //     const _getLanguageCode = async () => {
  //     }
  //     _getLanguageCode();
  // }, []);
  useEffect(() => {
    renewLogin(dispatchAuth);
  }, []);
  useEffect(() => {
    //Get data from user id
    const getUserDataAsync = async () => {
      const tokenFromSession = JSON.parse(sessionStorage.getItem("ideauto_u"));
      if (tokenFromSession) {
        const parsedToken = parseJwt(tokenFromSession.token);
        if (parsedToken !== undefined) {
          const { userId } = parsedToken;
          const _user = await getUserRole(userId, tokenFromSession.token);
          setUserRole(_user?.role);
        }
      }
    };
    getUserDataAsync();
  }, []);

  useEffect(() => {
    const getTranslations = async () => {
      let listCodes = getCorrectListCodesForSection();
      await dispatch(getLanguageCode());
      if (listCodes.length > 0) {
        dispatch(translationGetCurrentDictionary({ list: listCodes }));
      }
    };
    getTranslations();
  }, []);

  return (
    <>
      {isDictionaryReady && (
        <Routes>
          <Route path="/*" element={<Outlet />}>
            <Route
              exact
              path="profile/:userId"
              element={<EditUser isOwnProfile={true} />}
            />
            {isAdmin ? (
              <Route path="client">
                <Route exact path="" element={<IndexClient />} />
                <Route exact path="create" element={<CreateClient />} />
                <Route exact path="edit/:clientId" element={<EditClient />} />
              </Route>
            ) : (
              <Route path="client">
                <Route exact path="edit/:clientId" element={<EditClient />} />
              </Route>
            )}
            {isAdmin && (
              <Route path="product">
                <Route exact path="" element={<IndexProduct />} />
                <Route exact path="create" element={<CreateProduct />} />
                <Route exact path="edit/:productId" element={<EditProduct />} />
              </Route>
            )}
            {isAdmin && (
              <Route path="permission">
                <Route exact path="edit" element={<EditPermission />} />
              </Route>
            )}
            {isAdmin && (
              <Route path="contact">
                <Route exact path="edit" element={<EditContact />} />
              </Route>
            )}
            <Route path="notification">
              {isAdmin && (
                <>
                  <Route
                    exact
                    path=""
                    element={<IndexNotification sent={true} />}
                  />
                  <Route exact path="create" element={<CreateNotification />} />
                </>
              )}

              <Route
                exact
                path="received"
                element={<IndexNotification sent={false} />}
              />
              <Route
                exact
                path="received/:notificationId"
                element={<ReadNotification />}
              />
              <Route
                exact
                path="sent/:notificationId"
                element={<DetailSentNotification />}
              />
            </Route>
            {isAdmin && (
              <Route path="reporting">
                <Route exact path="" element={<IndexReporting />} />
              </Route>
            )}
            <Route path="user">
              <Route exact path="" element={<IndexUser />} />
              <Route exact path="create" element={<CreateUser />} />
              <Route
                exact
                path="edit/:userId"
                element={<EditUser isOwnProfile={false} />}
              />
            </Route>
          </Route>
        </Routes>
      )}
    </>
  );
};
