/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 27/09/2022                                        
* @Description Actions for translationReducer                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import ENDPOINTS from "./endpoints";


export const setCurrentTranslationDictionary = (payload) => ({
    type: types.translationGetCurrentDictionary,
    payload
});
export const translationGetCurrentDictionary = (args) => {
    return async (dispatch) => {
        try {
            const { list } = args;
            const payload = {
                list
            }
            const resp = await fetchWithToken(
                ENDPOINTS.TRANSLATION.POST.LIST,
                payload,
                'POST',
            );
            const body = await resp.json();
            if (body.success) {
                if (body?.data) {
                    const { data: { texts: dictionary } } = body;
                    dispatch(setCurrentTranslationDictionary(dictionary));
                }
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}