import React, { useEffect } from 'react'
import {
    Container,
    Button,
    Row,
    Col,
    Spinner
} from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';
import Table from 'react-bootstrap/Table';
import ROLES from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationFilteredList, getNotificationList, setNotificationToggleDatePickerInList } from '../../actions/notification';
import { InputSelect } from '../../components/Form/InputSelect';
import { INPUT_SELECTS, INPUT_SELECTS_STATE_VARIABLE } from '../../constants/inputSelects';
import { SEND_TYPES_FILTER_OPTIONS, STATE_TYPES_FILTER_OPTIONS } from '../../constants/ntoificationSendTypes';
import { setInputSelectCloseAll, setInputSelectOptions, switchClientMenu, switchInputSelectMenu } from '../../actions/inputSelectActions';
import { FILTER_ATTRIBUTES } from '../../constants/filter';
import { DatePicker } from '../../components/Form/DatePicker';
import { setDatePickerToggle } from '../../actions/datePicker';
import { DICTIONARY } from '../../constants/dictionary';
import { translate } from '../../utils/translate';

const IndexNotification = (props) => {

    const { sent } = props;
    const { current: { dictionary } } = useSelector((state) => state.translation);
    let isAdmin = false;
    const userRole = sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : '';

    if (userRole !== '') {
        isAdmin = userRole === ROLES.ADMIN;
    }
    const paramsForLoaded = '&_s=841110&_l=1';
    const limit = 25;
    const dispatch = useDispatch();
    const { state: stateAuth } = useAuth();
    const { list, isUsingFilter, filters, filterList, stopLoadingSent, stopLoadingReceived, showDatePicker } = useSelector((state) => state.notification);

    const { notificationFilterSendType, notificationFilterState } = useSelector((state) => state.inputSelect);

    // const { show: showDatePicker } = useSelector((state) => state.datePicker);
    const dataList = (filterList && filterList.length > 0) || isUsingFilter ? filterList : list;
    let timer = null;

    const handleFilterNotificationList = (args) => {
        const { search, attribute } = args;
        let payload = {
            filters,
            ...args,
            attribute, //FILTER_ATTRIBUTES.NOTIFICATION_TYPE,  //FILTER_ATTRIBUTES.NOTIFICATION_TYPE
            search, //id
            sent //sent
        }

        const delayTime = 500;
        clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(getNotificationFilteredList(payload));

        }, delayTime);
    }

    const handleEdit = (notificationId) => {
        dispatch(setInputSelectCloseAll());
        dispatch(setNotificationToggleDatePickerInList(false));
        sessionStorage.setItem('notificationActiveTab', 'client');
        // `${process.env.REACT_APP_BROWSER_ROUTE}/notification/create?_i=${stateAuth.token}${paramsForLoaded}`;
        if (sent) {
            window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/notification/sent/${notificationId}?_i=${stateAuth.token}${paramsForLoaded}`;
            // navigate(`/notification/sent/${notificationId}?_i=${stateAuth.token}${paramsForLoaded}`, { replace: false });
        } else {
            window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/notification/received/${notificationId}?_i=${stateAuth.token}${paramsForLoaded}`;
            // navigate(`/notification/received/${notificationId}?_i=${stateAuth.token}${paramsForLoaded}`, { replace: false });
        }
    }

    const nextPage = sessionStorage.getItem('nextPageNotifications');
    const handleScroll = () => {
        const allProductsLoaded = sessionStorage.getItem('allNotificationsLoaded');
        const actualScroll = window.innerHeight + window.scrollY + 400;
        if (!!allProductsLoaded && actualScroll >= document.body.offsetHeight) {
            const payloadPagination = {
                limit,
                page: nextPage,
                sent
            }
            dispatch(getNotificationList(payloadPagination));
            window.removeEventListener('scroll', handleScroll);
        }
    }


    const handleOnClickDateFilter = () => {
        dispatch(setNotificationToggleDatePickerInList(!showDatePicker))

        dispatch(switchClientMenu(false));
        dispatch(switchInputSelectMenu({
            inputSelectStateProp: INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.SEND_TYPE,
            isOpen: false
        }));
        dispatch(switchInputSelectMenu({
            inputSelectStateProp: INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.STATE,
            isOpen: false
        }));
    }

    const handleGoToCreate = () => {
        dispatch(setInputSelectCloseAll());
        dispatch(setDatePickerToggle(false));
        sessionStorage.setItem('notificationActiveTab', 'client');
        window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/notification/create?_i=${stateAuth.token}${paramsForLoaded}`;
    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [nextPage, filters]);

    useEffect(() => {
        if (!isUsingFilter) {
            const payload = {
                limit,
                page: 1,
                sent
            }
            if (list && list.length === 0) {
                sessionStorage.setItem('allNotificationsLoaded', false);
                dispatch(getNotificationList(payload));
            }
            // // const delayForGetPendingNotifications = 30000; //30s 
            // const delayForGetPendingNotifications = 10000; //30s 
            // setInterval(() => {
            //     const nextPage = sessionStorage.getItem('nextPageNotifications');
            //     const payloadPagination = {
            //         limit,
            //         page: nextPage,
            //         sent
            //     }
            //     dispatch(getNotificationList(payloadPagination));
            // }, delayForGetPendingNotifications);
        } else {
            if (filters) {
                const payload = {
                    limit,
                    page: 1,
                    sent,
                    ...filters
                }

                dispatch(getNotificationList(payload));
            }
        }
    }, [isUsingFilter]);


    useEffect(() => {
        dispatch(setInputSelectOptions({
            options: SEND_TYPES_FILTER_OPTIONS,
            inputSelectStateProp: INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.SEND_TYPE
        }));
        dispatch(setInputSelectOptions({
            options: STATE_TYPES_FILTER_OPTIONS,
            inputSelectStateProp: INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.STATE
        }));
    }, []);


    const arrowDown = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_down_black_24dp.svg`;
    const arrowUp = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_up_black_24dp.svg`;

    //TODO ADD TRANSLATIONS
    return (
        <Container
            fluid
        >
            <div className="row mb-5">
                <div className="col-xl-4 col-lg-5 col-md-4 col-xs-5 ">
                    <div className='section-name'>
                        <span className="material-icons-round section-icon">
                            notifications
                        </span>
                        {
                            sent ?
                                <div>{translate(DICTIONARY.TITLE.NOTIFICATION_SEND, dictionary)}</div>
                                :
                                <div>{translate(DICTIONARY.TITLE.NOTIFICATION_RECEIVED, dictionary)}</div>
                        }
                    </div>
                </div>
                <div className="col-xl-4 col-lg-2 col-md-2 col-xs-1 "></div>
                {
                    isAdmin && sent &&
                    <div className="col-xl-4 col-lg-5 col-md-6 col-xs-6 ">
                        <Button
                            variant="secondary"
                            onClick={handleGoToCreate}
                            className="w-25 float-end button"
                        >{translate(DICTIONARY.BUTTON.CREATE, dictionary)}</Button>
                    </div>
                }
            </div>
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    xxl="12"
                >

                    <Table borderless responsive className="table-launcher">
                        <thead>
                            <tr>
                                {
                                    isAdmin &&
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div>
                                                {translate(DICTIONARY.LABEL.USER, dictionary)}
                                            </div>
                                        </div>
                                    </th>
                                }
                                <th>
                                    <div className="tableHeader-filter">
                                        <div>
                                            {translate(DICTIONARY.LABEL.TITLE, dictionary)}
                                        </div>
                                    </div>
                                </th>
                                <th className="notification-list-column-text">
                                    <div className="tableHeader-filter ">
                                        <div>
                                            {translate(DICTIONARY.LABEL.MESSAGE, dictionary)}
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    <div className="tableHeader-filter">
                                        <div className="tableHeader-filter__input">
                                            <div className="filter">
                                                <div className="filter-box">
                                                    <div
                                                        className={`input-selectedOption form-control form-control__borderRadius ${showDatePicker ? 'input-select-optionsShow' : ''}`}
                                                        onClick={handleOnClickDateFilter}

                                                    >
                                                        <div
                                                            className='input-selectedOption-value'
                                                        >
                                                            {translate(DICTIONARY.PLACEHOLDER.SEND_DATE, dictionary)}
                                                        </div>

                                                        <div className="input-selectedOption-icon">
                                                            {
                                                                showDatePicker ?
                                                                    <img src={arrowUp} alt="arrowUp" />
                                                                    :
                                                                    <img src={arrowDown} alt="arrowDown" />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <Form.Control
                                                type="date"
                                                placeholder="Fecha envío"
                                                onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.NOTIFICATION_TYPE, ev.target.value)}
                                                defaultValue={filters?.type && filters?.type !== '' ? filters?.type : ''}
                                            /> */}
                                    </div>
                                    {
                                        showDatePicker &&
                                        <DatePicker
                                            callBackFilter={handleFilterNotificationList}
                                            extraClass={`datePicker-notificationList ${sent ? 'datePicker-notificationList__sent' : ''}`}
                                            attribute={FILTER_ATTRIBUTES.NOTIFICATION_DATES}
                                            show={showDatePicker}
                                        />
                                    }
                                </th>
                                <th className="tableHeader-notification-sendType">
                                    <div className="tableHeader-filter">
                                        <div className="tableHeader-filter__input">
                                            <InputSelect
                                                label=''
                                                options={SEND_TYPES_FILTER_OPTIONS}
                                                inputId="sendType"
                                                filterType={INPUT_SELECTS.NOTIFICATION.SEND_TYPE}
                                                inputName="sendType"
                                                className="filter__xl"
                                                inputType="checkbox"
                                                visibleSelectedValue={notificationFilterSendType.selectedLabel}
                                                hasSelectAll={false}
                                                callBackOption={handleFilterNotificationList}
                                                attribute={FILTER_ATTRIBUTES.NOTIFICATION_TYPE} />
                                            {/* 
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Método de envío"
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.NOTIFICATION_TYPE, ev.target.value)}
                                                    defaultValue={filters?.type && filters?.type !== '' ? filters?.type : ''}
                                                /> */}
                                        </div>
                                    </div>
                                </th>
                                {
                                    !sent &&
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <InputSelect
                                                    label=''
                                                    options={STATE_TYPES_FILTER_OPTIONS}
                                                    inputId="state"
                                                    filterType={INPUT_SELECTS.NOTIFICATION.STATE}
                                                    inputName="state"
                                                    className="filter__xl"
                                                    inputType="checkbox"
                                                    visibleSelectedValue={notificationFilterState.selectedLabel}
                                                    hasSelectAll={false}
                                                    callBackOption={handleFilterNotificationList}
                                                    attribute={FILTER_ATTRIBUTES.NOTIFICATION_STATE}
                                                />
                                                {/* 
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Método de envío"
                                                        onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.NOTIFICATION_TYPE, ev.target.value)}
                                                        defaultValue={filters?.type && filters?.type !== '' ? filters?.type : ''}
                                                    /> */}
                                            </div>
                                        </div>
                                    </th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {

                                // const { list, isUsingFilter, filters, filterList, stopLoadingSent, stopLoadingReceived } = useSelector((state) => state.notification);

                                dataList.length === 0 && !isUsingFilter &&
                                ((sent && !stopLoadingSent) || (!sent && !stopLoadingReceived)) &&
                                <>
                                    <tr>
                                        <td colSpan={5}>
                                            <div className="spinner">
                                                <Spinner animation="border" role="status"></Spinner>
                                            </div>
                                            <div className="spinner">
                                                <div className="spinner-message">
                                                    {translate(DICTIONARY.LABEL.NOTIFICATION_LOADING, dictionary)}
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </>


                            }
                            {
                                dataList.length === 0 && !isUsingFilter &&
                                ((sent && stopLoadingSent) || (!sent && stopLoadingReceived)) &&
                                <>
                                    <tr>
                                        <td colSpan={5}>
                                            <div className="">
                                                {translate(DICTIONARY.LABEL.NO_NOTIFICATION, dictionary)}
                                            </div>
                                        </td>
                                    </tr>
                                </>
                            }
                            {
                                dataList && dataList.length > 0 && dataList.map((item, index) => {
                                    const { id, title, text, type, creationDate, state } = item;
                                    let userName = '';
                                    if (isAdmin) {
                                        userName = item?.username;
                                    }
                                    return (
                                        <tr
                                            className={`table-hoverRow ${state === 'pending' && 'notification-list-pending'}`}
                                            key={`${id}_${index}`}
                                        >
                                            {/* <td>
                                                    <div
                                                        className="table-checkbox">
                                                        <Form.Check
                                                            className="table-checkbox"
                                                            type="checkbox"
                                                            name={`user[${id}]`}
                                                            checked={checked}
                                                            onChange={(ev) => handleSelectProduct(ev, id)}
                                                        />
                                                    </div>

                                                </td> */}
                                            {
                                                isAdmin &&
                                                <td
                                                    onClick={() => handleEdit(id)}
                                                >
                                                    {userName}
                                                </td>
                                            }
                                            <td
                                                onClick={() => handleEdit(id)}
                                            >
                                                {title}
                                            </td>
                                            <td
                                                className="notification-list-column-text"
                                                onClick={() => handleEdit(id)}
                                                title={text}
                                            >
                                                {text}
                                            </td>
                                            <td
                                                onClick={() => handleEdit(id)}
                                            // title={customers}
                                            // className="ellipsis"
                                            >
                                                {creationDate}
                                            </td>
                                            <td
                                                onClick={() => handleEdit(id)}
                                            // title={customers}
                                            // className="ellipsis"
                                            >
                                                {type}
                                            </td>
                                            {
                                                !sent &&
                                                <td
                                                    onClick={() => handleEdit(id)}
                                                    className="notification-list-column-icon"
                                                >

                                                    <div className="notification-list-icon">
                                                        {
                                                            state === 'pending' ?
                                                                <>
                                                                    <span className="material-icons-round section-icon">
                                                                        mail
                                                                    </span>
                                                                    {translate(DICTIONARY.LABEL.NOT_READ, dictionary)}
                                                                </>
                                                                :
                                                                <>
                                                                    <span className="material-icons-round section-icon">
                                                                        drafts
                                                                    </span>
                                                                    {translate(DICTIONARY.LABEL.READ, dictionary)}
                                                                </>
                                                        }
                                                    </div>
                                                </td>
                                            }
                                        </tr>

                                    )
                                })
                            }
                            {
                                isUsingFilter && dataList && dataList.length === 0 &&

                                <tr
                                    className="notification-list-row-noData"
                                >
                                    <td
                                    >
                                    </td>
                                    <td
                                        className="notification-list-column-text"
                                    >
                                    </td>
                                    <td
                                    >
                                    </td>
                                    <td
                                    >
                                    </td>
                                    {
                                        !sent &&
                                        <td
                                            className="notification-list-column-icon"
                                        >


                                        </td>
                                    }
                                </tr>
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container >
    )
}

export default IndexNotification;