/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 29/08/2022                                        
* @Description Notifications for clients                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/


import React from 'react';
import {
    Col,
    Row
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { InputSelect } from '../Form/InputSelect';
import { INPUT_SELECTS } from '../../constants/inputSelects';

export const ClientNotification = () => {

    const { clients } = useSelector((state) => state.inputSelect);
    return (
        <Row
            className='mt-3 notification-separation'>
            <Col
                xs="12"
                sm="6"
                md="6"
                lg="5"
                xl="5"
                xxl="4"
            >
                <InputSelect
                    label=''
                    options={clients?.options}
                    inputId="customer"
                    filterType={INPUT_SELECTS.CLIENTS}
                    inputName="customer"
                    className="filter__xl filter__fixHeight"
                    inputType="checkbox"
                    visibleSelectedValue={clients?.selectedLabel}
                    hasSelectAll={true}
                />
            </Col>
        </Row>
    )
}
