import hasParamIsFromMobile from "../../../utils/hasParamIsFromMobile";
import { parseJwt } from "./jwt";

const REACT_APP_API_URL = "https://tech.ideauto.api.babooni.com/api";
const REACT_APP_API_VERSION = "v1";
const apiURL = `${REACT_APP_API_URL}/${REACT_APP_API_VERSION}`;

const fetchWithToken = (endPoint, data, method = "GET", token) => {
  const url = `${apiURL}/${endPoint}`;
  const parsedToken = JSON.parse(sessionStorage.getItem("ideauto_u"));
  let _token = token ? token : parsedToken.token;
  _token = _token ? _token : null;

  const headers = {
    "Content-type": "application/json",
    token: _token,
    isMobileApp: hasParamIsFromMobile(),
  };

  const formData = new URLSearchParams();
  if (method === "POST" && data) {
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  }

  if (method === "GET") {
    return fetch(url, {
      method,
      headers: headers,
    });
  } else {
    return fetch(url, {
      method,
      headers: headers,
      body: JSON.stringify(data),
    });
  }
};

const getAllRoles = async (token) => {
  // endPoint, data = {}, method = 'GET', token
  try {
    const rolesSession = sessionStorage.getItem("roles");
    if (false) {
      return JSON.parse(rolesSession);
    } else {
      const resp = await fetchWithToken("permissions", {}, "GET", token);
      const body = await resp.json();
      if (body.success) {
        const {
          data: { permissions },
        } = body;
        const roles = permissions.map((_item) => {
          return {
            id: _item.value,
            value: _item.name,
          };
        });
        sessionStorage.setItem("roles", JSON.stringify(roles));
        return roles;
      }
    }
  } catch (error) {
    console.error(error);
  }
};
const getUserData = async (token) => {
  try {
    const parsedToken = parseJwt(token);
    if (parsedToken) {
      const { userId } = parsedToken;
      const resp = await fetchWithToken(`users/${userId}`, {}, "GET", token);
      const body = await resp.json();

      if (body.success) {
        const { data } = body;
        const { user } = data;
        const roles = await getAllRoles(token);
        if (roles) {
          const selectedRole = roles?.filter(
            (_item) => _item.id === user.role
          )[0];
          if (selectedRole && selectedRole.length > 0) {
            user.roleName = roles?.filter(
              (_item) => _item.id === user.role
            )[0].value;
          }
        }
        return user;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.error(error);
  }
};
const getClientData = async (clientId, token) => {
  try {
    const resp = await fetchWithToken(
      `customers/${clientId}`,
      {},
      "GET",
      token
    );
    const body = await resp.json();

    if (body.success) {
      const { data } = body;
      const { customer } = data;
      // if (customer) {
      //     switch (customer.colorId) {
      //         case '1':
      //             customer.color = '#FFDF26';
      //             break;
      //         default:
      //             customer.color = COLORS.lightGrey;
      //             break;
      //     }
      // }

      return customer;
    } else {
      return false;
    }
  } catch (error) {
    console.error(error);
  }
};
const getAllProducts = async (token, query = "", limit = "100", page = "") => {
  // endPoint, data = {}, method = 'GET', token
  try {
    const valuesFromSession = sessionStorage.getItem("products");
    if (valuesFromSession) {
      return valuesFromSession;
    } else {
      const resp = await fetchWithToken(
        `products?name=&customerName=&url=&limit=${limit}&page=${page}`,
        {},
        "GET",
        token
      );
      const body = await resp.json();
      if (body.success) {
        const {
          data: { products },
        } = body;
        sessionStorage.setItem("products", JSON.stringify(products));
        return products;
      } else {
        return [];
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const getProductsFromUser = async (token, products) => {
  try {
    const userData = await getUserData(token);
    // const products = await getAllProducts(token);

    if (userData) {
      // userData.products = [
      //     "5528769c-5b2b-4f40-b23d-cc4c39e2e136",
      //     "3a0d7cad-8829-43d0-9c28-a109452f9374",
      //     "12d9bc9e-9291-41e3-8221-4b8daba144d5"
      // ];
      const userDataProducts = userData.products;
      if (products) {
        const _appOptions = products.filter((_product) =>
          userDataProducts.includes(_product.id)
        );
        return _appOptions;
      }
    }
    return [];
  } catch (error) {
    console.error(error);
  }
};

export { getUserData, getAllProducts, getProductsFromUser, getClientData };
