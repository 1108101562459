/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 05/09/2022                                        
* @Description Date picker actions                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { types } from "../types/types";


export const setDatePickerSetStartDate = (payload) => ({
    type: types.datePickerSetStartDate,
    payload
});
export const setDatePickerSetEndDate = (payload) => ({
    type: types.datePickerSetEndDate,
    payload
});
export const setDatePickerToggle = (payload) => ({
    type: types.datePickerToggle,
    payload
});