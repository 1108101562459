/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 05/07/2022
 * @Description Select/Unselect all option
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllOptions,
  unSelectAllOptions,
} from "../../actions/inputSelectActions";
import { DICTIONARY } from "../../constants/dictionary";
import { INPUT_SELECTS } from "../../constants/inputSelects";
import { translate } from "../../utils/translate";

export const SelectAllOption = (props) => {
  const { filterType, inputId } = props;
  const { clients } = useSelector((state) => state.inputSelect);
  const dispatch = useDispatch();
  const parentInputId = `selectAll_${inputId}`;
  const inputType = "checkbox";
  const { isOptionSelectAllChecked: isOptionSelectAllCheckedForClients } =
    clients;
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);

  const handleSelectAllOption = () => {
    const checked =
      filterType === INPUT_SELECTS.CLIENTS &&
      isOptionSelectAllCheckedForClients;
    if (checked) {
      dispatch(unSelectAllOptions({ inputSelectType: filterType, dictionary }));
    } else if (!checked) {
      dispatch(selectAllOptions({ inputSelectType: filterType, dictionary }));
    } else {
      console.error("INVALID VALUE FOR CHECKED => ", checked);
    }
  };

  return (
    <div className="input-option">
      <input
        id={parentInputId}
        type={inputType}
        onChange={handleSelectAllOption}
        checked={
          filterType === INPUT_SELECTS.CLIENTS &&
          isOptionSelectAllCheckedForClients
        }
      />
      <label className="label-pointer" htmlFor={parentInputId}>
        {translate(DICTIONARY.PLACEHOLDER.SELECT_ALL, dictionary)}
      </label>
    </div>
  );
};
