/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 29/08/2022                                        
* @Description Message for notification                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/


import React from 'react';
import {
    Col,
    Form,
    Row
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DICTIONARY } from '../../constants/dictionary';
import { translate } from '../../utils/translate';

export const NotificationMessage = (props) => {
    const {
        handleUserInputChange,
        titleName,
        textName,
        isRead = false,
        showDate = false
    } = props;

    const { current: { dictionary } } = useSelector((state) => state.translation);
    const { data } = useSelector((state) => state.notification);
    let title = '';
    let message = '';
    let creationDate = '';
    if (isRead) {
        creationDate = data?.creationDate ? data.creationDate : '';
        title = data?.title ? data.title : '';
        message = data?.text ? data.text : '';
    }
    return (
        <>
            <Row className='section-content mt-3 ml-2'>
                <Col
                >
                    <div className='subsection-name'>
                        <div>{translate(DICTIONARY.TITLE.NOTIFICATION_DATA, dictionary)}</div>
                    </div>
                </Col>
                {
                    showDate &&
                    <Col
                    >
                        <div className='subsection-name justify-content-end'>{translate(DICTIONARY.LABEL.RECEPTION_DATE, dictionary)}:&nbsp;{creationDate}</div>
                    </Col>
                }
            </Row>
            <Row
                className='mt-3 notification-separation'>
                <Col
                    xs="12"
                    sm="6"
                    md="6"
                    lg="5"
                    xl="5"
                    xxl="4"
                >
                    <Form.Control
                        type="text"
                        placeholder={`${isRead ? title : `${translate(DICTIONARY.PLACEHOLDER.TITLE, dictionary)} *`}`}
                        className=""
                        name={`${titleName}`}
                        onInput={handleUserInputChange}
                        disabled={isRead}
                    />
                </Col>
            </Row>
            <Row className='notification-separation'>
                <Col>
                    <Form.Control
                        as="textarea"
                        placeholder={`${isRead ? message : `${translate(DICTIONARY.PLACEHOLDER.MESSAGE, dictionary)} *`}`}
                        className=""
                        name={`${textName}`}
                        onInput={handleUserInputChange}
                        disabled={isRead}
                        rows="7"
                    />
                </Col>
            </Row>
        </>
    )
}
