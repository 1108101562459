const getFromSession = (key) => {
    const valuesFromSession = sessionStorage.getItem(key);

    if (valuesFromSession) {
        return JSON.parse(valuesFromSession);
    } else {
        return null;
    }
}

const setForSession = (key, values) => {
    const valuesForSession = JSON.stringify(values);

    sessionStorage.setItem(key, valuesForSession);

}

export {
    getFromSession,
    setForSession
}