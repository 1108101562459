import React from 'react';
import { useContext } from 'react';
import { APP } from '../../../constants/app';
import { useAuth } from '../../../context/AuthContext';
import { ClientContext } from '../../../context/ClientContext';
import { ProductContext } from '../../../context/ProductContext';
import { UserContext } from '../../../context/UserContext';
import './ProductAvailable.css';


export const ProductAvailable = (props) => {
    const {
        id,
        name,
        icon,
        color,
        index,
        isEditable,
        isSelectedInClient,
        isUsedInClient = false,
        isUsedInUser = false
    } = props;

    const _icon = icon && icon !== '' ? icon : `${process.env.REACT_APP_BROWSER_ROUTE}/assets/img/default/apps_black_24dp.svg`;
    const altImg = `image_${index}`;

    const { dispatch, toggleProductClient, state: stateClient } = useContext(ClientContext);
    const { clientData } = stateClient;
    const { state: { userData }, toggleProductUser, dispatch: dispatchUser } = useContext(UserContext);
    const { state: stateProduct } = useContext(ProductContext);
    const { loadByClient: loadByClientFromProductState, products } = stateProduct;
    const { state: { token } } = useAuth();

    let pc = '';
    if (isSelectedInClient) {
        pc = 'product';
    } else {
        pc = 'product disabled';
    }
    if (isUsedInUser) {
        const userProducts = userData ? userData.products : [];
        let isSelectedInUser = false;
        if (userProducts) {
            let selectedProductsInUser = userProducts.filter(_prod => _prod === id);
            if (selectedProductsInUser.length === 0) {
                const initialUserData = sessionStorage.getItem('u:start');
                if (initialUserData !== null) {
                    const data = JSON.parse(initialUserData);
                    if (data?.products) {
                        selectedProductsInUser = data.products.filter(_prod => _prod === id);
                    }
                }
            }
            isSelectedInUser = selectedProductsInUser.length > 0;
        }
        if (isSelectedInUser) {
            pc = 'product'
        } else {
            pc = 'product disabled';
        }
    }

    pc = isEditable ? `${pc} productAvailable-selectable` : pc;

    const handleClickProduct = (ev) => {
        if (isEditable) {
            if (isUsedInClient) {
                toggleProductClient(dispatch, { id, name });
            }
            if (isUsedInUser) {
                toggleProductUser(dispatchUser, id);
            }
        } else {
            return false;
        }
    }

    const handleClickOpenProductAsClient = () => {
        const product = products.filter((_p) => _p.id === id);
        const { url } = product[0];
        const isRedirector = url === APP.LAUNCHER_REDIRECTOR_URL;
        const { id: idClient } = clientData;
        if (isRedirector) {
            const hrefRedirector = `${APP.LAUNCHER_REDIRECTOR_URL}?_i=${token}&_u=${id}&_s=841110&_l=1`;
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = hrefRedirector;
            form.name = 'salto';
            form.acceptCharset = 'UTF-8';
            // form.target = '_blank';

            const operacion = document.createElement('input');
            operacion.type = 'hidden';
            operacion.name = 'operacion';
            operacion.value = '5';

            const login = document.createElement('input');
            login.type = 'hidden';
            login.name = 'login';
            login.value = sessionStorage.getItem('login');

            const idProducto = document.createElement('input');
            idProducto.type = 'hidden';
            idProducto.name = 'idProducto';
            idProducto.value = id;

            const idCliente = document.createElement('input');
            idCliente.type = 'hidden';
            idCliente.name = 'idCliente';
            idCliente.value = idClient;

            form.appendChild(operacion);
            form.appendChild(login);
            form.appendChild(idProducto);
            form.appendChild(idCliente);
            document.body.appendChild(form);
            form.submit();

        } else if (url !== '') {
            //&_s=841110
            const urlForRedirect = `${url}?_i=${token}&_s=841110&_l=1&_c=${idClient}`;
            window.location.replace(urlForRedirect);
        }
    }



    return (
        <div
            className={pc}
            onClick={loadByClientFromProductState ? handleClickOpenProductAsClient : handleClickProduct}
        >
            <div className={color}>
                <img className='product-icon' src={_icon} alt={altImg} />
            </div>
            <div className="product-name">{name}</div>
        </div>
    )
}
