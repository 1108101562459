/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 07/10/2022                                        
* @Description Actions for product access parameters                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { SESSION_KEYS } from "../constants/session";
import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import getTokenFromUrl from "../utils/getTokenFromUrl";
import { parseJwt } from "../utils/jwt";
import { populateEndpointWithParams } from "../utils/populateEndpointWithParams";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import ENDPOINTS from "./endpoints";

const DASHBOARD_ID = process.env.REACT_APP_DASHBOARD_ID ? process.env.REACT_APP_DASHBOARD_ID : 300;

export const setLanguageCode = (languageCode) => ({
    type: types.parameterSetLanguage,
    payload: languageCode
});


export const getProductAccessParameters = () => {
    return async (dispatch) => {
        try {
            let ideautoU = JSON.parse(sessionStorage.getItem('ideauto_u'));
            if (!ideautoU) {
                ideautoU = getTokenFromUrl();
            }
            const token = ideautoU?.token ? ideautoU.token : ideautoU;
            const parsedToken = parseJwt(token);
            const { userId = '' } = parsedToken;

            const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
            let url = `${apiURL}/${ENDPOINTS.PARAMETERS_ACCESS.GET.ACCESSS_PRODUCTS}/${DASHBOARD_ID}?userId=`;

            const resp = await fetch(
                url,
                {
                    method: 'GET',
                    headers: {
                        'token': token
                    }
                }
            );
            const body = await resp.json();
            if (body.success) {
                if (body?.data) {
                    const { data } = body;
                    const { languageCode } = data;
                    dispatch(setLanguageCode(languageCode));
                    sessionStorage.setItem(SESSION_KEYS.USER.LANGUAGE_CODE, languageCode);
                }
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}



export const getLanguageCode = () => {
    return async (dispatch) => {
        try {
            let ideautoU = JSON.parse(sessionStorage.getItem('ideauto_u'));
            if (!ideautoU) {
                ideautoU = getTokenFromUrl();
            }
            const token = ideautoU?.token ? ideautoU.token : ideautoU;
            const parsedToken = parseJwt(token);
            const { userId = '' } = parsedToken;
            const _endpoint = ENDPOINTS.USER.GET.PROFILE;
            const endpointParams = [
                {
                    name: ':id',
                    value: userId
                }
            ];
            const endpoint = populateEndpointWithParams({ endpoint: _endpoint, endpointParams });

            const resp = await fetchWithToken(
                endpoint,
                {
                    method: 'GET',
                    headers: {
                        'token': token
                    }
                }
            );
            const body = await resp.json();
            if (body.success) {
                if (body?.data) {
                    const { data: { user } } = body;
                    const { languageCode } = user;
                    dispatch(setLanguageCode(languageCode));
                    sessionStorage.setItem(SESSION_KEYS.USER.LANGUAGE_CODE, languageCode);
                }
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}