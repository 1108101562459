/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 31/08/2022                                        
* @Description Notification send type                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationSetSendType, setNotificationSetSendTypeBoth, setNotificationSetSendTypeEmail, setNotificationSetSendTypePlatform } from '../../actions/notification';
import { DICTIONARY } from '../../constants/dictionary';
import { SEND_TYPES } from '../../constants/ntoificationSendTypes';
import { translate } from '../../utils/translate';

export const NotificationSendType = (props) => {
    const { isRead = false } = props;

    const { sendType } = useSelector((state) => state.notification);
    const { current: { dictionary } } = useSelector((state) => state.translation);
    const [isSwitchOnEmail, setIsSwitchOnEmail] = useState(false);
    const [isSwitchOnPlatform, setIsSwitchOnPlatform] = useState(false);
    const [isSwitchOnBoth, setIsSwitchOnBoth] = useState(false);
    const dispatch = useDispatch();

    const onSwitchActionPlatform = () => {
        setIsSwitchOnPlatform(!isSwitchOnPlatform);
        setCorrectSendTypeNotification({
            isSwitchOnEmail,
            isSwitchOnPlatform: !isSwitchOnPlatform,
            isSwitchOnBoth
        });
    };
    const onSwitchActionEmail = () => {
        setIsSwitchOnEmail(!isSwitchOnEmail);
        setCorrectSendTypeNotification({
            isSwitchOnEmail: !isSwitchOnEmail,
            isSwitchOnPlatform,
            isSwitchOnBoth
        });
    };
    const onSwitchActionBoth = () => {
        setIsSwitchOnPlatform(false);
        setIsSwitchOnEmail(false);
        setIsSwitchOnBoth(!isSwitchOnBoth);
        setCorrectSendTypeNotification({
            isSwitchOnBoth: !isSwitchOnBoth,
            isSwitchOnEmail,
            isSwitchOnPlatform
        });
    };
    const setCorrectSendTypeNotification = (props) => {
        const { isSwitchOnPlatform, isSwitchOnEmail, isSwitchOnBoth } = props;

        if ((isSwitchOnPlatform && isSwitchOnEmail) || isSwitchOnBoth) {
            dispatch(setNotificationSetSendTypeBoth());
        } else if (isSwitchOnPlatform) {
            dispatch(setNotificationSetSendTypePlatform());
        } else if (isSwitchOnEmail) {
            dispatch(setNotificationSetSendTypeEmail());
        } else {
            dispatch(setNotificationSetSendType(''));
        }
    }


    return (
        <>
            <Row className='section-content mt-3 ml-2'>
                <Col
                >
                    <div className='subsection-name'>
                        <div>
                            {translate(DICTIONARY.TITLE.SEND_METHOD, dictionary)}
                        </div>
                    </div>
                </Col>
            </Row>
            {
                isRead && sendType === SEND_TYPES.EMAIL &&
                <Row
                    className='mt-2 notification-separation'>
                    <Col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="5"
                        xl="5"
                        xxl="2"
                    >
                        <Form.Check
                            type="switch"
                            onChange={onSwitchActionEmail}
                            id="switch-sendEmail"
                            label={translate(DICTIONARY.LABEL.EMAIL, dictionary)}
                            checked={true}
                            name="email"
                            value="email"
                            className="input-switch"
                            disabled={isRead}
                        />
                    </Col>
                </Row>
            }
            {
                !isRead &&
                <Row
                    className='mt-2 notification-separation'>
                    <Col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="5"
                        xl="5"
                        xxl="2"
                    >
                        <Form.Check
                            type="switch"
                            onChange={onSwitchActionEmail}
                            id="switch-sendEmail"
                            label={translate(DICTIONARY.LABEL.EMAIL, dictionary)}
                            checked={isSwitchOnEmail}
                            name="email"
                            value="email"
                            className="input-switch"
                            disabled={isSwitchOnBoth}
                        />
                    </Col>
                </Row>
            }
            {
                isRead && sendType === SEND_TYPES.PLATFORM &&
                <Row
                    className='notification-separation'>
                    <Col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="5"
                        xl="5"
                        xxl="2"
                    >
                        <Form.Check
                            type="switch"
                            onChange={onSwitchActionPlatform}
                            id="switch-sendPlatform"
                            label={translate(DICTIONARY.LABEL.PLATFORM, dictionary)}
                            checked={true}
                            name="platform"
                            value="platform"
                            className="input-switch"
                            disabled={isSwitchOnBoth || isRead}
                        />
                    </Col>
                </Row>
            }
            {
                !isRead &&
                <Row
                    className='notification-separation'>
                    <Col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="5"
                        xl="5"
                        xxl="2"
                    >
                        <Form.Check
                            type="switch"
                            onChange={onSwitchActionPlatform}
                            id="switch-sendPlatform"
                            label={translate(DICTIONARY.LABEL.PLATFORM, dictionary)}
                            checked={isSwitchOnPlatform}
                            name="platform"
                            value="platform"
                            className="input-switch"
                            disabled={isSwitchOnBoth || isRead}
                        />
                    </Col>
                </Row>
            }
            {
                isRead && sendType === SEND_TYPES.PLATFORM_EMAIL &&
                <Row
                    className='notification-separation'>
                    <Col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="5"
                        xl="5"
                        xxl="2"
                    >
                        <Form.Check
                            type="switch"
                            onChange={onSwitchActionBoth}
                            id="switch-sendBoth"
                            label={translate(DICTIONARY.LABEL.EMAIL_AND_PLATFORM, dictionary)}
                            checked={true}
                            name="platform_email"
                            value="platform_email"
                            className="input-switch"
                            disabled={isRead}
                        />
                    </Col>
                </Row>
            }
            {
                !isRead &&
                <Row
                    className='notification-separation'>
                    <Col
                        xs="12"
                        sm="6"
                        md="6"
                        lg="5"
                        xl="5"
                        xxl="2"
                    >
                        <Form.Check
                            type="switch"
                            onChange={onSwitchActionBoth}
                            id="switch-sendBoth"
                            label={translate(DICTIONARY.LABEL.EMAIL_AND_PLATFORM, dictionary)}
                            checked={isSwitchOnBoth}
                            name="platform_email"
                            value="platform_email"
                            className="input-switch"
                        />
                    </Col>
                </Row>
            }
        </>
    )
}
