/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 15/09/2022                                        
* @Description Actions for reporting                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { FILTER_ATTRIBUTES } from "../constants/filter";
import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { formatDatesInArray } from "../utils/formatDates";
import getToken from "../utils/getToken";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import ENDPOINTS from "./endpoints";


export const setReportingSetList = (payload) => ({
    type: types.reportingSetList,
    payload
});
export const setReportingFilterList = (payload) => ({
    type: types.reportingSetFilteredList,
    payload
});
const setReportingSetFilters = (payload) => ({
    type: types.reportingSetFilters,
    payload
})
const setReportingSetAllLoaded = (payload) => ({
    type: types.reportingSetAllLoaded,
    payload
})
const setReportingSetNextPage = (payload) => ({
    type: types.reportingSetNextPage,
    payload
})

export const setReportingShowDatePickerForEndDate = (payload) => ({
    type: types.reportingShowDatePickerForEndDate,
    payload
});
export const setReportingShowDatePickerForStartDate = (payload) => ({
    type: types.reportingShowDatePickerForStartDate,
    payload
});

export const setReportingCloseAllDatePickers = () => ({
    type: types.reportingCloseAllDatePickers
});

const setReportingSetFilteredListWhenScroll = (payload) => ({
    type: types.reportingSetFilteredListWhenScroll,
    payload
});

export const getListWithParams = async (args) => {
    try {

        const {
            limit = '',
            page = '',
            filters = null
        } = args;
        const token = getToken();
        let endPointUrl = `${ENDPOINTS.REPORTING.GET.LIST}?page=${page}&limit=${limit}`;


        endPointUrl = filters ? `${endPointUrl}&startDateOrder=${filters.startDateOrder}` : `${endPointUrl}&startDateOrder=desc`;
        endPointUrl = filters ? `${endPointUrl}&startDate=${filters.startDate}` : `${endPointUrl}&startDate=`;
        endPointUrl = filters ? `${endPointUrl}&endDate=${filters.endDate}` : `${endPointUrl}&endDate=`;
        endPointUrl = filters ? `${endPointUrl}&endDateOrder=${filters.endDateOrder}` : `${endPointUrl}&endDateOrder=desc`;
        endPointUrl = filters ? `${endPointUrl}&customer=${filters.customer}` : `${endPointUrl}&customer=`;
        endPointUrl = filters ? `${endPointUrl}&username=${filters.username}` : `${endPointUrl}&username=`;
        const resp = await fetchWithToken(
            endPointUrl,
            {},
            'GET',
            token
        );
        const body = await resp.json();
        if (body.success) {
            const { data: { count, reporting } } = body;
            let _reporting = reporting.map((item) => {
                const { customers } = item;
                let _customers = '';
                if (customers && customers.length > 0) {
                    _customers = customers.map((_c) => _c.name).join(', ');
                }
                return {
                    ...item,
                    customers: `${_customers} `
                }
            });
            return {
                count,
                reporting: _reporting
            }
        } else {
            redirectToNoAuthorizedURL(resp);
        }
    } catch (error) {
        console.error(error);
    }
}
export const getListWithPagination = (params) => {
    return async (dispatch) => {
        try {
            const { limit, page: _page } = params;
            let page = _page;

            let allElementsOfList = [];
            const reportingWithParams = await getListWithParams({
                limit,
                page
            });
            const { reporting } = reportingWithParams;

            const list = formatDatesInArray(reporting);
            if (list && list.length > 0) {
                if (list.length < limit) {
                    dispatch(setReportingSetAllLoaded(true));
                }
                allElementsOfList = [
                    ...allElementsOfList,
                    ...list
                ];
                page++;
                dispatch(setReportingSetNextPage(page));
            }

            if (allElementsOfList) {
                dispatch(setReportingSetList(allElementsOfList));
                dispatch(setReportingSetNextPage(page));
            }

        } catch (error) {
            console.error(error);
        }
    }
}
export const getListByFilter = (params) => {
    return async (dispatch) => {
        try {
            const { filters = null, attribute, search, limit = 50, page = 1 } = params;
            let newFilters = {
                startDate: filters?.startDate ? filters.startDate : '',
                endDate: filters?.endDate ? filters.endDate : '',
                startDateOrder: filters?.startDateOrder ? filters.startDateOrder : 'asc',
                endDateOrder: filters?.endDateOrder ? filters.endDateOrder : 'asc',
                customer: filters?.customer ? filters.customer : '',
                username: filters?.username ? filters.username : '',
            }
            const _FILTER_ATTRIBUTES = FILTER_ATTRIBUTES.REPORTING;
            switch (attribute) {
                case _FILTER_ATTRIBUTES.STARTDATE:
                    newFilters.startDate = search;
                    break;
                case _FILTER_ATTRIBUTES.ENDDATE:
                    newFilters.endDate = search;
                    break;
                case _FILTER_ATTRIBUTES.STARTDATEORDER:
                    newFilters.startDateOrder = search;
                    break;
                case _FILTER_ATTRIBUTES.ENDDATEORDER:
                    newFilters.endDateOrder = search;
                    break;
                case _FILTER_ATTRIBUTES.CUSTOMER:
                    newFilters.customer = search;
                    break;
                case _FILTER_ATTRIBUTES.USERNAME:
                    newFilters.username = search;
                    break;
                default:
                    break;
            }

            const {
                startDate,
                endDate,
                startDateOrder,
                endDateOrder,
                customer,
                username
            } = newFilters;
            const endPointUrl = `${ENDPOINTS.REPORTING.GET.LIST}?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}&startDateOrder=${startDateOrder}&endDateOrder=${endDateOrder}&customer=${customer}&username=${username} `;

            const resp = await fetchWithToken(
                endPointUrl,
                {},
                'GET'
            );
            const body = await resp.json();
            if (body.success) {
                const { data: { reporting } } = body;
                const list = formatDatesInArray(reporting);
                dispatch(setReportingFilterList(list));
                dispatch(setReportingSetFilters({
                    attribute,
                    search
                }));
                dispatch(setReportingSetAllLoaded(true));
                let _page = page;
                _page++;
                dispatch(setReportingSetNextPage(_page));
                sessionStorage.setItem('allItemsLoaded', false);
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}
export const getListByFilterWithPagination = (params) => {
    return async (dispatch) => {
        try {
            const { filters = null, limit = 50, page: _page } = params;

            let page = _page;
            let allElementsOfList = [];
            const reportingWithParams = await getListWithParams({
                filters,
                limit,
                page
            });
            const { reporting } = reportingWithParams;

            const list = formatDatesInArray(reporting);
            if (list && list.length > 0) {
                if (list.length < limit) {
                    dispatch(setReportingSetAllLoaded(true));
                }
                allElementsOfList = [
                    ...allElementsOfList,
                    ...list
                ];
                page++;
                dispatch(setReportingSetNextPage(page));
            }

            if (allElementsOfList) {
                dispatch(setReportingSetFilteredListWhenScroll(allElementsOfList));
                // dispatch(setReportingSetList(allElementsOfList));
                dispatch(setReportingSetNextPage(page));
            }

        } catch (error) {
            console.error(error);
        }
    }
}
