/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 11/03/2022                                        
* @Description Filter options and suboptions                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toggleCheckOption } from '../../actions/inputSelectActions';
import { FILTER_ATTRIBUTES } from '../../constants/filter';

export const FilterOption = (props) => {
    const {
        filterType,
        currentOption,
        inputId,
        index,
        inputType,
        inputName,
        value,
        labelTranslate,
        callBackOption = () => { return false },
        attribute = null
    } = props;
    const { options: subOptions, checked, code } = currentOption;
    const { current: { dictionary } } = useSelector((state) => state.translation);

    const dispatch = useDispatch();
    const arrowDown = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_down_black_24dp.svg`;
    const arrowUp = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_up_black_24dp.svg`;

    const [showSubOptions, setShowSubOptions] = useState(false);
    const { inputSelect } = useSelector(state => state);
    const { notificationFilterSendType, notificationFilterState } = inputSelect;


    const hasSubOptions = subOptions && subOptions.length > 1;
    const onClickFunction = hasSubOptions ? () => setShowSubOptions(state => !state) : () => { return false };

    let parentOptionValue = value;
    if (subOptions && subOptions.length === 1) {
        parentOptionValue = subOptions[0].value;
    }
    const parentInputId = `${inputId}_parentOption_${index}`;

    const handleOnChangeSelectedOption = (ev, selected, selectedLabel, isSubOption, optionCode, item = null) => {
        // const isChecked = ev.target.checked;
    }

    const checkInput = (isSubOption, selectedLabel, id, optionCode, isChecked, item) => {
        dispatch(toggleCheckOption({ inputSelectType: filterType, id, dictionary }));
        let _search = id;
        switch (attribute) {
            case FILTER_ATTRIBUTES.NOTIFICATION_TYPE:
                const { isOptionSelectAllChecked: isOptionSelectAllCheckedSendType,
                    selectedValueForAll: selectedValueForAllSendType,
                    options: optionsSendType } = notificationFilterSendType;
                const checkedSendType = optionsSendType.map((item) => {
                    return {
                        ...item,
                        checked: item.id === id ? !item.checked : item.checked
                    }
                }).filter((item) => item.checked);
                const countCheckedSendType = optionsSendType.filter((item) => item.checked).length;
                const countOptionsSendType = optionsSendType.length;

                if (!isChecked && checkedSendType.length > 0) {
                    _search = checkedSendType[0].id;
                } else if (isOptionSelectAllCheckedSendType) {
                    _search = selectedValueForAllSendType;
                } else if (isChecked && countCheckedSendType === countOptionsSendType - 1) {
                    _search = selectedValueForAllSendType;
                } else if (!isChecked) {
                    _search = '';
                }
                break;

            case FILTER_ATTRIBUTES.NOTIFICATION_STATE:

                const {
                    isOptionSelectAllChecked: isOptionSelectAllCheckedState,
                    selectedValueForAll: selectedValueForAllState,
                    options: optionsState
                } = notificationFilterState;
                const checkedState = optionsState.map((item) => {
                    return {
                        ...item,
                        checked: item.id === id ? !item.checked : item.checked
                    }
                }).filter((item) => item.checked);
                const countCheckedState = optionsState.filter((item) => item.checked).length;
                const countOptionsState = optionsState.length;
                if (!isChecked && checkedState.length > 0) {
                    _search = checkedState[0].id;
                } else if (isOptionSelectAllCheckedState) {
                    _search = selectedValueForAllState;
                } else if (isChecked && countCheckedState === countOptionsState - 1) {
                    _search = selectedValueForAllState;
                } else if (!isChecked) {
                    _search = '';
                }
                break;
            default:
                break;
        }

        callBackOption({
            search: _search,
            attribute
        });
    }

    let onClickBlockInput = hasSubOptions ? () => { return false } : () => checkInput(false, labelTranslate, value, code, !checked, currentOption);
    if (checked && inputType === 'radio') {
        onClickBlockInput = () => { return false };
    }

    const onClickInputCheckboxMultipleOptions = hasSubOptions ? (ev) => handleOnChangeSelectedOption(ev, value, labelTranslate, false, code, currentOption) : () => { return false };


    return (
        <div id={`${inputId}_parentOption_${index}`} key={`${inputId}_parentOption_${index}`} >
            <div className="input-option" onClick={onClickBlockInput}>
                {
                    inputType === 'checkbox' &&
                    <input
                        id={parentInputId}
                        type={inputType}
                        name={inputName}
                        value={parentOptionValue}
                        onChange={onClickInputCheckboxMultipleOptions}
                        checked={checked}
                    />
                }
                {
                    inputType === 'radio' && checked &&
                    <input
                        id={parentInputId}
                        type={inputType}
                        name={inputName}
                        value={parentOptionValue}
                        // onChange={onClickInputCheckboxMultipleOptions}
                        checked
                    />
                }
                {
                    inputType === 'radio' && !checked &&
                    <input
                        id={parentInputId}
                        type={inputType}
                        name={inputName}
                        value={parentOptionValue}
                        onChange={onClickInputCheckboxMultipleOptions}
                    />
                }
                <div className="label-and-arrow"
                    onClick={onClickFunction}
                >
                    {
                        hasSubOptions ?
                            showSubOptions ?
                                <img src={arrowUp} alt="arrowUp" />
                                :
                                <img src={arrowDown} alt="arrowDown" />
                            :
                            <></>
                    }
                    <label
                        className="label-pointer"
                        htmlFor={parentInputId}
                    >{labelTranslate}</label>
                    {/* <label className="label-pointer" >{labelTranslate}</label> */}
                </div>
            </div>
            {
                showSubOptions && subOptions &&
                <div className="input-subOptions">
                    {
                        subOptions.map((item, index) => {
                            const { label, value, checked, code } = item;
                            const labelTranslate = label;
                            const subOptionInputId = `${inputId}_subOption_${index}`;

                            return (
                                <div
                                    // onClick={handleOpenInputSelect}
                                    // onClick={() => checkInput(true, labelTranslate, value, code, !checked)}
                                    className="input-option input-subOption"
                                    key={subOptionInputId}
                                >
                                    {
                                        inputType === 'checkbox' &&
                                        <input
                                            id={subOptionInputId}
                                            type={inputType}
                                            name={inputName}
                                            value={value}
                                            onChange={(ev) => handleOnChangeSelectedOption(ev, value, labelTranslate, true, code, item)}
                                            checked={checked}
                                        />
                                    }
                                    {
                                        inputType === 'radio' && checked &&
                                        <input
                                            id={subOptionInputId}
                                            type={inputType}
                                            name={inputName}
                                            value={value}
                                            onChange={(ev) => handleOnChangeSelectedOption(ev, value, labelTranslate, true, code, item)}
                                            checked
                                        />
                                    }
                                    {
                                        inputType === 'radio' && !checked &&
                                        <input
                                            id={subOptionInputId}
                                            type={inputType}
                                            name={inputName}
                                            value={value}
                                            onChange={(ev) => handleOnChangeSelectedOption(ev, value, labelTranslate, true, code, item)}
                                        />
                                    }
                                    <label

                                        className="label-pointer"
                                        htmlFor={subOptionInputId} >{labelTranslate}</label>
                                </div>

                            )
                        })
                    }
                </div>
            }
        </div >
    )
}
