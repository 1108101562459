/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 26/07/2022                                        
* @Description Edit permission template                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/
import React, { useEffect } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import JSONInput from 'react-json-editor-ajrm';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseTemplate, insertBaseTemplate, setErrorInJSON, setIsSubmited, setUpdateBaseTemplate } from '../../actions/permission';
import locale from 'react-json-editor-ajrm/locale/es';
import Swal from 'sweetalert2';
import { sweetAlertProps } from '../../constants/sweetAlertProps';
import COLORS from '../../constants/colors';
import { DICTIONARY } from '../../constants/dictionary';
import { translate } from '../../utils/translate';

export const EditPermission = () => {

    const dispatch = useDispatch();
    const { current: { dictionary } } = useSelector((state) => state.translation);
    const { base, error, isSubmited } = useSelector((state) => state.permission);
    const { permission, update } = base;
    const { json } = permission;
    const errorMessage = translate(DICTIONARY.MESSAGE.PERMISSION_ERROR, dictionary);


    const handleSubmit = (ev) => {
        ev.preventDefault();
        dispatch(setIsSubmited(true));
        if (error === null) {
            dispatch(insertBaseTemplate({
                json: update ? update : json
            }));
            Swal.fire({
                ...sweetAlertProps,
                text: translate(DICTIONARY.MESSAGE.PRODUCT_SAVE, dictionary),
                confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
                confirmButtonColor: COLORS.button
            }).then((sweet) => {
                if (sweet.isConfirmed) {
                    // navigate('/user', { replace: true });
                }
            });
        } else if (error !== null && isSubmited) {
            Swal.fire({
                ...sweetAlertProps,
                icon: 'error',
                text: errorMessage,
                confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
                confirmButtonColor: COLORS.button
            }).then((sweet) => {
                if (sweet.isConfirmed) {
                    // navigate('/user', { replace: true });
                }
            });
        }
    }
    const handleOnChangeJSON = (ev) => {
        const { error, jsObject } = ev;
        if (!error) {
            dispatch(setIsSubmited(false));
            dispatch(setErrorInJSON(null));
            dispatch(setUpdateBaseTemplate(jsObject));
        } else {
            dispatch(setErrorInJSON(errorMessage));
        }
    }

    useEffect(() => {
        dispatch(getBaseTemplate());
    }, []);

    return (
        <Container
            fluid
        >
            <Row>
                <Col>
                    <div className='section-name'>
                        <span className="material-icons-round section-icon">
                            settings_applications
                        </span>
                        <div>{translate(DICTIONARY.TITLE.PERMISSION_EDIT, dictionary)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col
                    className='section-content mt-3 ml-2'
                >
                    <div className='subsection-name'>
                        <div>{translate(DICTIONARY.TITLE.BASE_TEMPLATE, dictionary)}</div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form
                        className='mt-3'
                        onSubmit={handleSubmit}
                    >
                        <Row
                            className="user-row"
                        >
                            <Col
                                xs="12"
                                sm="12"
                                md="12"
                                lg="12"
                                xl="12"
                                xxl="12"
                                className=''
                            >
                                <JSONInput
                                    id='jsoneditorPermissions'
                                    placeholder={json}
                                    locale={locale}
                                    height='400px'
                                    width='100%'
                                    onChange={handleOnChangeJSON}
                                />
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                        {/* <Button
                            variant="light"
                            className="w-25 float-end button-form button button-delete"
                            onClick={handleDeleteTemplate}
                            disabled={parentIsTemplate}
                        >
                            Eliminar
                        </Button> */}
                        {/* {
                            error !== null && isSubmited &&

                            <Row
                                className="user-row">
                                <Col>
                                    <div className="errorMessage">{error}</div>
                                </Col>
                            </Row>
                        } */}
                        <Button
                            variant="secondary"
                            type="submit"
                            className="w-25 float-end button-form button">
                            {translate(DICTIONARY.BUTTON.SAVE, dictionary)}
                        </Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    )
}
