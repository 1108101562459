import { DICTIONARY } from "../../../constants/dictionary";
import ROLES from "../../../constants/roles";
import { translate } from "../../../utils/translate";

export const getRoleName = (userRole, dictionary) => {
    let roleName = '';
    switch (userRole) {
        case ROLES.USER:
            roleName = translate(DICTIONARY.LABEL.ROLE_USER, dictionary);
            break;
        case ROLES.ADMIN:
            roleName = translate(DICTIONARY.LABEL.ROLE_SUPER_ADMIN, dictionary);
            break;
        case ROLES.CUSTOMER:
            roleName = translate(DICTIONARY.LABEL.ROLE_CUSTOMER_ADMIN, dictionary)
            break;
        default:
            roleName = userRole;
            break;
    }

    return roleName;

} 
