const COLORS = {
    // darkBlue: "#0f547e",
    // lightBlue: "#D3DEE7",
    // darkGreen: "#8ed540",
    // lightGreen: "#e4ffc7",
    // darkRed: "#fa6e6e",
    // lightRed: "#f7adad",
    // darkGrey: "#6c757d",
    // lightGrey: "#fcfcfc",

    // darkBlue: "#0f547eff",
    // lightBlue: "#6a9cbaff",
    // lightGrey: "#d3dee7ff",
    // darkGrey: "#78909cff",
    // grey: "#ccccccff",
    // darkRed: "#f9bbbbff",
    // lightRed: "#f7d4d4ff",
    // darkGreen: "#b6d7a8ff",
    // green: "#cffba0ff",
    // lightGreen: "#d9ead3ff",


    darkBlue: "#0f547eff",
    lightBlue: "#6a9cbaff",
    lightGrey: "#d3dee7ff",
    darkGrey: "#78909cff",
    grey: "#ccccccff",
    turquoise: "#0097a7ff",
    lightTurquoise: "#76a5afff",
    brown: "#bf9000ff",
    lightPink: "#d5a6bdff",
    greyGreen: "#d9ead3ff",

    darkRed: "#f9bbbbff",
    lightRed: "#f7d4d4ff",

    darkGreen: "#b6d7a8ff",
    green: "#cffba0ff",
    lightGreen: "#d9ead3ff",

    user: "#6a9cbaff",
    super_admin: "#000",
    customer_admin: "#0f547eff",

    userText: "#fff",
    super_adminText: "#fff",
    customer_adminText: "#fff",

    button: "#6c757d",
    buttonRed: "#D82525",

    active: "#b6d7a8ff",
    deactive: "#f9bbbbff",
    active_until: "#F9DCBBff",
    activeText: '#000',
    deactiveText: '#000',
    active_untilText: '#000',

}

export default COLORS;