/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 02/09/2022                                        
* @Description List of receiver clients of notification                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/
import React from 'react';
import { useSelector } from 'react-redux';
import {
    Col,
    Row,
    Spinner
} from 'react-bootstrap';

export const ClientReceiverList = () => {

    const { clientsReceiverList } = useSelector((state) => state.notification);
    const { customers } = clientsReceiverList;

    const showCustomersList = customers !== undefined;
    const hasCustomers = showCustomersList && customers.length > 0;

    return (
        <Row
            className='mt-3 notification-separation'>
            <Col
                xs="12"
                sm="6"
                md="6"
                lg="5"
                xl="5"
                xxl="4"
            >
                {
                    showCustomersList ?
                        <>
                            {
                                hasCustomers &&
                                <div className="notification-clientReceiver-list">

                                    {
                                        hasCustomers &&
                                        customers.map((item) => {
                                            const { id, name } = item;
                                            // const logoUrl = logo ? `${apiURL}/customers/uploads/photo/${id}?token=${token}` : `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/logoIdeauto.png`;
                                            return (
                                                <div
                                                    key={id}
                                                    className="notification-clientReceiver-list-item"
                                                >
                                                    {/* <div
                                                className='notification-clientReceiver-logo'
                                            >
                                                <Image
                                                    src={logoUrl}
                                                    className='' />
                                            </div> */}
                                                    <div className='notification-clientReceiver-clientName'>
                                                        {name}
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            }
                        </>
                        :
                        <Spinner animation="border" role="status"></Spinner>
                }

            </Col>
        </Row>
    )
}
