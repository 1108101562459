import { types } from "../types/types";

/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 27/09/2022                                        
* @Description Translation reducer                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/
const initialState = {
    current: {}
}
export const translationReducer = (state = initialState, action) => {
    const currentFromState = state.current;
    switch (action.type) {
        case types.translationGetCurrentDictionary:
            return {
                ...state,
                current: {
                    ...currentFromState,
                    dictionary: action.payload
                }
            }
        default:
            return state;
    }
}