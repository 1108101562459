/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 27/09/2022                                        
* @Description Translate key into word                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import LOCAL_DICTIONARY from '../constants/dictionary.json';
import parse from 'html-react-parser';
export const translate = (key, dictionary) => {
    const existKeyInDictionary = dictionary !== null && dictionary !== undefined &&
        dictionary[key] !== null && dictionary[key] !== undefined;
    const existKeyInLocalDictionary = LOCAL_DICTIONARY !== null && LOCAL_DICTIONARY !== undefined &&
        LOCAL_DICTIONARY[key] !== null && LOCAL_DICTIONARY[key] !== undefined;

    if (existKeyInDictionary) {
        return parse(dictionary[key]);
    } else {
        // console.error(`KEY: ${key} doesn't exist in dictionary`);
        return key;
    }
}