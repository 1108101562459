const getTokenFromUrl = () => {
    const urlSplit = window.location.href.split('?_i=');
    if (urlSplit.length > 1) {
        const _token = urlSplit[1].substring(urlSplit[1].indexOf('_i=')).replace('_i=', '').split('&')[0];

        return _token;
    }
    return null;
}

export default getTokenFromUrl;