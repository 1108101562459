/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 07/02/2023                                        
* @Description Session keys for dashboard                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

export const SESSION_KEYS = {
    USER: {
        LANGUAGE_CODE: "u:lc"
    }
}