/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 26/08/2022
 * @Description Edit generic contact for ideuato
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { createContact, getDefaultContact } from "../../actions/contact";
import { ClientContact } from "../../components/Section/ClientContact";
import COLORS from "../../constants/colors";
import { DICTIONARY } from "../../constants/dictionary";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import { useForm } from "../../hooks/useForm";
import { translate } from "../../utils/translate";

export const EditContact = () => {
  const dispatch = useDispatch();

  const { data } = useSelector((state) => state.contact);
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);
  const { contact } = data;
  const initialStateClient = {
    email: contact?.email ? contact.email : "",
    phone: contact?.phone ? contact.phone : "",
    contactName: contact?.name ? contact.name : "",
  };
  const [formClientValues, handleClientInputChange] =
    useForm(initialStateClient);

  const handleEditContact = async (ev) => {
    ev.preventDefault();

    const { email, phone, contactName } = formClientValues;

    const payload = {
      email: email !== "" ? email : contact.email,
      phone: phone !== "" ? phone : contact.phone,
      name: contactName !== "" ? contactName : contact.name,
    };
    const response = await dispatch(createContact({ payload }));
    if (response.success) {
      Swal.fire({
        ...sweetAlertProps,
        text: translate(DICTIONARY.MESSAGE.CONTACT_SAVE, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {});
    } else {
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${response?.message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {});
    }
  };
  useEffect(() => {
    dispatch(getDefaultContact());
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="section-name">
            <span className="material-icons-round section-icon">
              support_agent
            </span>
            <div>{translate(DICTIONARY.TITLE.CONTACT_EDIT, dictionary)}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section-content mt-3 ml-2">
          <div className="subsection-name">
            <div className="title">
              {translate(DICTIONARY.TITLE.CONTACT_DATA, dictionary)}
            </div>
          </div>
        </Col>
      </Row>

      <Row className="user-row">
        <Col>
          <Form className="mt-3" onSubmit={handleEditContact}>
            <ClientContact
              handleClientInputChange={handleClientInputChange}
              setShowColorPicker={() => {
                return false;
              }}
            />
            <Button
              variant="secondary"
              className="w-25 float-end button-form button"
              type="submit"
            >
              {translate(DICTIONARY.BUTTON.SAVE, dictionary)}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
