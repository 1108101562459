import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { Container, Col, Image, Form, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ProductAvailableList } from "../../components/UI/ProductAvailableList/ProductAvailableList";
import { useAuth } from "../../context/AuthContext";
import { ClientContext } from "../../context/ClientContext";
import { useRole } from "../../context/RoleContext";
import { UserContext } from "../../context/UserContext";
import { useForm } from "../../hooks/useForm";
import Swal from "sweetalert2";
import { getUserData } from "../../components/UI/Launcher/fetchLauncher";
import {
  APP_SECTIONS,
  userAccessManager,
} from "../../components/UI/Launcher/checkPermissions";
import COLORS from "../../constants/colors";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import { useDispatch, useSelector } from "react-redux";
import { translate } from "../../utils/translate";
import { DICTIONARY } from "../../constants/dictionary";
import { setCopyProfileData } from "../../actions/user";

const CreateUser = () => {
  const dispatch = useDispatch();
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);
  const paramsForLoaded = "&_s=841110&_l=1";
  const { initialData, languageOptions } = useSelector((state) => state.user);
  const { role, customerId, languageCode } = initialData;

  let initialUserData = sessionStorage.getItem("u:start");
  if (initialUserData) {
    initialUserData = JSON.parse(initialUserData);
  }
  const initialStateUser = {
    email: "",
    password: "",
    password2: "",
    firstName: "",
    lastName: "",
    role: "",
    customerId: "",
    allowEmailNotification: true,
    ...initialUserData,
  };

  let navigate = useNavigate();
  const {
    createUser,
    uploadUserImage,
    state: stateUser,
  } = useContext(UserContext);
  const { dispatch: dispatchRole, state: stateRoles, getAllRoles } = useRole();
  const { roles } = stateRoles;

  const [photo, setPhoto] = useState({});
  const [srcImage, setSrcImage] = useState("");
  const [formUserValues, handleUserInputChange] = useForm(initialStateUser);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [isSwitchOnAllowPushNotification, setIsSwitchAllowPushNotification] =
    useState(true);

  const {
    dispatch: dispatchClient,
    state: stateClient,
    getAllClients,
    getClientData,
  } = useContext(ClientContext);
  const { clients, clientData } = stateClient;
  const {
    state: { token },
  } = useAuth();

  const { userData } = stateUser;

  const inputPhoto = useRef(null);

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  const onSwitchActionAllowPushNotification = () => {
    setIsSwitchAllowPushNotification(!isSwitchOnAllowPushNotification);
  };
  const handleInputPhoto = (ev) => {
    inputPhoto.current.click();
  };
  const [clientId, setClientId] = useState("");
  const loadClientProducts = (ev) => {
    const _clientId = ev.target.value;
    setClientId(_clientId);
    getClientData(dispatchClient, _clientId, token);
    handleUserInputChange(ev);
  };

  const handleChangeFile = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSrcImage(imageUrl);
    setPhoto(file);
  };
  const handleCreateUser = async (ev) => {
    ev.preventDefault();

    const {
      username,
      email,
      password,
      password2,
      firstName,
      lastName,
      role,
      customerId,
      languageCode,
    } = formUserValues;

    if (password !== password2) {
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: translate(DICTIONARY.MESSAGE.PASSWORDS_NOT_EQUAL, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {});
    } else {
      const data = {
        username,
        email,
        password,
        firstName,
        lastName,
        role,
        customerId: customerId === "" ? clientData?.id : customerId,
        deletedProducts: [],
        products: userData?.products ? userData.products : [],
        allowEmailNotification: isSwitchOn,
        allowPushNotification: isSwitchOnAllowPushNotification,
        languageCode,
      };
      const response = await createUser(data, token);

      if (response.success) {
        const {
          data: { user },
        } = response;
        const { id } = user;
        await uploadUserImage(id, token, photo);
        Swal.fire({
          ...sweetAlertProps,
          html: translate(DICTIONARY.MESSAGE.USER_CREATE, dictionary),
          confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
          confirmButtonColor: COLORS.button,
        }).then((sweet) => {
          if (sweet.isConfirmed) {
            // windo
            window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/user?_i=${token}${paramsForLoaded}`;
            // navigate(`/user?_i=${token}${paramsForLoaded}`, { replace: false });
          }
        });
      } else {
        Swal.fire({
          ...sweetAlertProps,
          icon: "error",
          text: `${response?.message}`,
          confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
          confirmButtonColor: COLORS.button,
        }).then((sweet) => {});
      }
    }
  };

  const [allowSelectClient, setShowMenuAdmin] = useState(false);
  const [defaultValueSelectClient, setDefaultValueSelectClient] = useState(
    translate(DICTIONARY.PLACEHOLDER.SELECT_CLIENT, dictionary)
  );

  useEffect(() => {
    if (initialUserData !== null) {
      // const data = JSON.parse(initialUserData);
      dispatch(setCopyProfileData(initialUserData));
    }
  }, []);
  useEffect(() => {
    getAllRoles(dispatchRole, token);
  }, []);

  useEffect(() => {
    const _getAllClients = async () => {
      const userData = await getUserData(token);
      if (userData) {
        const allowMenuAdmin = userAccessManager(
          APP_SECTIONS.editSelectedClient,
          userData.role
        );
        if (allowMenuAdmin) {
          await getAllClients(token);
        }
      }
    };
    _getAllClients();
  }, [token]);

  useEffect(() => {
    const getUserFromToken = async () => {
      const userData = await getUserData(token);
      if (userData) {
        const allowMenuAdmin = userAccessManager(
          APP_SECTIONS.editSelectedClient,
          userData.role
        );
        if (!allowMenuAdmin) {
          setDefaultValueSelectClient(userData.customerId);
          getClientData(dispatchClient, userData.customerId, token);
        }
        setShowMenuAdmin(allowMenuAdmin);
      }
    };
    getUserFromToken();
  }, [token]);

  useEffect(() => {
    if (customerId) {
      loadClientProducts({
        target: {
          value: customerId,
        },
      });
    }
  }, [customerId]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="section-name">
              <span className="material-icons-round section-icon">person</span>
              <div>{translate(DICTIONARY.TITLE.USER_CREATE, dictionary)}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="section-content mt-3 ml-2">
            <div className="subsection-name">
              <div>{translate(DICTIONARY.TITLE.USER_DATA, dictionary)}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form className="mt-3" onSubmit={handleCreateUser}>
              <Row className="user-row">
                <Col xs="12" sm="5" md="4" lg="3" xl="3" xxl="3" className="">
                  <div className="avatar">
                    <div className="avatar-image">
                      {srcImage && (
                        <Image
                          roundedCircle
                          src={srcImage}
                          className="avatar-border"
                        />
                      )}
                    </div>
                    <div className="avatar-button">
                      <Form.Control
                        ref={inputPhoto}
                        style={{ display: "none" }}
                        type="file"
                        onChange={handleChangeFile}
                      />
                      <Button
                        variant="secondary"
                        className="avatar-change-button"
                        onClick={handleInputPhoto}
                      >
                        {translate(
                          DICTIONARY.BUTTON.USER_ADD_PHOTO,
                          dictionary
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="7" md="8" lg="9" xl="9" xxl="9">
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Select
                        name="customerId"
                        onChange={loadClientProducts}
                        disabled={!allowSelectClient}
                      >
                        <option>
                          {translate(
                            DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
                            dictionary
                          )}
                          *
                        </option>
                        {clients ? (
                          clients.map((client, index) => {
                            const { id, name } = client;
                            const isSelected =
                              defaultValueSelectClient == id ||
                              customerId === id;

                            return (
                              <option
                                key={`client_opt_${index}_${id}`}
                                value={id}
                                selected={isSelected}
                              >
                                {name}
                              </option>
                            );
                          })
                        ) : (
                          <option
                            value={defaultValueSelectClient}
                            selected={true}
                          >
                            {clientData?.name ? clientData?.name : ""}
                          </option>
                        )}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Select
                        defaultValue={
                          role
                            ? role
                            : `${translate(
                                DICTIONARY.PLACEHOLDER.USER_TYPE,
                                dictionary
                              )} *`
                        }
                        className=""
                        name="role"
                        onChange={handleUserInputChange}
                      >
                        <option>
                          {translate(
                            DICTIONARY.PLACEHOLDER.USER_TYPE,
                            dictionary
                          )}{" "}
                          *
                        </option>
                        {roles &&
                          roles.map((client, index) => {
                            const { id, value } = client;
                            const isSelected = role === id;
                            // role,
                            // customerId,
                            // languageCode
                            return (
                              <option
                                key={`client_opt_${index}_${id}`}
                                value={id}
                                selected={isSelected}
                              >
                                {value}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Col>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Select
                        defaultValue={
                          languageCode
                            ? languageCode
                            : `${translate(
                                DICTIONARY.PLACEHOLDER.LANGUAGE,
                                dictionary
                              )} *`
                        }
                        className=""
                        name="languageCode"
                        onChange={handleUserInputChange}
                      >
                        <option>
                          {translate(
                            DICTIONARY.PLACEHOLDER.LANGUAGE,
                            dictionary
                          )}{" "}
                          *
                        </option>
                        {languageOptions &&
                          languageOptions.map((client, index) => {
                            const { id, value } = client;
                            return (
                              <option
                                key={`language_option_${index}_${id}`}
                                value={id}
                              >
                                {value}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Control
                        type="text"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.USER,
                          dictionary
                        )} *`}
                        className=""
                        name="username"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="switch"
                        onChange={onSwitchAction}
                        id="switch-allowEmailNotification"
                        label={translate(
                          DICTIONARY.LABEL.SEND_NOTIFICATION_EMAIL,
                          dictionary
                        )}
                        checked={isSwitchOn}
                        name="allowEmailNotification"
                        value={true}
                        className="input-switch"
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="switch"
                        onChange={onSwitchActionAllowPushNotification}
                        id="switch-allowPushNotification"
                        label={translate(
                          DICTIONARY.LABEL.ALLOW_PUSH_NOTIFICATION,
                          dictionary
                        )}
                        checked={isSwitchOnAllowPushNotification}
                        name="allowPushNotification"
                        value={true}
                        className="input-switch"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Control
                        type="text"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.NAME,
                          dictionary
                        )} *`}
                        className=""
                        name="firstName"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.SURNAME,
                          dictionary
                        )} *`}
                        className=""
                        name="lastName"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Control
                        type="email"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.EMAIL,
                          dictionary
                        )} *`}
                        name="email"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="password"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.PASSWORD,
                          dictionary
                        )} *`}
                        name="password"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="password"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.REPEAT_PASSWORD,
                          dictionary
                        )} *`}
                        name="password2"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <ProductAvailableList
                loadByClient={true}
                clientId={clientId}
                isUsedInClient={false}
                isUsedInUser={true}
              />
              <Row></Row>
              <Button
                variant="secondary"
                type="submit"
                className="w-25 float-end button-form"
              >
                {translate(DICTIONARY.BUTTON.USER_CREATE, dictionary)}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateUser;
