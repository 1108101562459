/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 28/09/2022                                        
* @Description Transform url to section, at the moment is only used for translate a page                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { SECTION } from "../constants/section";

export const urlToSection = () => {
    const url = window.location.href;
    let section = '';
    const allSections = SECTION;
    section = Object.keys(allSections).filter((item) => url.indexOf(allSections[item]) !== -1);

    if (section.length > 0) {
        return allSections[section[0]];
    } else {
        return null;
    }
}
