import React, { useContext, useEffect } from "react";
import { Container, Col, Form, Button, Row, Tabs, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useForm } from "../../hooks/useForm";
import Swal from "sweetalert2";
import COLORS from "../../constants/colors";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClients,
  switchClientMenu,
} from "../../actions/inputSelectActions";
import {
  createNotification,
  setNotificationSetActiveTab,
} from "../../actions/notification";
import { NotificationMessage } from "../../components/Notification/NotificationMessage";
import { UserContext } from "../../context/UserContext";
import { NotificationSendType } from "../../components/Notification/NotificationSendType";
import { ClientNotification } from "../../components/Notification/ClientNotification";
import { UserNotification } from "../../components/Notification/UserNotification";
import { translate } from "../../utils/translate";
import { DICTIONARY } from "../../constants/dictionary";

const initialStateUser = {
  title: "",
  text: "",
  titleUser: "",
  textUser: "",
};
const CreateNotification = () => {
  const paramsForLoaded = "&_s=841110&_l=1";

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);
  const [formUserValues, handleUserInputChange] = useForm(initialStateUser);
  const {
    state: { token },
  } = useAuth();
  const { clients } = useSelector((state) => state.inputSelect);
  const { activeTab, sendType } = useSelector((state) => state.notification);
  const { state: userState } = useContext(UserContext);
  const { selectedUsers } = userState;

  const handleCreate = async (ev) => {
    ev.preventDefault();
    handleCloseMenu();
    const _customers = clients.options.filter((item) => item.checked);
    let customers = [];
    if (_customers.length > 0) {
      customers = _customers.map((item) => item.id);
    }
    const { title, text } = formUserValues;
    const { isOptionSelectAllChecked } = clients;
    let _title = title;
    let _text = text;
    let allCustomers = isOptionSelectAllChecked;

    const users = selectedUsers ? selectedUsers : [];
    const payloadForClients = {
      title: _title,
      text: _text,
      type: sendType,
      customers,
      users: [],
      allCustomers,
    };
    const payloadForUsers = {
      title: _title,
      text: _text,
      type: sendType,
      customers: [],
      users,
      allCustomers: false,
    };
    let responseClients = {
      success: false,
    };
    let responseUsers = {
      success: false,
    };

    if (customers && customers.length > 0) {
      responseClients = await dispatch(
        createNotification({ payload: payloadForClients })
      );
    }
    if (users && users.length > 0) {
      responseUsers = await dispatch(
        createNotification({ payload: payloadForUsers })
      );
    }
    if (customers && customers.length === 0 && users && users.length === 0) {
      responseClients = await dispatch(
        createNotification({ payload: payloadForClients })
      );
    }
    if (responseClients.success || responseUsers.success) {
      Swal.fire({
        ...sweetAlertProps,
        text: translate(DICTIONARY.MESSAGE.NOTIFICATION_SAVE, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        if (sweet.isConfirmed) {
          window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/notification?_i=${token}${paramsForLoaded}`;
          // navigate(`/notification?_i=${token}${paramsForLoaded}`, { replace: false });
        }
      });
    } else {
      let messageError = "";

      if (customers && customers.length > 0) {
        messageError = responseClients?.message;
      }
      if (users && users.length > 0) {
        messageError = responseUsers?.message;
      }
      if (customers && customers.length === 0 && users && users.length === 0) {
        messageError = responseClients?.message;
      }

      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${messageError}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {});
    }
  };
  const handleSelectTab = (ev) => {
    sessionStorage.setItem("notificationActiveTab", ev);
    handleCloseMenu();
    dispatch(setNotificationSetActiveTab(ev));
  };
  const handleCloseMenu = () => {
    if (clients.isOpen) {
      dispatch(switchClientMenu(!clients.isOpen));
    }
  };
  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  useEffect(() => {
    const notificationActiveTab = sessionStorage.getItem(
      "notificationActiveTab"
    );
    if (notificationActiveTab) {
      const _notificationActiveTab = notificationActiveTab;
      dispatch(setNotificationSetActiveTab(_notificationActiveTab));
    }
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xs="12" sm="12" md="12" lg="12" xl="12" xxl="12">
            <div className="section-name">
              <span className="material-icons-round section-icon">
                notifications
              </span>
              <div>
                {translate(DICTIONARY.TITLE.NOTIFICATION_CREATE, dictionary)}
              </div>
            </div>
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col>
            <Form className="mt-3 " onSubmit={handleCreate}>
              <NotificationMessage
                handleUserInputChange={handleUserInputChange}
                titleName="title"
                textName="text"
              />
              <NotificationSendType />
              <Row>
                <Col className="section-content mt-3 ml-2">
                  <div className="subsection-name">
                    <div>
                      {translate(DICTIONARY.TITLE.RECEIVERS, dictionary)}
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="section-content mt-3 notification-tabs">
                  <Tabs
                    defaultActiveKey="client"
                    activeKey={activeTab}
                    id="controlled-tab-example"
                    onSelect={(ev) => handleSelectTab(ev)}
                    className="justify-content-start tabs"
                  >
                    <Tab
                      eventKey="client"
                      title={translate(DICTIONARY.LABEL.CLIENT, dictionary)}
                    ></Tab>
                    <Tab
                      eventKey="user"
                      title={translate(DICTIONARY.LABEL.USER, dictionary)}
                    ></Tab>
                  </Tabs>
                </Col>
                <Col>
                  <Button
                    variant="secondary"
                    type="submit"
                    className="w-25 float-end button-form"
                  >
                    {translate(
                      DICTIONARY.BUTTON.NOTIFICATION_CREATE,
                      dictionary
                    )}
                  </Button>
                </Col>
              </Row>
              {activeTab === "client" && <ClientNotification />}
              {activeTab === "user" && (
                <UserNotification isActive={true} hideState={true} />
              )}
              {/* <Form
                            className='mt-3 '
                            onSubmit={handleCreate}
                        > */}
              {/* <Row>
                                <Col
                                    xs="12"
                                    sm="6"
                                    md="6"
                                    lg="5"
                                    xl="5"
                                    xxl="4"
                                >
                                    <InputSelect
                                        label=''
                                        options={clients?.options}
                                        inputId="customer"
                                        filterType={INPUT_SELECTS.CLIENTS}
                                        inputName="customer"
                                        className="filter__xl"
                                        inputType="checkbox"
                                        visibleSelectedValue={clients?.selectedLabel}
                                        hasSelectAll={true}
                                    />
                                </Col>
                            </Row> */}
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateNotification;
