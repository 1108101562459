/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 15/09/2022                                        
* @Description Reducer for reporting actions                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/


import { FILTER_ATTRIBUTES } from "../constants/filter";
import { types } from "../types/types";

const initialState = {
    filteredList: [],
    list: [],
    nextPage: 1,
    isUsingFilter: false,
    filters: {
        startDate: '',
        endDate: '',
        startDateOrder: 'desc', //asc o desc
        endDateOrder: 'desc', //asc o desc
        customer: '',
        username: ''
    },
    datePicker: {
        showForEndDate: false,
        showForStartDate: false
    },
    allItemsLoaded: false
}

// api/v1/reporting?token=&startDate=&endDate=&startDateOrder=&endDateOrder=&customer=&username=
export const reportingReducer = (state = initialState, action) => {
    const stateFilters = state.filters;
    const listFromState = state.list;
    const datePickersFromState = state.datePicker;
    const filteredListFromState = state.filteredList;

    switch (action.type) {
        case types.reportingCloseAllDatePickers:
            const newDatePickers = [];
            Object.keys(datePickersFromState).forEach((item) => {
                newDatePickers[item] = false;
            })
            return {
                ...state,
                datePicker: {
                    ...newDatePickers
                }
            }
        case types.reportingSetAllLoaded:

            sessionStorage.setItem('allItemsLoaded', action.payload);
            return {
                ...state,
                allItemsLoaded: action.payload
            }
        case types.reportingSetFilteredList:
            return {
                ...state,
                filteredList: action.payload
            }
        case types.reportingSetFilteredListWhenScroll:
            const newFilteredList = [
                ...filteredListFromState,
                ...action.payload
            ];
            return {
                ...state,
                filteredList: newFilteredList
            }
        case types.reportingSetFilters:
            const { attribute, search } = action.payload;

            let newFilters = {
                ...stateFilters
            }
            const _FILTER_ATTRIBUTES = FILTER_ATTRIBUTES.REPORTING;
            switch (attribute) {
                case _FILTER_ATTRIBUTES.STARTDATE:
                    newFilters.startDate = search;
                    break;
                case _FILTER_ATTRIBUTES.ENDDATE:
                    newFilters.endDate = search;
                    break;
                case _FILTER_ATTRIBUTES.STARTDATEORDER:
                    newFilters.startDateOrder = search;
                    break;
                case _FILTER_ATTRIBUTES.ENDDATEORDER:
                    newFilters.endDateOrder = search;
                    break;
                case _FILTER_ATTRIBUTES.CUSTOMER:
                    newFilters.customer = search;
                    break;
                case _FILTER_ATTRIBUTES.USERNAME:
                    newFilters.username = search;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                filters: {
                    ...newFilters
                },
                isUsingFilter: true
            }
        case types.reportingSetList:
            const newList = [
                ...listFromState,
                ...action.payload
            ]
            return {
                ...state,
                list: newList
            }
        case types.reportingSetNextPage:
            sessionStorage.setItem('nextPageReporting', action.payload);
            return {
                ...state,
                nextPage: action.payload
            }
        case types.reportingShowDatePickerForEndDate:
            return {
                ...state,
                datePicker: {
                    showForEndDate: action.payload,
                    showForStartDate: false
                }
            }
        case types.reportingShowDatePickerForStartDate:
            return {
                ...state,
                datePicker: {
                    showForEndDate: false,
                    showForStartDate: action.payload,
                }
            }
        default:
            return state;
    }
}