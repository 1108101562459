/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 02/09/2022                                        
* @Description Populate endpoint with params only for post                                                          
* ----------------------------------------------------------------------------------------
* @Inputs                                                                    
*  - @param args Object with two properties: endpoint and endpointParams, 
*               - endpoint its the backend endpoint                                                                            
*               - endpointParams is an array of object, each child has two properties
*                 name and value, where name is the param to replace with his value
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/
export const populateEndpointWithParams = (args) => {
    const { endpoint, endpointParams } = args;
    let endpointWithParams = endpoint;
    endpointParams.forEach((param) => {
        const { name, value } = param;

        endpointWithParams = endpointWithParams.replace(name, value);
    })
    return endpointWithParams;
}