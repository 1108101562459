
/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 26/07/2022                                        
* @Description Reducer for user actions                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { types } from "../types/types";

const initialState = {
    initialData: {
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        role: '',
        customerId: '',
        products: [],
        deletedProducts: [],
        languageCode: ''
    },
    languageOptions: [
        {
            id: "pt",
            value: "Portugués"
        },
        {
            id: "en",
            value: "Inglés",
        },
        {
            id: "es",
            value: "Español",
        }
    ],
    loadInitialData: false
}


export const userReducer = (state = initialState, action) => {

    switch (action.type) {
        case types.userResetCopyProfileData:
            return {
                ...state,
                initialData: {},
                loadInitialData: false

            }
        case types.userSetCopyProfileData:
            return {
                ...state,
                initialData: action.payload,
                loadInitialData: true
            }
        default:
            return state;
    }
}
