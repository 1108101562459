/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 24/08/2022                                        
* @Description Client data contact form                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { useState, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux';
import { DICTIONARY } from '../../constants/dictionary';
import ROLES from '../../constants/roles';
import { translate } from '../../utils/translate';

export const ClientContact = (props) => {
    const {
        // isOwnProfile = false,
        handleClientInputChange,
        setShowColorPicker = null,
        // htmlTitle
    } = props
    const { current: { dictionary } } = useSelector((state) => state.translation);

    const userRole = sessionStorage.getItem('userRole');
    const isEditable = userRole === ROLES.ADMIN;
    const isHidden = userRole === ROLES.USER;

    // const dispatch = useDispatch();
    const { data: { contact } } = useSelector((state) => state.contact);

    const [nameContact, setNameContact] = useState('');
    const [emailContact, setEmailContact] = useState('');
    const [phoneContact, setPhoneContact] = useState('');

    useEffect(() => {
        if (contact != null) {
            const { name, email, phone } = contact;
            setNameContact(name);
            setEmailContact(email);
            setPhoneContact(phone);
        }
    }, [contact]);



    return (
        <>
            {
                !isHidden ?
                    <>
                        <Row className="mt-3 user-row">
                            <Col
                                xs="12"
                                sm="6"
                                md="6"
                                lg="5"
                                xl="5"
                                xxl="6"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder={translate(DICTIONARY.PLACEHOLDER.CONTACT_PERSON, dictionary)}
                                    name="contactName"
                                    onChange={(ev) => handleClientInputChange(ev)}
                                    onClick={() => setShowColorPicker(false)}
                                    defaultValue={nameContact}
                                    disabled={!isEditable}
                                />
                            </Col>

                        </Row>
                        <Row className="user-row">
                            <Col
                                xs="12"
                                sm="6"
                                md="6"
                                lg="5"
                                xl="5"
                                xxl="6"
                            >
                                <Form.Control
                                    type="email"
                                    placeholder={translate(DICTIONARY.PLACEHOLDER.EMAIL, dictionary)}
                                    name="email"
                                    onChange={(ev) => handleClientInputChange(ev)}
                                    onClick={() => setShowColorPicker(false)}
                                    defaultValue={emailContact}
                                    disabled={!isEditable}
                                />
                            </Col>
                            <Col
                                xs="12"
                                sm="6"
                                md="6"
                                lg="5"
                                xl="5"
                                xxl="6"
                            >
                                <Form.Control
                                    type="tel"
                                    placeholder={translate(DICTIONARY.PLACEHOLDER.PHONE, dictionary)}
                                    name="phone"
                                    onChange={(ev) => handleClientInputChange(ev)}
                                    onClick={() => setShowColorPicker(false)}
                                    defaultValue={phoneContact}
                                    disabled={!isEditable}
                                />
                            </Col>
                        </Row>
                    </>
                    :
                    <></>
            }

        </>
    )
}
