/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/07/2022                                        
* @Description Constants for modals                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

export const MODAL_TYPES = {
    ACTIVATE_USERS: 'MODAL FOR ACTIVATE/DEACTIVE USERS',
    ACTIVATE_CUSTOMERS: 'MODAL FOR ACTIVATE/DEACTIVE CUSTOMERS',

    PERMISSIONS_USERS: 'MODAL FOR USERS PERMISSIONS ',
    PERMISSIONS_CUSTOMERS: 'MODAL FOR CUSTOMERS PERMISSIONS '
}