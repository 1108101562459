export const types = {
    authGetToken: '[AUTH] Get token using ideauto api',
    authSetLogin: '[AUTH] Set logging status for user',
    authSetLogout: '[AUTH] Set logout status for user',
    clientAddAllProducts: '[CLIENT] Add all selected products to client',
    clientAddOneProduct: '[CLIENT] Add one product to client',
    clientFilter: '[CLIENT] Filter client',
    clientGetAll: '[CLIENT] Get all clients',
    clientGetOne: '[CLIENT] Get data from one client',
    clientOfUser: '[CLIENT] Get client data of user',
    clientRemoveOneProduct: '[CLIENT] Remove one product to client',
    clientResetData: '[CLIENT] Reset client data',
    clientResetFilter: '[CLIENT] Reset filters for client',
    clientSetSelect: '[CLIENT] Toggle selected client',
    clientToggleProduct: '[CLIENT] Toggle on product in selectedProducts array',
    colorGetAll: '[COLOR] Get all corporative colors',
    contactCreate: '[CONTACT] Create contact of customer',
    contactDelete: '[CONTACT] Delete contact of customer',
    contactGetDefault: '[CONTACT] Get default contact of customer',
    contactGetOne: '[CONTACT] Get contact of customer',
    contactOverwrite: '[CONTACT] Overwrite contact of customer',
    contactResetData: '[CONTACT] Reset contact data',
    datePickerSetEndDate: '[DATE PICKER] Set EndDate',
    datePickerSetStartDate: '[DATE PICKER] Set StartDate',
    datePickerToggle: '[DATE PICKER] Toggle show/hide',
    inputSelectCloseAll: '[INPUT_SELECT] Close all',
    inputSelectSelectAllClients: '[INPUT SELECT] Select all clients',
    inputSelectSelectAllOptions: '[INPUT SELECT] Select all options',
    inputSelectSetClientChecked: '[INPUT SELECT] Set client checked',
    inputSelectSetClients: '[INPUT_SELECT] Set clients',
    inputSelectSetDefaultClients: '[INPUT SELECT] Set default selected options',
    inputSelectSetDefaultOptions: '[INPUT SELECT] Set default selected options',
    inputSelectSetOptionChecked: '[INPUT SELECT] Set option checked',
    inputSelectSetOptions: '[INPUT_SELECT] Set options',
    inputSelectSwitchClientsMenu: '[INPUT SELECT] Open clients',
    inputSelectSwitchOptionsMenu: '[INPUT SELECT] Open options',
    inputSelectUnselectAllClients: '[INPUT SELECT] Unselect all clients',
    inputSelectUnselectAllOptions: '[INPUT SELECT] Unselect all options',
    modalSetDisabledButtons: '[MODAL] Set buttons disabled',
    modalSetMessage: '[MODAL] Set message',
    modalSetShow: '[MODAL] Set show on/off',
    modalSetShowMesage: '[MODAL] Set show message on/off',
    notificationGetFilterList: '[NOTIFICATION] Get filtered list of notifications',
    notificationGetList: '[NOTIFICATION] Get list of notifications',
    notificationGetOne: '[NOTIFICATION] Get data of one notification',
    notificationGetPendingCount: '[NOTIFICATION] Get count of pending notifications',
    notificationResetVariables: '[NOTIFICATION] Reset all variables',
    notificationSend: '[NOTIFICATION] Send notification',
    notificationSetActiveTab: '[NOTIFICATION] Set tab in create form',
    notificationSetAllLoaded: '[NOTIFICATION] Set notifications as all loaded',
    notificationSetClientsList: '[NOTIFICATION] Set list of clients that received this notification',
    notificationSetFilters: '[NOTIFICATION] Set filters values',
    notificationSetIsUsingFilter: '[NOTIFICATION] Set is using filter',
    notificationSetNextPage: '[NOTIFICATION] Set next page',
    notificationSetSendType: '[NOTIFICATION] Set send type',
    notificationSetStopLoadReceived: '[NOTIFICATION] Stop loading received notifications',
    notificationSetStopLoadSent: '[NOTIFICATION] Stop loading sent notifications',
    notificationSetUsersList: '[NOTIFICATION] Set list of users that received this notification',
    notificationShowSendMessage: '[NOTIFICATION] Show send messasge',
    notificationToggleDatePickerInList: '[NOTIFICATION] Toggle date picker in notification list',
    notificationUpdateState: '[NOTIFICATION] Update state of notification as user',
    paginationSetNextPageProducts: '[PAGINATION] Set next page for products',
    paginationSetNextPageUsers: '[PAGINATION] Set next page',
    paginationStopLoadingProducts: '[PAGINATION] Stop loading products when scroll',
    paginationStopLoadingUsers: '[PAGINATION] Stop loading users when scroll',
    parameterSetLanguage: '[PARAMETER] Set language code',
    permissionGetCustomTemplate: '[PERMISSION] Get custome template',
    permissionGetTemplate: '[PERMISSION] Get base template',
    permissionSetCustomTemplate: '[PERMISSION] Set custome template',
    permissionSetDeleted: '[PERMISSION] Set is deleted',
    permissionSetError: '[PERMISSION] Set error in json',
    permissionSetIsSubmited: '[PERMISSION] Set if is submited',
    permissionSetJsonCustom: '[PERMISION] Update property json of permission inside custom object',
    permissionSetMessage: '[PERMISSION] Set message from response',
    permissionSetTemplate: '[PERMISSION] Set base template',
    permissionSetUpdateBaseTemplate: '[PERMISSION] Set update base template',
    permissionSetUpdateCustomTemplate: '[PERMISSION] Set update custom template',
    productDelete: '[PRODUCTS] Delete product from list',
    productFilter: '[PRODUCTS] Filter products list',
    productGetAll: '[PRODUCTS] Get all products',
    productResetData: '[PRODUCTS] Reset data in edit',
    productSelectAll: '[PRODUCTS] Select all',
    productSelectOne: '[PRODUCTS] Select one',
    productSetAllLoaded: '[PRODUCTS] Set all products loaded',
    productSetData: '[PRODUCTS] Set data in edit',
    productSetFilteredData: '[PRODUCTS] Set filtered list',
    productSetList: '[PRODUCTS] Set product list',
    productSetLoadByClient: '[PRODUCT] Set load by client',
    productSetNewList: '[PRODUCTS] Set product new list',
    productSetNextPage: '[PRODUCTS] Set next page to load',
    productSetSelected: '[PRODUCTS] Set selected product id',
    productUnselectAll: '[PRODUCTS] Unselect all',
    productUnselectOne: '[PRODUCTS] Unselect one',
    reportingCloseAllDatePickers: '[REPORTING] Close all date pickers',
    reportingSetAllLoaded: '[REPORTING] Set all loaded',
    reportingSetFilteredList: '[REPORTING] Set filtered list',
    reportingSetFilteredListWhenScroll: '[REPORTING] Set filtered list when scroll',
    reportingSetFilters: '[REPORTING] Set filters',
    reportingSetList: '[REPORTING] Set list',
    reportingSetNextPage: '[REPORTING] Set next page to load',
    reportingShowDatePickerForEndDate: '[REPORTING] Show date picker for end date',
    reportingShowDatePickerForStartDate: '[REPORTING] Show date picker for start date',
    roleGetAll: '[ROLE] Get all roles',
    setErrorInCurrentSection: '[ERROR] Set error in current section for current action',
    translationGetCurrentDictionary: '[TRANSLATION] Get dictionary of current page',
    userDelete: '[USER] Delete selected users',
    userEdit: '[USER] Edit user',
    userFilter: '[USER] Filter users by attribute',
    userGetAll: '[USER] Get all users',
    userGetOne: '[USER] Get data from one user',
    userRefreshAfterEdit: '[USER] Refresh users after edit one',
    userResetCopyProfileData: '[USER] Reset data to be copied',
    userResetData: '[USER] Reset data when edit user',
    userResetProducts: '[USER] Reste selected products for user',
    userSelectAll: '[USER] Select all users',
    userSelectOne: '[USER] Select one user',
    userSetCopyProfileData: '[USER] Set data to be copied',
    userSetFilteredData: '[USER] Set users using filter',
    userSetFilteredUsers: '[USER] Set filtered users',
    userSetSelect: '[USER] Set selected user for delete',
    usersReloadData: '[USER] Reload data',
    userToggleProduct: '[USER] Toggle on product in user products array',
    userUnselectAll: '[USER] Unselect all users',
    userUnselectOne: '[USER] Unselect one user',

}