/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 26/08/2022                                        
* @Description Reducer for contact actions                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/


import { FILTER_ATTRIBUTES } from "../constants/filter";
import { types } from "../types/types";

const initialState = {
    activeTab: 'client',
    filterList: [],
    filters: {
        type: '',
        startDate: '',
        endDate: '',
        _state: ''
    },
    isUsingFilter: false,
    list: [],
    data: {},
    pendingCount: 0,
    sendType: '',
    showSendMessage: false,
    usersReceiverList: [],
    clientsReceiverList: {},
    stopLoadingSent: false,
    stopLoadingReceived: false,
    showDatePicker: false
}
export const notificationReducer = (state = initialState, action) => {
    const stateFilters = state.filters;
    const listFromState = state.list;
    // const usersReceiverListFromState = state.usersReceiverList;
    switch (action.type) {
        case types.notificationGetList:
            const newList = [
                ...listFromState,
                ...action.payload
            ]
            return {
                ...state,
                list: newList
            }
        case types.notificationGetFilterList:
            return {
                ...state,
                filterList: action.payload,
                isUsingFilter: true
            }
        case types.notificationGetOne:
            return {
                ...state,
                data: action.payload
            }
        case types.notificationGetPendingCount:
            return {
                ...state,
                pendingCount: action.payload
            }
        case types.notificationResetVariables:
            return initialState;
        case types.notificationSend:
            return {
                ...state,
                showSendMessage: true
            }
        case types.notificationSetAllLoaded:
            sessionStorage.setItem('allNotificationsLoaded', action.payload);
            return {
                ...state
            }

        case types.notificationSetActiveTab:
            return {
                ...state,
                activeTab: action.payload
            }
        case types.notificationSetClientsList:
            return {
                ...state,
                clientsReceiverList: action.payload
            }
        case types.notificationSetFilters:
            const { attribute, search } = action.payload;

            let newFilters = {
                ...stateFilters
            }
            switch (attribute) {
                case FILTER_ATTRIBUTES.NOTIFICATION_TYPE:
                    newFilters.type = search;
                    break;
                case FILTER_ATTRIBUTES.NOTIFICATION_STATE:
                    newFilters._state = search;
                    break;
                case FILTER_ATTRIBUTES.NOTIFICATION_DATES:
                    const { startDate, endDate } = search;
                    newFilters.startDate = startDate;
                    newFilters.endDate = endDate;
                    break;
                default:
                    break;
            }
            return {
                ...state,
                filters: {
                    ...newFilters
                },
                isUsingFilter: true
            }
        case types.notificationSetUsersList:
            // const usersReceiverListNew = [
            //     ...usersReceiverListFromState,
            //     ...action.payload
            // ];
            return {
                ...state,
                usersReceiverList: action.payload
            }
        case types.notificationSetIsUsingFilter:
            return {
                ...state,
                isUsingFilter: action.payload
            }

        case types.notificationSetNextPage:
            sessionStorage.setItem('nextPageNotifications', action.payload);
            return {
                ...state,
            }
        case types.notificationSetSendType:
            return {
                ...state,
                sendType: action.payload
            }
        case types.notificationShowSendMessage:
            return {
                ...state,
                showSendMessage: action.payload
            }
        // case types.notificationUpdateState:
        //     return {
        //         ...state,
        //     }
        case types.notificationSetStopLoadSent:
            return {
                ...state,
                stopLoadingSent: action.payload
            }
        case types.notificationSetStopLoadReceived:
            return {
                ...state,
                stopLoadingReceived: action.payload
            }
        case types.notificationToggleDatePickerInList:
            return {
                ...state,
                showDatePicker: action.payload
            }
        default:
            return state;
    }
}