/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 21/01/2022
 * @Description AuthContext for get token
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import React, { createContext, useContext, useEffect, useReducer } from "react";
import { fetchSimple } from "../helpers/fetch";
import { types } from "../types/types";

const AuthContext = createContext([]);

const initialState = {
  isLogged: false,
  token: "",
  role: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.authGetToken:
      return {
        ...state,
        token: action.payload,
      };
    case types.authSetLogin:
      return {
        ...state,
        ...action.payload,
      };
    case types.authSetLogout:
      return {
        isLogged: false,
        token: "",
        role: "",
      };
    default:
      return {
        token: "",
        isLogged: false,
      };
  }
};

const setLoginCredentials = (isLogged, token, role) => ({
  type: types.authSetLogin,
  payload: {
    isLogged,
    token,
    role,
  },
});
const setLogout = () => ({
  type: types.authSetLogout,
});

const login = async (dispatch, username, password) => {
  const resp = await fetchSimple(
    "auth/login",
    {
      username,
      password,
    },
    "POST"
  );

  const body = await resp.json();

  if (body.success) {
    // return body.data;
    const { data } = body;
    const { token, role } = data;
    return token;
    // dispatch(setLoginCredentials(true, token, role));
    // const products = await getAllProducts(token);
    // const _apps = await getProductsFromUser(token, products);
    // const userObj = {
    // 	token,
    // 	_apps
    // 	// role
    // }
    // sessionStorage.setItem('ideauto_u', JSON.stringify(userObj));
  } else {
    dispatch(setLoginCredentials(false, "", ""));
  }
};

const renewLogin = (dispatch) => {
  const it_token = sessionStorage.getItem("ideauto_u");
  if (it_token) {
    const data = JSON.parse(it_token);
    dispatch(setLoginCredentials(true, data.token, data.role));
  }
};

const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, []);

  const logout = () => {
    sessionStorage.clear();
    dispatch(setLogout());
  };
  useEffect(() => {
    const it_token = sessionStorage.getItem("ideauto_u");
    if (it_token) {
      const data = JSON.parse(it_token);
      dispatch(setLoginCredentials(true, data.token, data.role));
    }
  }, [state.token]);

  const value = { state, dispatch, logout };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used with AuthProvider");
  }
  return context;
};

export { AuthProvider, AuthContext, useAuth, login, renewLogin };
