/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 01/03/2022                                        
* @Description Products availables list                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { DICTIONARY } from '../../../constants/dictionary';
import ROLES from '../../../constants/roles';
import { useAuth } from '../../../context/AuthContext';
import { ClientContext } from '../../../context/ClientContext';
import { ProductContext } from '../../../context/ProductContext';
import { translate } from '../../../utils/translate';
import { ProductAvailable } from '../ProductAvailable/ProductAvailable';
import './productAvailableList.css';

export const ProductAvailableList = (props) => {
    const {
        loadByClient = false,
        isReadOnly = false,
        clientId = '',
        isOwnProfile = false,
        isUsedInClient = false,
        isUsedInUser = false,
        // isAdmin = false,
        showProductsAsClient = false
    } = props

    const { state: stateProduct, getAllProducts, dispatch: dispatchProduct, setLoadByClient } = useContext(ProductContext);
    const { state: stateClient, dispatch: dispatchClient, getClientData } = useContext(ClientContext);

    const { current: { dictionary } } = useSelector((state) => state.translation);
    const { clientData, selectedProducts } = stateClient;
    const { products, loadByClient: loadByClientFromProductState } = stateProduct;
    const { state: stateAuth } = useAuth();
    const { token } = stateAuth;
    const isEditable = !isReadOnly;
    const selectedProductsInClient = selectedProducts ? selectedProducts : [];

    // const [productsInClientConfiguration, setProductsInClientConfiguration] = useState([]);
    let productList = products;

    if (loadByClient || loadByClientFromProductState) {
        productList = products?.map((product) => {
            const { id } = product;

            const isProductForClient = clientData?.products?.filter((_item) => _item === id);
            if (clientData?.products && isProductForClient.length > 0) {
                return {
                    id: product.id,
                    name: product.name
                }
            }
        });

        productList = productList?.filter((_item) => _item !== undefined);
    }

    const handleClickShowAsClient = (evt) => {
        dispatchProduct(setLoadByClient(!loadByClientFromProductState));
    }
    useEffect(() => {
        getAllProducts(dispatchProduct);
    }, []);

    const userRole = sessionStorage.getItem('userRole');
    const isAdmin = userRole === ROLES.ADMIN;
    useEffect(() => {
        if (loadByClient && isAdmin) {
            const getClientDataAsync = async () => {
                await getClientData(dispatchClient, clientId, token);
            }
            getClientDataAsync();
        }
    }, [clientId]);

    const titleClassName = isAdmin && isOwnProfile ?
        "subsection-name productAvailableList-edit"
        :
        "subsection-name";


    const [isEditableProduct, setIsEditableProduct] = useState(false);
    useEffect(() => {
        const userRole = sessionStorage.getItem('userRole');
        const isCustomAdminOrUser = userRole === ROLES.CUSTOMER || userRole === ROLES.ADMIN;

        const _isEditable = isCustomAdminOrUser && isEditable && !isOwnProfile;
        setIsEditableProduct(_isEditable);
    }, []);


    const handleClickEdit = (isEditable) => {
        setIsEditableProduct(!isEditable);
    }

    return (
        <>
            <Row>
                <Col
                    className='section-content mt-2 ml-2'
                >
                    <div className={titleClassName}>
                        <div className="title">
                            <div>{translate(DICTIONARY.TITLE.PRODUCT_AVAILABLE, dictionary)}</div>
                            {/* <div className="title-bold">Productos</div>
                            <div>&nbsp;disponibles</div> */}
                        </div>
                        {
                            isAdmin && isOwnProfile &&
                            <div className="productAvailableList-edit-pencil"
                                onClick={() => handleClickEdit(isEditableProduct)}
                            >
                                {
                                    !isEditableProduct &&
                                    <span className="material-icons-round">edit</span>
                                }
                                {
                                    isEditableProduct &&
                                    <span className="material-icons-round">edit_off</span>
                                }
                            </div>
                        }
                    </div>

                    {
                        showProductsAsClient &&
                        <Button
                            variant="secondary"
                            className="w-25 float-end button-form button-form__inline"
                            onClick={handleClickShowAsClient}
                        >
                            {
                                loadByClientFromProductState ?
                                    translate(DICTIONARY.BUTTON.SHOW_AS_ADMIN, dictionary)
                                    :
                                    translate(DICTIONARY.BUTTON.SHOW_AS_CLIENT, dictionary)
                            }
                        </Button>
                    }
                </Col>
            </Row>
            <Row
                className="mb-3 product-list"
            >
                {
                    productList && productList?.map((product, _i) => {
                        const {
                            id,
                            name,
                            icon,
                            color,
                            available
                        } = product;
                        const _name = name ? name : '';
                        const _icon = icon ? icon : '';
                        const _color = color ? color : '';
                        const _available = available ? available : '';
                        let isSelectedInClient = selectedProductsInClient?.filter((_sprod) => _sprod.id === id);
                        isSelectedInClient = isSelectedInClient ? isSelectedInClient.length > 0 : false;

                        return (
                            <Col
                                xs="4"
                                sm="3"
                                md="2"
                                lg="2"
                                xl="1"
                                xxl="1"
                                key={_i}
                                className="product-list-column"
                            >
                                <ProductAvailable
                                    id={id}
                                    name={_name}
                                    icon={_icon}
                                    color={_color}
                                    available={_available}
                                    isEditable={isEditableProduct}
                                    isSelectedInClient={isSelectedInClient}
                                    isUsedInClient={isUsedInClient}
                                    isUsedInUser={isUsedInUser}
                                />
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    )
}
