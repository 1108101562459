import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Container, Col, Image, Form, Button, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import Swal from "sweetalert2";
import COLORS from "../../constants/colors";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import { InputSelect } from "../../components/Form/InputSelect";
import { INPUT_SELECTS } from "../../constants/inputSelects";
import {
  getOneProduct,
  setIsAvailableMobile,
  setMaintenance,
  updateProduct,
  uploadProductImage,
} from "../../actions/product";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClients,
  switchClientMenu,
  toggleCheckOption,
} from "../../actions/inputSelectActions";
import { useAuth } from "../../context/AuthContext";
import { DICTIONARY } from "../../constants/dictionary";
import { translate } from "../../utils/translate";

const EditProduct = (props) => {
  const dispatch = useDispatch();
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);
  const { data } = useSelector((state) => state.products);
  const { clients } = useSelector((state) => state.inputSelect);
  const { isClientsLoaded } = clients;
  const { name, url, customers, logo, isInMaintenance, isAvailableMobile } =
    data;
  const { productId } = useParams();
  const {
    state: { token },
  } = useAuth();

  const [isSwitchOn, setIsSwitchOn] = useState(
    isInMaintenance !== undefined ? isInMaintenance : false
  );
  const [photo, setPhoto] = useState({});
  const [srcImage, setSrcImage] = useState("");
  const [formUserValues, handleUserInputChange] = useForm(data);
  const [isSwitchOnHasMobileVersion, setIsSwitchOnHasMobileVersion] =
    useState(isAvailableMobile);
  const inputPhoto = useRef(null);

  const onSwitchActionHasMobileVersion = () => {
    setIsSwitchOnHasMobileVersion(!isSwitchOnHasMobileVersion);
  };
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  const handleInputPhoto = (ev) => {
    inputPhoto.current.click();
  };

  const handleChangeFile = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSrcImage(imageUrl);
    setPhoto(file);
  };

  const handleEditProduct = async (ev) => {
    ev.preventDefault();
    handleCloseMenu();
    const { name, url } = formUserValues;

    const checkedCustomers = clients.options.filter((item) => item.checked);
    let customersId = [];
    if (checkedCustomers.length > 0) {
      customersId = checkedCustomers.map((item) => item.id);
    }

    let deletedCustomers = [];
    customers.forEach((item) => {
      if (customersId.indexOf(item) === -1) {
        deletedCustomers.push(item);
      }
    });

    const payload = {
      name: name ? name : data.name,
      url: url ? url : data.url,
      customers: customersId,
      deletedCustomers,
    };
    const payloadMaintenance = {
      isInMaintenance: isSwitchOn,
    };
    const payloadHasMobileVersion = {
      isAvailableMobile: isSwitchOnHasMobileVersion,
    };
    await setMaintenance({ id: productId, payload: payloadMaintenance });
    await setIsAvailableMobile({
      id: productId,
      payload: payloadHasMobileVersion,
    });
    const response = await updateProduct({
      id: productId,
      payload,
    });
    if (response && response.success) {
      await uploadProductImage(productId, token, photo);
      Swal.fire({
        ...sweetAlertProps,
        text: translate(DICTIONARY.MESSAGE.PRODUCT_SAVE, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        if (sweet.isConfirmed) {
          // navigate('/user', { replace: true });
        }
      });
    } else {
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${response?.message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        // if (sweet.isConfirmed) {
        //     navigate('/user', { replace: true });
        // }
      });
    }
  };

  const handleCloseMenu = () => {
    if (clients.isOpen) {
      dispatch(switchClientMenu(!clients.isOpen));
    }
  };

  useEffect(() => {
    const getProductData = async () => {
      await dispatch(getAllClients());
      await dispatch(getOneProduct(productId));
    };
    getProductData();
  }, []);

  useEffect(() => {
    if (isInMaintenance !== undefined) {
      setIsSwitchOn(isInMaintenance);
    }
  }, [isInMaintenance]);
  useEffect(() => {
    if (isAvailableMobile !== undefined) {
      setIsSwitchOnHasMobileVersion(isAvailableMobile);
    }
  }, [isAvailableMobile]);

  useEffect(() => {
    const checkClients = async () => {
      if (isClientsLoaded) {
        if (customers && customers.length > 0) {
          customers.forEach((item) => {
            dispatch(
              toggleCheckOption({
                inputSelectType: INPUT_SELECTS.CLIENTS,
                id: item,
              })
            );
          });
        }
        if (logo && logo !== "") {
          const tokenFromSession = JSON.parse(
            sessionStorage.getItem("ideauto_u")
          );
          const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
          const logoUrl = logo
            ? `${apiURL}/products/uploads/photo/${productId}?token=${tokenFromSession.token}`
            : ``;

          setSrcImage(logoUrl);
        }
      }
    };
    checkClients();
  }, [customers]);

  const _icon = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/img/default/apps_black_24dp.svg`;
  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="section-name">
              <div className="section-icon__img ">
                <img
                  className="product-icon product-icon__tiny"
                  src={_icon}
                  alt={`Productos`}
                />
              </div>
              <div>{translate(DICTIONARY.TITLE.PRODUCT_EDIT, dictionary)}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="section-content mt-3 ml-2">
            <div className="subsection-name">
              {translate(DICTIONARY.TITLE.PRODUCT_DATA, dictionary)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form className="mt-3" onSubmit={handleEditProduct}>
              <Row className="user-row">
                <Col xs="12" sm="5" md="4" lg="3" xl="3" xxl="3" className="">
                  <div className="avatar">
                    <div className="avatar-image">
                      {srcImage && (
                        <Image src={srcImage} className="client-image" />
                      )}
                    </div>
                    <div className="avatar-button">
                      <Form.Control
                        ref={inputPhoto}
                        style={{ display: "none" }}
                        type="file"
                        onChange={handleChangeFile}
                      />
                      <Button
                        variant="secondary"
                        className="avatar-change-button"
                        onClick={handleInputPhoto}
                      >
                        {translate(
                          DICTIONARY.BUTTON.PRODUCT_ADD_ICON,
                          dictionary
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="7" md="8" lg="9" xl="9" xxl="9">
                  <Row className="row-separation">
                    <Col>
                      <Form.Check
                        type="switch"
                        onChange={onSwitchAction}
                        id="switch-allowEmailNotification"
                        label={translate(
                          DICTIONARY.LABEL.PRODUCT_MAINTENANCE,
                          dictionary
                        )}
                        checked={isSwitchOn}
                        name="isInMaintenance"
                        value={true}
                        className="input-switch"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Check
                        type="switch"
                        onChange={onSwitchActionHasMobileVersion}
                        id="switch-allowAccessMobileApp"
                        label={translate(
                          DICTIONARY.LABEL.PRODUCT_HAS_MOBILE_VERSION,
                          dictionary
                        )}
                        checked={isSwitchOnHasMobileVersion}
                        name="allowMobileAccess"
                        value={true}
                        className="input-switch"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <InputSelect
                        label=""
                        options={clients?.options}
                        inputId="customer"
                        filterType={INPUT_SELECTS.CLIENTS}
                        inputName="customer"
                        className="filter__xl"
                        inputType="checkbox"
                        visibleSelectedValue={clients?.selectedLabel}
                        hasSelectAll={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Control
                        type="text"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.PRODUCT_NAME,
                          dictionary
                        )} *`}
                        className=""
                        name="name"
                        defaultValue={name}
                        onInput={handleUserInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.PRODUCT_URL,
                          dictionary
                        )} *`}
                        className=""
                        name="url"
                        defaultValue={url}
                        onInput={handleUserInputChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row></Row>
              <Button
                variant="secondary"
                type="submit"
                className="w-25 float-end button-form"
              >
                {translate(DICTIONARY.BUTTON.SAVE, dictionary)}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProduct;
