/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 22/07/2022                                        
* @Description Actions for permission reducer                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { MODAL_TYPES } from "../constants/modals";
import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";

const BASE_ENDPOINT = `parameters/permissions`;
const ENDPOINTS = {
    GET: {
        CUSTOMER: `${BASE_ENDPOINT}/customers`, // Recuperar los permisos de parámetros de acceso de un cliente
        TEMPLATE: `${BASE_ENDPOINT}/template`, //Recuperar la plantilla de permisos de parámetros de acceso
        USER: `${BASE_ENDPOINT}/users`, //Recuperar los permisos de parámetros de acceso de un usuario
    },
    POST: {
        TEMPLATE: `${BASE_ENDPOINT}/template`, //Insertar plantilla de permisos de parámetros de acceso
        CUSTOMER: `${BASE_ENDPOINT}/customers`, //Guardar permisos de parámetros de acceso de un cliente (si tiene permisos, los sobreescribe)
        USER: `${BASE_ENDPOINT}/users`, //Guardar permisos de parámetros de acceso de un usuario (si tiene permisos, los sobreescribe)
    },
    DELETE: {
        CUSTOMER: `${BASE_ENDPOINT}/customers`, //Eliminar los permisos de parámetros de acceso de un cliente
        USER: `${BASE_ENDPOINT}/users`, //Eliminar los permisos de parámetros de acceso de un usuario
    }
}

const setBaseTemplate = (payload) => ({
    type: types.permissionSetTemplate,
    payload
});
const setCustomTemplate = (payload) => ({
    type: types.permissionSetCustomTemplate,
    payload
});
export const setUpdateCustomTemplate = (payload) => ({
    type: types.permissionSetUpdateCustomTemplate,
    payload
});
export const setErrorInJSON = (payload) => ({
    type: types.permissionSetError,
    payload
});
export const setIsSubmited = (payload) => ({
    type: types.permissionSetIsSubmited,
    payload
});
export const setUpdateBaseTemplate = (payload) => ({
    type: types.permissionSetUpdateBaseTemplate,
    payload
});
const setCustomJSON = (payload) => ({
    type: types.permissionSetJsonCustom,
    payload
});
export const setPermissionMessage = (payload) => ({
    type: types.permissionSetMessage,
    payload
})

export const setIsDeleted = (payload) => ({
    type: types.permissionSetDeleted,
    payload
})

export const getBaseTemplate = () => {
    return async (dispatch) => {
        try {
            const resp = await fetchWithToken(
                ENDPOINTS.GET.TEMPLATE
            );

            const body = await resp.json();
            if (body.success) {
                const { data } = body;
                // const {
                //     parentIsTemplate,
                //     parentIsCustomer,
                //     permission
                // } = data;
                dispatch(setBaseTemplate(data));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}
export const getCustomTemplate = (params) => {
    return async (dispatch) => {
        try {
            const { id, modal } = params;
            let endpoint = '';
            switch (modal) {
                case MODAL_TYPES.PERMISSIONS_CUSTOMERS:
                    endpoint = `${ENDPOINTS.GET.CUSTOMER}/${id}`;
                    break;
                case MODAL_TYPES.PERMISSIONS_USERS:
                    endpoint = `${ENDPOINTS.GET.USER}/${id}`;
                    break;
                default:
                    break;
            }
            const resp = await fetchWithToken(
                endpoint
            );
            const body = await resp.json();
            if (body.success) {
                const { data } = body;
                // const {
                //     parentIsTemplate,
                //     parentIsCustomer,
                //     permission
                // } = data;
                dispatch(setCustomTemplate(data));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export const updateCustomTemplate = (params) => {
    return async (dispatch) => {
        try {
            const { modal, payload } = params;
            let endpoint = '';
            switch (modal) {
                case MODAL_TYPES.PERMISSIONS_CUSTOMERS:
                    endpoint = `${ENDPOINTS.POST.CUSTOMER}`;
                    break;
                case MODAL_TYPES.PERMISSIONS_USERS:
                    endpoint = `${ENDPOINTS.POST.USER}`;
                    break;
                default:
                    break;
            }
            const resp = await fetchWithToken(
                endpoint,
                payload,
                'POST'
            );
            const body = await resp.json();
            if (body.success) {
                const { data: { permission } } = body;
                const { json } = permission;

                dispatch(setCustomJSON(json));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}

export const deleteCustomTemplate = (params) => {
    return async (dispatch) => {
        try {

            const { id, modal } = params;

            let endpoint = '';
            switch (modal) {
                case MODAL_TYPES.PERMISSIONS_CUSTOMERS:
                    endpoint = `${ENDPOINTS.POST.CUSTOMER}/${id}`;
                    break;
                case MODAL_TYPES.PERMISSIONS_USERS:
                    endpoint = `${ENDPOINTS.POST.USER}/${id}`;
                    break;
                default:
                    break;
            }
            const resp = await fetchWithToken(
                endpoint,
                {},
                'DELETE'
            );
            const body = await resp.json();
            if (body.success) {
                const { message } = body;
                dispatch(setPermissionMessage(message));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}


export const insertBaseTemplate = (payload) => {
    return async (dispatch) => {
        try {

            const resp = await fetchWithToken(
                ENDPOINTS.POST.TEMPLATE,
                payload,
                'POST'
            );
            const body = await resp.json();
            if (body.success) {
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }
}