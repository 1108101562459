export const FILTER_ATTRIBUTES = {
    USERNAME: 'BY USERNAME',
    NAME: 'BY NAME',
    EMAIL: 'BY EMAIL',
    ROLE: 'BY ROLE',
    CUSTOMER: 'BY CLIENT',
    URL: 'BY URL',
    NOTIFICATION_DATES: 'BY NOTIFICATION DATES',
    NOTIFICATION_TYPE: 'BY NOTIFICATION TYPE',
    NOTIFICATION_STATE: 'BY NOTIFICATION STATE',
    REPORTING: {
        STARTDATE: 'FILTER BY startDate',
        ENDDATE: 'FILTER BY endDate',
        STARTDATEORDER: 'FILTER BY startDateOrder',
        ENDDATEORDER: 'FILTER BY endDateOrder',
        CUSTOMER: 'FILTER BY customer',
        USERNAME: 'FILTER BY username'
    }
}

export const DATES_VARIABLES = {
    fromHour: 'T00:00:00.000Z',
    endHour: 'T23:59:59.999Z',
    dateFormat: 'YYYY-MM-DD',
    spanishDateFormat: 'DD/MM/YYYY'
}

export const ORDER_TYPE = {
    ASCENDANT: 'asc',
    DESCENDENT: 'desc'
}