export const SEND_TYPES = {
    EMAIL: 'email',
    PLATFORM: 'platform',
    PLATFORM_EMAIL: 'platform_email'
}

export const SEND_TYPES_FILTER_OPTIONS = [
    {
        id: 'email',
        name: 'Email',
        checked: false
    },
    {
        id: 'platform',
        name: 'Plataforma',
        checked: false
    },
    // {
    //     id: 'platform_email',
    //     name: 'Email y Plataforma',
    //     checked: false
    // },
];
export const STATE_TYPES_FILTER_OPTIONS = [
    {
        id: 'ok',
        name: 'Leídas',
        checked: false
    },
    {
        id: 'pending',
        name: 'No leídas',
        checked: false
    },

]