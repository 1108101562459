/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 10/03/2022                                        
* @Description Select option, where options are checkbox and can be selected                                                         
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { useEffect, useState } from 'react';
import './filter.css';
import { useDispatch, useSelector } from 'react-redux';
import { FilterOption } from './FilterOption';
import { SelectAllOption } from './SelectAllOption';
import { INPUT_SELECTS, INPUT_SELECTS_STATE_VARIABLE } from '../../constants/inputSelects';
import { switchClientMenu, switchInputSelectMenu } from '../../actions/inputSelectActions';
import { setDatePickerToggle } from '../../actions/datePicker';
import { translate } from '../../utils/translate';

export const InputSelect = (props) => {
    const {
        filterType,
        inputId,
        label,
        ariaLabel,
        inputName,
        inputType,
        visibleSelectedValue,
        className = '',
        hasSelectAll = false,
        callBackOption = () => { return false },
        attribute = null
    } = props;
    const dispatch = useDispatch();
    const { current: { dictionary } } = useSelector((state) => state.translation);

    const { inputSelect } = useSelector(state => state);
    const { clients, notificationFilterSendType, notificationFilterState } = inputSelect;

    const [showOptions, setShowOptions] = useState(false);
    const [options, setOptions] = useState([]);
    const [tooltip, setTooltip] = useState('');
    const arrowDown = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_down_black_24dp.svg`;
    const arrowUp = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_up_black_24dp.svg`;


    const handleOpenInputSelect = () => {
        dispatch(setDatePickerToggle(false))
        switch (filterType) {
            case INPUT_SELECTS.CLIENTS:
                dispatch(switchClientMenu(!clients.isOpen));
                break;
            case INPUT_SELECTS.NOTIFICATION.SEND_TYPE:
                dispatch(switchInputSelectMenu({
                    inputSelectStateProp: INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.SEND_TYPE,
                    isOpen: !notificationFilterSendType.isOpen
                }));
                break;
            case INPUT_SELECTS.NOTIFICATION.STATE:
                dispatch(switchInputSelectMenu({
                    inputSelectStateProp: INPUT_SELECTS_STATE_VARIABLE.NOTIFICATION.STATE,
                    isOpen: !notificationFilterState.isOpen
                }));
                break;
            default:
                break;
        }
    }
    // const inputOptionsClassName = filterType === INPUT_SELECTS.CLIENTS ? 'input-options input-options__xl' : 'input-options';

    useEffect(() => {
        switch (filterType) {
            case INPUT_SELECTS.CLIENTS:
                setShowOptions(clients.isOpen);
                setOptions(clients.options);
                setTooltip(clients.tooltip);
                break;
            case INPUT_SELECTS.NOTIFICATION.SEND_TYPE:
                setShowOptions(notificationFilterSendType.isOpen);
                setOptions(notificationFilterSendType.options);
                setTooltip(notificationFilterSendType.tooltip);
                break;
            case INPUT_SELECTS.NOTIFICATION.STATE:
                setShowOptions(notificationFilterState.isOpen);
                setOptions(notificationFilterState.options);
                setTooltip(notificationFilterState.tooltip);
                break;
            default:
                break;
        }
    }, [inputSelect, clients, notificationFilterSendType, notificationFilterState]);

    return (
        <div className={`filter ${className}`}>
            {
                label &&
                <div className="input-label filter-label">{label}</div>
            }
            <div
                className={`filter-box`}
            >
                <div
                    className={`input-selectedOption form-control form-control__borderRadius ${showOptions ? 'input-select-optionsShow' : ''}`}
                    onClick={() => handleOpenInputSelect()}
                >
                    <div className="input-selectedOption-value" title={tooltip}>
                        {translate(visibleSelectedValue, dictionary)}
                    </div>
                    <div className="input-selectedOption-icon">
                        {
                            showOptions ?
                                <img src={arrowUp} alt="arrowUp" />
                                :
                                <img src={arrowDown} alt="arrowDown" />
                        }
                    </div>

                </div>
                {
                    showOptions && options &&
                    <div className="input-optionsList" aria-label={ariaLabel}>
                        {
                            hasSelectAll && inputType === 'checkbox' &&
                            <SelectAllOption
                                filterType={filterType}
                                inputId={inputId}
                            />
                        }
                        {
                            options.map((item, index) => {
                                const { id, name } = item;

                                return (
                                    <FilterOption
                                        key={`${inputId}_parentOption_${index}`}
                                        currentOption={item}
                                        filterType={filterType}
                                        options={options}
                                        inputId={inputId}
                                        index={index}
                                        inputType={inputType}
                                        inputName={inputName}
                                        value={id ? id : ''}
                                        labelTranslate={name}
                                        callBackOption={callBackOption}
                                        attribute={attribute}
                                    />
                                )
                            })
                        }
                    </div>
                }
            </div>
        </div >
    )
}

// InputSelect.propTypes = {
//     options: PropTypes.array
// };
