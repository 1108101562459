import React, { useEffect } from "react";

import { UserProvider } from "./context/UserContext";
import { RoleProvider } from "./context/RoleContext";
import { AppRouter } from "./routes/AppRouter";
import { ClientProvider } from "./context/ClientContext";
import { ProductProvider } from "./context/ProductContext";
import { ColorProvider } from "./context/ColorContext";
import { PaginationProvider } from "./context/PaginationContext";
import { ProductCrudProvider } from "./context/ProductCrudContext";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";
import { fetchSimple } from "./helpers/fetch";
import "./App.css";
// import './css/notification.css';
// import './css/inputSwitch.css';
// import './css/datePicker.css';
import "./css/index.js";

const App = () => {
  const constructLogins = () => {
    if (process.env.REACT_APP_TEST === "1") {
      const renewTokenInURL = async (args) => {
        const resp = await fetchSimple("auth/login", args, "POST");
        const body = await resp.json();
        if (body.success) {
          const { data } = body;
          if (data) {
            sessionStorage.clear();
            const { token } = data;
            const paramsForLoadUser = "_s=841110&_l=1";
            const newURL = `${window.location.protocol}//${window.location.host}?_i=${token}&${paramsForLoadUser}`;

            window.location.replace(newURL);
          }
        }
      };

      const logins = [];
      logins.push(
        <div
          className="__login"
          key="login_1"
          onClick={() =>
            renewTokenInURL({
              username: "aanton",
              password: "Iea_2012",
            })
          }
        >
          Administrador ideauto (aanton)
        </div>
      );
      logins.push(
        <div
          className="__login"
          key="login_2"
          onClick={() =>
            renewTokenInURL({
              username: "ALEJ_corpor",
              password: "12345678",
            })
          }
        >
          Administrador corporativo (ALEJ_corpor)
        </div>
      );
      logins.push(
        <div
          className="__login"
          key="login_3"
          onClick={() =>
            renewTokenInURL({
              username: "UserBabooni",
              password: "12345678",
            })
          }
        >
          Usuario (UserBabooni)
        </div>
      );
      return logins;
    } else return;
  };

  return (
    <ProductProvider>
      <ColorProvider>
        <ClientProvider>
          <PaginationProvider>
            <UserProvider>
              <RoleProvider>
                <ProductCrudProvider>
                  <Provider store={store}>
                    {process.env.REACT_APP_TEST === "1" && (
                      <div
                        style={{
                          margin: "20px",
                          backgroundColor: "lightgrey",
                          width: "300px",
                          padding: "5px",
                          borderRadius: "10px",
                          border: "2px solid #f32",
                        }}
                      >
                        <div style={{ fontWeight: 700, color: "#f32" }}>
                          SOLO PARA TEST
                        </div>
                        {constructLogins()}
                        {/* <LoginScreen /> */}
                      </div>
                    )}
                    <AppRouter />
                  </Provider>
                </ProductCrudProvider>
              </RoleProvider>
            </UserProvider>
          </PaginationProvider>
        </ClientProvider>
      </ColorProvider>
    </ProductProvider>
  );
};

export default App;
