/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 25/11/2021                                        
* @Description Root reducer                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { combineReducers } from "redux";
import { contactReducer } from "./contactReducer";
import { datePickerReducer } from "./datePickerReducer";
import { inputSelectReducer } from "./inputSelectReducer";
import { modalReducer } from "./modalReducer";
import { notificationReducer } from "./notificationReducer";
import { parameterReducer } from "./parameterReducer";
import { permissionReducer } from "./permissionReducer";
import { productReducer } from "./productReducer";
import { reportingReducer } from "./reportingReducer";
import { translationReducer } from "./translationReducer";
import { userReducer } from "./userReducer";

export const rootReducer = combineReducers({
    contact: contactReducer,
    datePicker: datePickerReducer,
    inputSelect: inputSelectReducer,
    modal: modalReducer,
    notification: notificationReducer,
    parameter: parameterReducer,
    permission: permissionReducer,
    products: productReducer,
    reporting: reportingReducer,
    translation: translationReducer,
    user: userReducer
});