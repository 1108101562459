import React, { useEffect } from 'react'
import {
    Container,
    Col,
    Button,
    Row
} from 'react-bootstrap';
import { useAuth } from '../../context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationOne, updateNotificationState, } from '../../actions/notification';
import { NotificationMessage } from '../../components/Notification/NotificationMessage';
import { useParams } from 'react-router-dom';
import { translate } from '../../utils/translate';
import { DICTIONARY } from '../../constants/dictionary';

const ReadNotification = () => {
    const paramsForLoaded = '&_s=841110&_l=1';

    const { current: { dictionary } } = useSelector((state) => state.translation);
    const { notificationId } = useParams();
    const dispatch = useDispatch();

    const { state: { token } } = useAuth();

    const goBackToNotificationList = async () => {

        window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/notification/received?_i=${token}${paramsForLoaded}`;
    }
    useEffect(() => {
        dispatch(getNotificationOne({ payload: notificationId, isForRead: true }))
    }, []);

    useEffect(() => {
        const payload = {
            state: 'ok'
        }
        dispatch(updateNotificationState({ payload, notificationId }))
    }, []);


    return (
        <>
            <Container
                fluid
            >
                <Row>
                    <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        xxl="12">
                        <div className='section-name'>
                            <span className="material-icons-round section-icon">
                                notifications
                            </span>
                            <div>
                                {translate(DICTIONARY.TITLE.NOTIFICATION_DETAIL, dictionary)}
                            </div>
                        </div>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NotificationMessage
                            isRead={true}
                            showDate={true}
                        />
                        <Row>
                        </Row>
                        <Button
                            variant="secondary"
                            type="button"
                            className="w-25 float-end button-form"
                            onClick={goBackToNotificationList}
                        >
                            {translate(DICTIONARY.BUTTON.GO_BACK_TO_LIST, dictionary)}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ReadNotification;