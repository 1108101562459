/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/07/2022                                        
* @Description Actions for client                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { MODAL_TYPES } from "../constants/modals";
import { fetchWithToken } from "../helpers/fetch";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import { setMessage } from "./modal";

const ENDPOINTS = {
    POST: {
        ACTIVATE: 'customers/active'
    }
}

export const activeDeactiveCustomer = (params) => {
    return async (dispatch) => {
        try {
            const {
                id,
                payload,
                succesMessage,
                errorMessage } = params;
            const resp = await fetchWithToken(
                `${ENDPOINTS.POST.ACTIVATE}/${id}`,
                payload,
                'PATCH'
            );
            const body = await resp.json();

            if (body.success) {
                dispatch(setMessage({
                    id: MODAL_TYPES.ACTIVATE_CUSTOMERS,
                    // message: 'Se ha guardado la configuración correctamente',
                    message: succesMessage,
                    icon: 'success'
                }));
            } else {
                dispatch(setMessage({
                    id: MODAL_TYPES.ACTIVATE_CUSTOMERS,
                    // message: 'Ha ocurrido un error al guardar la configuración',
                    message: errorMessage,
                    icon: 'error'
                }));
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }

    }
}