/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 02/03/2022                                        
* @Description Badge for user roles                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React from 'react'
import { useSelector } from 'react-redux';
import { BADGE_TYPES } from '../../constants/badgesTypes';
import COLORS from '../../constants/colors';
import ROLES from '../../constants/roles';
import { getRoleName } from '../UI/Launcher/helpers';

export const Badge = (props) => {

    const { text, type = '' } = props;
    let color = '';
    let backgroundColor = '';

    const { current: { dictionary } } = useSelector((state) => state.translation);

    switch (text) {
        case 'Administrador':
        case ROLES.ADMIN:
            color = COLORS.super_adminText;
            backgroundColor = COLORS.super_admin;
            break;
        case 'Administrador corporativo':
        case ROLES.CUSTOMER:
            color = COLORS.customer_adminText;
            backgroundColor = COLORS.customer_admin;
            break;
        case ROLES.USER:
            color = COLORS.userText;
            backgroundColor = COLORS.user;
            break;
        default:
            color = COLORS.userText;
            backgroundColor = COLORS.user;
            break;
    }

    if (type !== '') {
        switch (type) {
            case BADGE_TYPES.ACTIVE:
                color = COLORS.activeText;
                backgroundColor = COLORS.active;
                break;
            case BADGE_TYPES.DEACTIVE:
                color = COLORS.deactiveText;
                backgroundColor = COLORS.deactive;
                break;
            case BADGE_TYPES.ACTIVE_UNTIL:
                color = COLORS.active_untilText;
                backgroundColor = COLORS.active_until;
                break;
            default:
                color = COLORS.userText;
                backgroundColor = COLORS.user;
                break;
        }
    }

    const badgeStyle = {
        backgroundColor,
        color
    }

    return (
        <div className="badge" style={badgeStyle}>{getRoleName(text, dictionary)}</div>
    )
}
