/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 19/07/2022
 * @Description Actions for productReducer
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import { FILTER_ATTRIBUTES } from "../constants/filter";

import { fetchWithToken, uploadImage } from "../helpers/fetch";
import { getFromSession } from "../helpers/session";
import { types } from "../types/types";
import { populateEndpointWithParams } from "../utils/populateEndpointWithParams";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import ENDPOINTS from "./endpoints";

const sessionKey = "products";
const setProductsList = (payload) => ({
  type: types.productSetList,
  payload,
});
const setAllProducts = (products) => ({
  type: types.productGetAll,
  payload: products,
});

// const setLoadByClient = (payload) => ({
//     type: types.productSetLoadByClient,
//     payload
// })
const setAllProductsLoaded = (payload) => ({
  type: types.productSetAllLoaded,
  payload,
});
const setNextPage = (payload) => ({
  type: types.productSetNextPage,
  payload,
});
export const setAllProductsSelected = () => ({
  type: types.productSelectAll,
});
export const setAllProductsUnselected = () => ({
  type: types.productUnselectAll,
});
export const setOneProductSelected = (payload) => ({
  type: types.productSelectOne,
  payload,
});
export const setOneProductUnselected = (payload) => ({
  type: types.productUnselectOne,
  payload,
});

export const setProductSetSelected = () => ({
  type: types.productSetSelected,
});
const productDelete = (payload) => ({
  type: types.productDelete,
  payload,
});
// const setNewProductList = (payload) => ({
//     type: types.productSetNewList,
//     payload
// })
export const resetProductData = () => ({
  type: types.productResetData,
});
const setProductData = (payload) => ({
  type: types.productSetData,
  payload,
});
const setFilters = (payload) => ({
  type: types.productFilter,
  payload,
});

const setFilteredData = (payload) => ({
  type: types.productSetFilteredData,
  payload,
});

export const getAllProducts = (query = "", limit = "100", page = "1") => {
  return async (dispatch) => {
    try {
      const valuesFromSession = getFromSession(sessionKey);

      if (valuesFromSession) {
        dispatch(setAllProducts(valuesFromSession));
      } else {
        const resp = await fetchWithToken(
          `products?name=&customerName=&url=&limit=${limit}&page=${page}`,
          {},
          "GET"
        );
        const body = await resp.json();
        if (body.success) {
          const {
            data: { products },
          } = body;
          let _products = products.map((item) => {
            const { customers } = item;
            let _customers = "";
            if (customers && customers.length > 0) {
              _customers = customers.map((_c) => _c.Name).join(", ");
            }
            return {
              ...item,
              customers: _customers,
            };
          });
          // //join ','
          // sessionStorage.setItem('products', JSON.stringify(products))
          dispatch(setAllProducts(products));
        } else {
          redirectToNoAuthorizedURL(resp);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getAllProductsWithParams = async (
  token,
  limit = "",
  page = "1"
) => {
  try {
    const resp = await fetchWithToken(
      `products?name=&customerName=&url=&limit=${limit}&page=${page}`,
      {},
      "GET",
      token
    );
    const body = await resp.json();
    if (body.success) {
      const {
        data: { count, products },
      } = body;
      let _products = products.map((item) => {
        const { customers } = item;
        let _customers = "";
        if (customers && customers.length > 0) {
          _customers = customers.map((_c) => _c.Name).join(", ");
        }
        return {
          ...item,
          customers: `${_customers}`,
        };
      });
      return {
        count,
        products: _products,
      };
    } else {
      redirectToNoAuthorizedURL(resp);
    }
  } catch (error) {
    console.error(error);
  }
};
export const getProductsWithPagination = (params) => {
  return async (dispatch) => {
    try {
      const { token, limit, page: _page } = params;
      // let clients = stateClient?.clients ? stateClient.clients : [];
      // let getClients = false;
      let page = _page;
      // if (userRole !== '' && userRole === ROLES.ADMIN) {
      //     getClients = true;
      // }

      let allProducts = [];
      const productsWithParams = await getAllProductsWithParams(
        token,
        limit,
        page
      );
      const { products } = productsWithParams;
      if (products && products.length > 0) {
        const _products = products.map((prod) => {
          let productObj = {
            ...prod,
            checked: false,
          };
          return productObj;
        });

        if (_products.length < limit) {
          dispatch(setAllProductsLoaded(true));
        }
        allProducts = [...allProducts, ..._products];
        page++;
        dispatch(setNextPage(page));
      }

      if (allProducts) {
        dispatch(setProductsList(allProducts));
        dispatch(setNextPage(page));
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteProduct = (productId) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`products/${productId}`, {}, "DELETE");

      const body = await resp.json();
      if (body.success) {
        dispatch(productDelete(productId));
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};
export const createProduct = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`products`, payload, "POST");

      const body = await resp.json();
      if (body.success) {
        // dispatch(productDelete(productId));
      } else {
        redirectToNoAuthorizedURL(resp);
      }
      return body;
    } catch (error) {
      console.error(error);
    }
  };
};
//upload logo of client
export const uploadProductImage = async (id, token, file) => {
  try {
    const resp = await uploadImage(`products/uploads/photo/${id}`, file, token);
    const body = await resp.json();
    if (body.success) {
      // return customers;
    } else {
      return "";
    }
  } catch (error) {
    console.error(error);
  }
};

export const getProductsByFilter = (params) => {
  return async (dispatch) => {
    try {
      const {
        filters = null,
        attribute,
        search,
        limit = 100,
        page = "1",
      } = params;

      let newFilters = {
        name: filters?.name ? filters?.name : "",
        url: filters?.url ? filters?.url : "",
        customerName: filters?.customerName ? filters?.customerName : "",
      };

      switch (attribute) {
        case FILTER_ATTRIBUTES.NAME:
          newFilters.name = search;
          break;
        case FILTER_ATTRIBUTES.URL:
          newFilters.url = search;
          break;
        case FILTER_ATTRIBUTES.CUSTOMER:
          newFilters.customerName = search;
          break;
        default:
          break;
      }
      const { name, customerName, url } = newFilters;
      const resp = await fetchWithToken(
        `products?name=${name}&customerName=${customerName}&url=${url}&limit=${limit}&page=${page}`,
        {},
        "GET"
      );
      const body = await resp.json();
      if (body.success) {
        const {
          data: { products },
        } = body;

        let _products = products.map((item) => {
          const { customers } = item;
          let _customers = "";
          if (customers && customers.length > 0) {
            _customers = customers.map((_c) => _c.Name).join(", ");
          }
          return {
            ...item,
            customers: _customers,
            checked: false,
          };
        });

        dispatch(setFilteredData(_products));
        dispatch(
          setFilters({
            attribute,
            search,
          })
        );
        dispatch(setAllProductsLoaded(true));
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getOneProduct = (productId) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(`products/${productId}`, {}, "GET");
      const body = await resp.json();

      if (body.success) {
        const {
          data: { product },
        } = body;
        dispatch(setProductData(product));
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateProduct = async (params) => {
  try {
    const { id, payload } = params;
    const resp = await fetchWithToken(`products/${id}`, payload, "PATCH");
    const body = await resp.json();
    if (body.success) {
      return body;
    } else {
      redirectToNoAuthorizedURL(resp);
    }
  } catch (error) {
    console.error(error);
  }
};

export const setMaintenance = async (params) => {
  try {
    const { id, payload } = params;
    const resp = await fetchWithToken(
      `${ENDPOINTS.PRODUCT.PATCH.MAINTENANCE}/${id}`,
      payload,
      "PATCH"
    );
    const body = await resp.json();
    if (body.success) {
    } else {
      redirectToNoAuthorizedURL(resp);
    }
  } catch (error) {
    console.error(error);
  }
};

export const setIsAvailableMobile = async (args) => {
  try {
    const { payload = {}, id } = args;
    const _endpoint = ENDPOINTS.PRODUCT.PATCH.UPDATE_AVAILABLE_MOBILE;
    const endpointParams = [
      {
        name: ":id",
        value: id,
      },
    ];
    const endpoint = populateEndpointWithParams({
      endpoint: _endpoint,
      endpointParams,
    });

    const resp = await fetchWithToken(`${endpoint}`, payload, "PATCH");
    const body = await resp.json();
    if (body.success) {
    } else {
      redirectToNoAuthorizedURL(resp);
    }
  } catch (error) {
    console.error(error);
  }
};
