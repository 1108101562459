import React, { useContext, useEffect } from 'react'
import {
    Container,
    Form,
    Button,
    Row,
    Col,
    Spinner,
    Image
} from 'react-bootstrap';
import Swal from 'sweetalert2';
import { useAuth } from '../../context/AuthContext';
import Table from 'react-bootstrap/Table';
import { ClientContext } from '../../context/ClientContext';
import { sweetAlertProps } from '../../constants/sweetAlertProps';
import { FILTER_ATTRIBUTES } from '../../constants/filter';
import ROLES from '../../constants/roles';
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteProduct,
    getProductsByFilter,
    getProductsWithPagination,
    resetProductData,
    setAllProductsSelected,
    setAllProductsUnselected,
    setOneProductSelected,
    setOneProductUnselected,
    setProductSetSelected
} from '../../actions/product';
import COLORS from '../../constants/colors';
import { translate } from '../../utils/translate';
import { DICTIONARY } from '../../constants/dictionary';


const IndexProduct = () => {

    let isAdmin = false;
    let isCustomAdminOrUser = false;
    const userRole = sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : '';
    const { current: { dictionary } } = useSelector((state) => state.translation);

    if (userRole !== '') {
        isAdmin = userRole === ROLES.ADMIN;
        isCustomAdminOrUser = userRole === ROLES.CUSTOMER || userRole === ROLES.ADMIN;
    }
    const paramsForLoaded = '&_s=841110&_l=1';
    const limit = 25;
    const dispatch = useDispatch();
    const { state: stateAuth } = useAuth();
    const { getAllClients } = useContext(ClientContext);

    const { list, isUsingFilter, selectedProducts, filters, filteredProducts } = useSelector((state) => state.products);

    const productList = (filteredProducts && filteredProducts.length > 0) || isUsingFilter ? filteredProducts : list;

    const handleSelectAll = (ev) => {
        const { checked } = ev.target;
        if (checked) dispatch(setAllProductsSelected());
        if (!checked) dispatch(setAllProductsUnselected());
        dispatch(setProductSetSelected());
    }

    const handleSelectProduct = (ev, productId) => {
        const { checked } = ev.target;
        if (checked) dispatch(setOneProductSelected(productId));
        if (!checked) dispatch(setOneProductUnselected(productId));
        dispatch(setProductSetSelected());
    }


    const handleDeleteRecord = () => {
        if (selectedProducts) {
            Swal.fire({
                ...sweetAlertProps,
                html: translate(DICTIONARY.MESSAGE.PRODUCT_DELETE, dictionary),
                showDenyButton: true,
                confirmButtonText: translate(DICTIONARY.BUTTON.CONFIRM_DELETE, dictionary),
                denyButtonText: translate(DICTIONARY.BUTTON.CANCEL_DELETE, dictionary),
                confirmButtonColor: COLORS.buttonRed,
                denyButtonColor: COLORS.button
            }).then((result) => {
                if (result.isConfirmed) {
                    selectedProducts.forEach(async (item) => {
                        dispatch(deleteProduct(item));

                    });
                } else if (result.isDenied) {
                }
            });
        }
    }

    let timer = null;

    const handleFilter = (attribute, search) => {
        const payload = {
            filters,
            attribute,
            search
        }
        const delayTime = 500;
        clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(getProductsByFilter(payload));
        }, delayTime);
    }

    const handleEditProduct = (productId) => {
        dispatch(resetProductData());
        window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/product/edit/${productId}?_i=${stateAuth.token}${paramsForLoaded}`;
        // navigate(`/product/edit/${productId}?_i=${stateAuth.token}${paramsForLoaded}`, { replace: false });
    }

    const nextPage = sessionStorage.getItem('nextPageProducts');
    const handleScroll = () => {
        const allProductsLoaded = sessionStorage.getItem('allProductsLoaded');
        const actualScroll = window.innerHeight + window.scrollY + 400;
        if (!!allProductsLoaded && actualScroll >= document.body.offsetHeight) {
            const productPaginationObj = {
                token: stateAuth.token,
                limit,
                page: nextPage,
                userRole: sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : ''
            }
            dispatch(getProductsWithPagination(productPaginationObj));
            window.removeEventListener('scroll', handleScroll);
        }

    }
    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [nextPage, filters]);

    useEffect(() => {
        if (isAdmin) {
            getAllClients(stateAuth.token);
        }
    }, []);

    useEffect(() => {
        // getAllRoles(dispatchRole, stateAuth.token);
        if (!isUsingFilter) {
            if (list && list.length === 0) {
                sessionStorage.setItem('allProductsLoaded', false);
                // token, limit, page
                const productPaginationObj = {
                    token: stateAuth.token,
                    limit,
                    page: 1,
                    userRole: sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : ''
                }
                dispatch(getProductsWithPagination(productPaginationObj));
            }
        } else {
            if (filters) {
                dispatch(getProductsByFilter({
                    filters
                }));
            }
        }

    }, [isUsingFilter]);

    const _icon = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/img/default/apps_black_24dp.svg`;
    //TODO ADD TRANSLATIONS
    return (
        <Container
            fluid
        >
            <div className="row mb-5">
                <div className="col-xs-12 col-md-3">
                    <div className='section-name'>
                        <div className='section-icon__img '>
                            <img
                                className='product-icon product-icon__tiny'
                                src={_icon}
                                alt={`Productos`}
                            />

                        </div>
                        <div>{translate(DICTIONARY.TITLE.PRODUCT_INDEX, dictionary)}</div>
                    </div>
                </div>
                <div className="col-xs-12 col-md-6"></div>
                {
                    isCustomAdminOrUser &&
                    <div className="col-xs-12 col-md-3">
                        <Button variant="light" className="w-25 float-end button-delete" onClick={handleDeleteRecord}>{translate(DICTIONARY.BUTTON.DELETE, dictionary)}</Button>
                        <Button variant="secondary" href={`${process.env.REACT_APP_BROWSER_ROUTE}/product/create?_i=${stateAuth.token}${paramsForLoaded}`} className="w-25 float-end">{translate(DICTIONARY.BUTTON.CREATE, dictionary)}</Button>
                    </div>
                }
            </div>
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    xxl="12"
                >
                    {
                        !list &&
                        <>
                            <div className="spinner">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>
                            <div className="spinner">
                                <div className="spinner-message">{translate(DICTIONARY.LABEL.PRODUCT_LOADING, dictionary)}</div>

                            </div>
                        </>
                    }
                    {
                        productList &&
                        <Table borderless responsive className="table-launcher">
                            <thead>
                                <tr>
                                    <th className="tableHeader-filter__checkbox">
                                        <Form.Check type="checkbox" onChange={handleSelectAll} />
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            {/* <div>Nombre</div> */}
                                            <div className="tableHeader-filter__input">
                                                <Form.Control
                                                    type="text"
                                                    placeholder={translate(DICTIONARY.PLACEHOLDER.NAME, dictionary)}
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.NAME, ev.target.value)}
                                                    defaultValue={filters?.name && filters?.name !== '' ? filters?.name : ''}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th className="tableHeader-filter">
                                        {translate(DICTIONARY.LABEL.ICON, dictionary)}
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <Form.Control
                                                    type="text"
                                                    placeholder={translate(DICTIONARY.PLACEHOLDER.URL, dictionary)}
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.URL, ev.target.value)}
                                                    defaultValue={filters?.url && filters?.url !== '' ? filters?.url : ''}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <Form.Control
                                                    type="text"
                                                    placeholder={translate(DICTIONARY.PLACEHOLDER.CLIENT, dictionary)}
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.CUSTOMER, ev.target.value)}
                                                    defaultValue={filters?.customer && filters?.customer !== '' ? filters?.customer : ''}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    productList && productList.length > 0 && productList.map((item, index) => {
                                        const { checked, id, logo, name, url, customers } = item;
                                        const tokenFromSession = JSON.parse(sessionStorage.getItem('ideauto_u'));
                                        const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
                                        const logoUrl = logo ?
                                            `${apiURL}/products/uploads/photo/${id}?token=${tokenFromSession.token}` :
                                            ``;

                                        return (
                                            <tr
                                                className="table-hoverRow"
                                                key={`${id}_${index}`}
                                            >
                                                <td>
                                                    <div
                                                        className="table-checkbox">
                                                        <Form.Check
                                                            className="table-checkbox"
                                                            type="checkbox"
                                                            name={`user[${id}]`}
                                                            checked={checked}
                                                            onChange={(ev) => handleSelectProduct(ev, id)}
                                                        />
                                                    </div>

                                                </td>
                                                <td
                                                    onClick={() => handleEditProduct(id)}>
                                                    {name}
                                                </td>
                                                <td
                                                    onClick={() => handleEditProduct(id)}>
                                                    {
                                                        logoUrl && logoUrl !== '' && <Image className='product-image' src={logoUrl} />
                                                    }
                                                </td>
                                                <td
                                                    onClick={() => handleEditProduct(id)}>
                                                    {url}
                                                </td>
                                                <td
                                                    onClick={() => handleEditProduct(id)}
                                                    title={customers}
                                                    className="ellipsis"
                                                >
                                                    {customers}
                                                </td>
                                            </tr>

                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default IndexProduct;