/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 30/08/2023
 * @Description Checks if url has query param isFromMobile
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

const hasParamIsFromMobile = () => {
  const params = new URLSearchParams(window.location.search);
  let _hasParamIsFromMobile = params.get("isFromMobile");
  _hasParamIsFromMobile = _hasParamIsFromMobile ? true : false;
  return _hasParamIsFromMobile;
};

export default hasParamIsFromMobile;
