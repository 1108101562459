/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/07/2022                                        
* @Description Reducer for modal                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { MODAL_TYPES } from "../constants/modals"
import { types } from "../types/types";

const initialState = {
    modals: [
        {
            id: MODAL_TYPES.ACTIVATE_USERS,
            show: false,
            message: '',
            icon: 'success'
        },
        {
            id: MODAL_TYPES.ACTIVATE_CUSTOMERS,
            show: false,
            message: '',
            icon: 'success'
        },
        {
            id: MODAL_TYPES.PERMISSIONS_USERS,
            show: false,
            message: '',
            icon: 'success'
        },
        {
            id: MODAL_TYPES.PERMISSIONS_CUSTOMERS,
            show: false,
            message: '',
            icon: 'success'
        },
    ],
    showMessage: false,
    buttons: {
        acceptDisabled: true
    }
}


export const modalReducer = (state = initialState, action) => {
    const modalsFromState = state.modals;
    switch (action.type) {
        case types.modalSetDisabledButtons:
            return {
                ...state,
                buttons: {
                    acceptDisabled: action.payload
                }
            }
        case types.modalSetMessage:
            const { id: modalId, message, icon } = action.payload;
            const modalFilteredForMessage = modalsFromState.filter((item) => item.id === modalId);
            modalFilteredForMessage[0].message = message;
            modalFilteredForMessage[0].icon = icon;

            const modalsWithoutFilteredForMessage = modalsFromState.filter((item) => item.id !== modalId);
            const modalsWithMessage = [
                ...modalsWithoutFilteredForMessage,
                ...modalFilteredForMessage
            ];

            return {
                ...state,
                modals: modalsWithMessage
            }
        case types.modalSetShow:
            const { id, show } = action.payload;
            const modalFiltered = modalsFromState.filter((item) => item.id === id);
            modalFiltered[0].show = show;

            const modalsWithoutFiltered = modalsFromState.filter((item) => item.id !== id);
            const _modals = [
                ...modalsWithoutFiltered,
                ...modalFiltered
            ];
            return {
                ...state,
                modals: _modals
            }
        case types.modalSetShowMesage:
            return {
                ...state,
                showMessage: action.payload
            }
        default:
            return state;
    }
}
