/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/01/2022                                        
* @Description Product context for manage products                                                           
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { createContext, useReducer } from 'react';
// import ROLES from '../constants/roles';
import { fetchWithToken } from '../helpers/fetch';
import { getFromSession } from '../helpers/session';
import { types } from '../types/types';
import { redirectToNoAuthorizedURL } from '../utils/redirectToNoAuthorizedURL';


const sessionKey = 'products';
const ProductContext = createContext([]);

const initialState = {
    products: [],
    productsList: [],
    loadByClient: false
}

const productReducer = (state = initialState, action) => {

    // const stateRoles = state.roles;
    switch (action.type) {
        case types.productSetList:
            return {
                ...state,
                productsList: action.payload
            }
        case types.productGetAll: ;
            return {
                ...state,
                products: action.payload
            }
        case types.productSetLoadByClient:
            return {
                ...state,
                loadByClient: action.payload
            }
        default:
            return state;
    }
}

// const setProductsList = (payload) => ({
//     type: types.productSetList,
//     payload
// })
const setAllProducts = (products) => ({
    type: types.productGetAll,
    payload: products
});

const setLoadByClient = (payload) => ({
    type: types.productSetLoadByClient,
    payload
})

const getAllProducts = async (dispatch, query = '', limit = '100', page = '1') => {
    try {
        const valuesFromSession = getFromSession(sessionKey);

        if (valuesFromSession) {
            dispatch(setAllProducts(valuesFromSession));
        } else {
            const resp = await fetchWithToken(
                `products?name=&customerName=&url=&limit=${limit}&page=${page}`,
                {},
                'GET'
            );
            const body = await resp.json();
            if (body.success) {
                const { data: { products } } = body;

                sessionStorage.setItem('products', JSON.stringify(products))
                dispatch(setAllProducts(products));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        }

    } catch (error) {
        console.error(error);
    }
}


const ProductProvider = ({ children }) => {
    const [state, dispatch] = useReducer(productReducer, []);
    // const { getAllClients, state: stateClient } = useContext(ClientContext);
    // const { dispatch: dispatchPagination, setNextPageProducts, setAllProductsLoaded } = usePagination();


    // const getAllProductsWithParams = async (token, limit = '', page = '') => {
    //     try {
    //         const resp = await fetchWithToken(
    //             `products?limit=${limit}&page=${page}`,
    //             {},
    //             'GET',
    //             token
    //         );
    //         const body = await resp.json();
    //         if (body.success) {
    //             const { data: { count, products } } = body;
    //             return {
    //                 count,
    //                 products
    //             }
    //         } else {
    //             redirectToNoAuthorizedURL(resp);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }

    // }


    const value = { state, dispatch, getAllProducts, setLoadByClient };
    return <ProductContext.Provider value={value}>{children}</ProductContext.Provider>;
}




export { ProductProvider, ProductContext }