/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 19/07/2022
 * @Description Reducer for input selects
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import { DICTIONARY } from "../constants/dictionary";
import { types } from "../types/types";
import { translate } from "../utils/translate";

const initialState = {
  clients: {
    isClientsLoaded: false,
    isOpen: false,
    isOptionSelectAllChecked: false,
    options: [],
    selectedLabel: "", // ,// 'Selecciona cliente *',
    tooltip: "",
    inputType: "checkbox",
    required: false,
  },
  notificationFilterSendType: {
    isOpen: false,
    isOptionSelectAllChecked: false,
    options: [],
    selectedLabel: DICTIONARY.PLACEHOLDER.SEND_METHOD, // 'Método de envío',
    defaultLabel: DICTIONARY.PLACEHOLDER.SEND_METHOD, // 'Método de envío',
    tooltip: "",
    selectedValue: "",
    selectedValueForAll: "platform_email",
    inputType: "checkbox",
  },
  notificationFilterState: {
    isOpen: false,
    isOptionSelectAllChecked: false,
    options: [],
    selectedLabel: DICTIONARY.LABEL.STATE, //'Estado',
    defaultLabel: DICTIONARY.LABEL.STATE, //'Estado',
    tooltip: "",
    selectedValue: "",
    selectedValueForAll: "",
    inputType: "checkbox",
  },
};

const setOptions = (args) => {
  const { state, options, inputSelectStateProp } = args;
  let newState = {
    ...state,
  };

  newState[inputSelectStateProp] = {
    ...state[inputSelectStateProp],
    options,
  };
  return newState;
};
const switchMenu = (args) => {
  const { state, isOpen, inputSelectStateProp } = args;
  let newState = {
    ...state,
    clients: {
      ...state.clients,
      isOpen: false,
    },
    notificationFilterSendType: {
      ...state.notificationFilterSendType,
      isOpen: false,
    },
    notificationFilterState: {
      ...state.notificationFilterState,
      isOpen: false,
    },
  };

  newState[inputSelectStateProp] = {
    ...state[inputSelectStateProp],
    isOpen,
  };
  return newState;
};
// const selectAllOptions = (args) => {
//     const { state, inputSelectStateProp } = args;
//     let _tooltip = '';
//     const newOptionSelectAllState = state[inputSelectStateProp].options.map((item) => {
//         if (item?.name) {
//             _tooltip = `${_tooltip}, ${item.name}`;
//         }
//         return {
//             ...item,
//             checked: true
//         }
//     })
//     let newState = {
//         ...state
//     };
//     newState[inputSelectStateProp] = {
//         ...state[inputSelectStateProp],
//         isOptionSelectAllChecked: true,
//         selectedLabel: 'Todos',
//         options: newOptionSelectAllState,
//         tooltip: _tooltip.slice(2)
//     }

//     return newState;
// }
const setOptionChecked = (args) => {
  const {
    state,
    optionId,
    defaultSelectedLabel,
    inputSelectStateProp,
    dictionary,
  } = args;
  let _tooltip = "";
  let newOptionsState = [];
  let selectedValue = "";
  if (state[inputSelectStateProp].inputType === "radio") {
    newOptionsState = state[inputSelectStateProp].options.map((item) => {
      const { checked, id } = item;
      let _checked = checked;
      if (id === optionId) {
        _checked = true;
        selectedValue = id;
      } else {
        _checked = false;
      }
      if (_checked) {
        _tooltip = `${_tooltip}, ${item.name}`;
      }
      return {
        ...item,
        checked: _checked,
      };
    });
  } else {
    newOptionsState = state[inputSelectStateProp].options.map((item) => {
      const { checked, id } = item;
      let _checked = checked;
      if (id === optionId) {
        _checked = !checked;
      }
      if (_checked) {
        _tooltip = `${_tooltip}, ${item.name}`;
      }
      return {
        ...item,
        checked: _checked,
      };
    });
  }
  const checkedOptions = newOptionsState.filter((item) => item.checked);
  let isOptionSelectAllChecked = false;
  let selectedLabel = translate(defaultSelectedLabel, dictionary); //'Selecciona cliente *';

  if (checkedOptions.length === 1) {
    selectedLabel = checkedOptions[0].name;
  } else if (
    checkedOptions.length > 0 &&
    newOptionsState.length > 0 &&
    checkedOptions.length === newOptionsState.length
  ) {
    selectedLabel = translate(DICTIONARY.PLACEHOLDER.ALL, dictionary); //'Todos';
    isOptionSelectAllChecked = true;
    if (state[inputSelectStateProp]?.selectedValueForAll) {
      selectedValue = state[inputSelectStateProp].selectedValueForAll;
    }
  } else if (checkedOptions.length > 1) {
    selectedLabel = translate(DICTIONARY.PLACEHOLDER.SOME, dictionary);
  } else if (checkedOptions.length === 0) {
    selectedLabel = state[inputSelectStateProp].defaultLabel;
  }

  let newState = {
    ...state,
  };
  newState[inputSelectStateProp] = {
    ...state[inputSelectStateProp],
    selectedLabel,
    options: newOptionsState,
    isOptionSelectAllChecked,
    tooltip: _tooltip.slice(2),
    selectedValue,
  };

  return newState;
};

const closeAll = (state) => {
  const newState = [];
  Object.keys(state).forEach((key) => {
    const item = state[key];
    newState[key] = {
      ...item,
      isOpen: false,
    };
  });
  return newState;
};
export const inputSelectReducer = (state = initialState, action) => {
  const clientsFromState = state.clients;
  let _tooltip = "";

  let inputSelectStateProp = "";
  if (action?.payload) {
    const { inputSelectStateProp: _inputSelectStateProp } = action.payload;
    inputSelectStateProp = _inputSelectStateProp;
  }
  switch (action.type) {
    case types.inputSelectSetOptionChecked:
      const { optionId, defaultSelectedLabel } = action.payload;
      const optionsChecked = setOptionChecked({
        state,
        optionId,
        defaultSelectedLabel,
        inputSelectStateProp,
        dictionary: action.payload.dictionary,
      });
      return optionsChecked;
    case types.inputSelectSelectAllOptions:
      return {
        ...state,
      };
    case types.inputSelectUnselectAllOptions:
      return {
        ...state,
      };
    case types.inputSelectSetOptions:
      const { options } = action.payload;
      const newOptionsState = setOptions({
        state,
        options,
        inputSelectStateProp,
      });
      return newOptionsState;
    case types.inputSelectSwitchOptionsMenu:
      const { isOpen } = action.payload;
      const switchOptionMenu = switchMenu({
        state,
        isOpen,
        inputSelectStateProp,
      });
      return switchOptionMenu;
    case types.inputSelectCloseAll:
      const newState = closeAll(state);
      return newState;
    case types.inputSelectSetDefaultOptions:
      return {
        ...state,
      };

    case types.inputSelectSetDefaultClients:
      const defaultSelected = action.payload;
      const _options = clientsFromState.options.map((item) => {
        return {
          ...item,
          checked: defaultSelected.indexOf(item.id) !== -1,
        };
      });
      return {
        ...state,
        options: _options,
      };
    case types.inputSelectSelectAllClients:
      const newClientOptionSelectAllState = clientsFromState.options.map(
        (item) => {
          if (item?.name) {
            _tooltip = `${_tooltip}, ${item.name}`;
          }
          return {
            ...item,
            checked: true,
          };
        }
      );

      return {
        ...state,
        clients: {
          ...clientsFromState,
          isOptionSelectAllChecked: true,
          selectedLabel: translate(
            DICTIONARY.PLACEHOLDER.ALL,
            action.payload.dictionary
          ),
          options: newClientOptionSelectAllState,
          tooltip: _tooltip.slice(2),
        },
      };
    case types.inputSelectUnselectAllClients:
      const newClientOptionUnselectAllState = clientsFromState.options.map(
        (item) => {
          return {
            ...item,
            checked: false,
          };
        }
      );
      return {
        ...state,
        clients: {
          ...clientsFromState,
          isOptionSelectAllChecked: false,
          selectedLabel: `${translate(
            DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
            action.payload.dictionary
          )} *`,
          options: newClientOptionUnselectAllState,
          tooltip: "",
        },
      };
    case types.inputSelectSetClientChecked:
      const newClientOptionsState = clientsFromState.options.map((item) => {
        const { checked, id } = item;
        let _checked = checked;
        if (id === action.payload.id) {
          _checked = !checked;
        }
        if (_checked) {
          _tooltip = `${_tooltip}, ${item.name}`;
        }
        return {
          ...item,
          checked: _checked,
        };
      });
      const checkedClients = newClientOptionsState.filter(
        (item) => item.checked
      );
      let isOptionSelectAllChecked = false;
      let selectedLabel = `${translate(
        DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
        action.payload.dictionary
      )} *`;

      if (checkedClients.length === 1) {
        selectedLabel = checkedClients[0].name;
      } else if (
        checkedClients.length > 0 &&
        newClientOptionsState.length > 0 &&
        checkedClients.length === newClientOptionsState.length
      ) {
        selectedLabel = translate(
          DICTIONARY.PLACEHOLDER.ALL,
          action.payload.dictionary
        );
        isOptionSelectAllChecked = true;
      } else if (checkedClients.length > 1) {
        selectedLabel = translate(
          DICTIONARY.PLACEHOLDER.SOME,
          action.payload.dictionary
        );
      }
      return {
        ...state,
        clients: {
          ...clientsFromState,
          selectedLabel,
          options: newClientOptionsState,
          isOptionSelectAllChecked,
          tooltip: _tooltip.slice(2),
        },
      };
    case types.inputSelectSwitchClientsMenu:
      return {
        ...state,
        clients: {
          ...clientsFromState,
          isOpen: action.payload,
        },
      };
    case types.inputSelectSetClients:
      return {
        ...state,
        clients: {
          ...clientsFromState,
          isClientsLoaded: true,
          options: action.payload,
        },
      };
    default:
      return state;
  }
};
