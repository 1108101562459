/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 07/02/2023                                        
* @Description Reducer for parameters                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { types } from "../types/types";


const initialState = {
    languageCode: ''
}
export const parameterReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.parameterSetLanguage:
            return {
                ...state,
                languageCode: action.payload
            }
        default:
            return state;
    }
}