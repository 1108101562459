/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 28/02/2022
 * @Description Color context for clients
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import React, { createContext, useContext, useReducer } from "react";
import { types } from "../types/types";

const PaginationContext = createContext([]);

const initialState = {
  nextPage: 1,
  allUsersLoaded: false,
};

const paginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.paginationSetNextPageUsers:
      sessionStorage.setItem("nextPage", action.payload);
      return {
        ...state,
        nextPage: action.payload,
      };
    case types.paginationStopLoadingUsers:
      sessionStorage.setItem("allUsersLoaded", true);
      return {
        ...state,
        allUsersLoaded: true,
      };
    default:
      return state;
  }
};

const PaginationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(paginationReducer, []);

  const setNextPage = (nextPage) => {
    try {
      dispatch({
        type: types.paginationSetNextPageUsers,
        payload: nextPage,
      });
    } catch (error) {
      console.error(`setNextPage =>`, error);
    }
  };

  const setAllUsersLoaded = () => {
    try {
      dispatch({
        type: types.paginationStopLoadingUsers,
      });
    } catch (error) {
      console.error(`setAllUsersLoaded =>`, error);
    }
  };
  const setAllProductsLoaded = () => {
    try {
      dispatch({
        type: types.paginationStopLoadingProducts,
      });
    } catch (error) {
      console.error(`setAllUsersLoaded =>`, error);
    }
  };
  const setNextPageProducts = (nextPage) => {
    try {
      dispatch({
        type: types.paginationSetNextPageUsers,
        payload: nextPage,
      });
    } catch (error) {
      console.error(`setNextPage =>`, error);
    }
  };

  const value = {
    state,
    dispatch,
    setNextPage,
    setAllUsersLoaded,
    setAllProductsLoaded,
    setNextPageProducts,
  };
  return (
    <PaginationContext.Provider value={value}>
      {children}
    </PaginationContext.Provider>
  );
};

const usePagination = () => {
  const context = useContext(PaginationContext);

  if (context === undefined) {
    throw new Error("usePagination must be used with PaginationProvider");
  }
  return context;
};

export { PaginationProvider, usePagination };
