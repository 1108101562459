/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 21/01/2022
 * @Description User context for user actions
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import React, { createContext, useContext, useReducer } from "react";
import { getUserData } from "../components/UI/Launcher/fetchLauncher";
import { FILTER_ATTRIBUTES } from "../constants/filter";
import ROLES from "../constants/roles";
import { fetchSimple, fetchWithToken, uploadImage } from "../helpers/fetch";
import { types } from "../types/types";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import { ClientContext } from "./ClientContext";
import { usePagination } from "./PaginationContext";

const UserContext = createContext([]);

const initialState = {
  users: [],
  usersAll: [],
  filteredUsers: [],
  isUsingFilter: false,
  editUserData: {},
  userData: {},
  selectedUsers: [],
  filters: {
    username: "",
    name: "",
    email: "",
    role: "",
    customer: "",
  },
  error: "",
  reloadUsers: false,
};

const userReducer = (state = initialState, action) => {
  const stateUsers = state.users;
  const stateUsersAll = state.usersAll;
  const filteredUsers = state.filteredUsers;
  const _selectedUsers = state.selectedUsers ? state.selectedUsers : [];
  const stateUserData = state.userData;
  const stateFilters = state.filters;

  switch (action.type) {
    case types.usersReloadData:
      return {
        ...state,
        reloadUsers: action.payload,
      };
    case types.userResetData:
      return {
        ...state,
        userData: {},
      };

    case types.setErrorInCurrentSection:
      return {
        ...state,
        error: action.payload,
        isUsingFilter: false,
      };
    case types.userSetSelect:
      let toggledUsers = [];
      const existSelectedClient = _selectedUsers?.filter(
        (_item) => _item === action.payload
      );
      if (existSelectedClient && existSelectedClient.length > 0) {
        toggledUsers = _selectedUsers.filter(
          (_client) => _client !== action.payload
        );
      } else {
        toggledUsers = [..._selectedUsers, action.payload];
      }
      return {
        ...state,
        error: "",
        selectedUsers: toggledUsers,
      };
    case types.userGetOne:
      return {
        ...state,
        error: "",
        userData: action.payload,
        // isUsingFilter: false
      };
    case types.userGetAll:
      let newUsersState = [];
      if (stateUsers) {
        newUsersState = [...stateUsers, ...action.payload];
      } else {
        newUsersState = [...action.payload];
      }
      return {
        ...state,
        error: "",
        users: newUsersState,
        usersAll: newUsersState,
        // filteredUsers: [],
        editUserData: {},
        isUsingFilter: false,
      };

    case types.userSelectAll:
      if (filteredUsers.length > 0) {
        const filteredUsersChecked = filteredUsers.map((user) => {
          return {
            ...user,
            checked: true,
          };
        });
        const selectedUsersInFilter = filteredUsersChecked.map(
          (user) => user.id
        );
        return {
          ...state,
          error: "",
          filteredUsers: filteredUsersChecked,
          selectedUsers: selectedUsersInFilter,
          editUserData: {},
        };
      } else {
        const userSelectAll = state.users.map((user) => {
          return {
            ...user,
            checked: true,
          };
        });
        const selectedUsersInAll = userSelectAll.map((user) => user.id);
        return {
          ...state,
          error: "",
          users: userSelectAll,
          selectedUsers: selectedUsersInAll,
          editUserData: {},
          isUsingFilter: false,
        };
      }

    case types.userUnselectAll:
      if (filteredUsers.length > 0) {
        const filteredUsersUnChecked = filteredUsers.map((user) => {
          return {
            ...user,
            checked: false,
          };
        });
        return {
          ...state,
          error: "",
          filteredUsers: filteredUsersUnChecked,
          editUserData: {},
        };
      } else {
        const userSelectAll = state.users.map((user) => {
          return {
            ...user,
            checked: false,
          };
        });
        return {
          ...state,
          error: "",
          users: userSelectAll,
          editUserData: {},
        };
      }
    case types.userSelectOne:
      const userId = action.payload;
      const userIndex = stateUsers.findIndex((user) => user.id === userId);

      stateUsers[userIndex] = {
        ...stateUsers[userIndex],
        checked: true,
      };
      if (filteredUsers && filteredUsers.length > 0) {
        const userIndexFiltered = filteredUsers.findIndex(
          (user) => user.id === userId
        );

        filteredUsers[userIndexFiltered] = {
          ...filteredUsers[userIndexFiltered],
          checked: true,
        };
        return {
          ...state,
          error: "",
          users: stateUsers,
          filteredUsers,
          editUserData: {},
        };
      } else {
        return {
          ...state,
          error: "",
          users: stateUsers,
          editUserData: {},
        };
      }

    case types.userUnselectOne:
      const _userId = action.payload;
      const _userIndex = stateUsers.findIndex((user) => user.id === _userId);

      stateUsers[_userIndex] = {
        ...stateUsers[_userIndex],
        checked: false,
      };
      return {
        ...state,
        error: "",
        users: stateUsers,
        filteredUsers: [],
        editUserData: {},
        isUsingFilter: false,
      };
    case types.userDelete:
      let newUsers = [];
      let newFiltered = [];
      let newUsersAll = [];
      if (filteredUsers.length > 0) {
        newFiltered = filteredUsers.filter(
          (user) => user.id !== action.payload
        );
      } else {
        newUsers = stateUsers.filter((user) => user.id !== action.payload);
      }
      if (newUsers.length === 0) {
        newUsersAll = stateUsersAll.filter(
          (user) => user.id !== action.payload
        );
      }
      return {
        ...state,
        error: "",
        usersAll: newUsersAll,
        users: newUsers,
        filteredUsers: newFiltered,
        editUserData: {},
        // isUsingFilter: false
      };
    case types.userFilter:
      const { attribute, search, dispatchUser } = action.payload;

      let newFilters = {
        ...stateFilters,
      };
      switch (attribute) {
        case FILTER_ATTRIBUTES.USERNAME:
          newFilters.username = search;
          break;
        case FILTER_ATTRIBUTES.NAME:
          newFilters.name = search;
          break;
        case FILTER_ATTRIBUTES.EMAIL:
          newFilters.email = search;
          break;
        case FILTER_ATTRIBUTES.ROLE:
          newFilters.role = search;
          break;
        case FILTER_ATTRIBUTES.CUSTOMER:
          newFilters.customer = search;
          break;
        default:
          break;
      }
      filterUsers(dispatchUser, newFilters);

      return {
        ...state,
        filters: {
          ...stateFilters,
          ...newFilters,
        },
      };
    case types.userSetFilteredUsers:
      const isUsingFilterValue = action.payload.length > 0;
      return {
        ...state,
        filteredUsers: action.payload,
        // selectedUsers: [],
        isUsingFilter: isUsingFilterValue,
      };

    case types.userSetFilteredData:
      return {
        ...state,
        users: action.payload,
      };
    case types.userEdit:
      const idUser = action.payload;
      let _editUserData = stateUsers.filter((user) => user.id === idUser);

      sessionStorage.setItem("currentUser", JSON.stringify(_editUserData[0]));
      return {
        ...state,
        error: "",
        editUserData: _editUserData[0],
        isUsingFilter: false,
      };
    case types.userToggleProduct:
      let toggledProducts = [];
      const userProducts = stateUserData?.products;
      if (userProducts) {
        const existProduct = userProducts.filter(
          (_item) => _item === action.payload
        );
        if (existProduct && existProduct.length > 0) {
          toggledProducts = userProducts.filter(
            (_prodId) => _prodId !== action.payload
          );
        } else {
          toggledProducts = [...userProducts, action.payload];
        }
      } else {
        toggledProducts = [action.payload];
      }

      return {
        ...state,
        userData: {
          ...stateUserData,
          products: toggledProducts,
        },
      };
    case types.userResetProducts:
      return {
        ...state,
        userData: {
          ...stateUserData,
          products: [],
        },
      };
    default:
      return state;
  }
};

const filterUsers = async (dispatchUser, newFilters) => {
  const usersFiltered = await getUsersByFilter(newFilters);

  dispatchUser({
    type: types.userSetFilteredData,
    payload: usersFiltered?.users ? usersFiltered.users : [],
  });
  dispatchUser({
    type: types.userSetFilteredUsers,
    payload: usersFiltered?.users ? usersFiltered.users : [],
  });
};

const toggleProductUser = (dispatch, product) => {
  dispatch({
    type: types.userToggleProduct,
    payload: product,
  });
};

const setSelectedUsers = (dispatch, userId) => {
  dispatch({
    type: types.userSetSelect,
    payload: userId,
  });
};
const setUserPagination = (nextPage) => ({
  type: types.usersSetNextPage,
  payload: nextPage,
});
const setAllUsers = (users) => ({
  type: types.userGetAll,
  payload: users,
});

const getUserDataFromToken = async () => {
  const ideautoU = JSON.parse(sessionStorage.getItem("ideauto_u"));
  if (ideautoU) {
    const user = await getUserData(ideautoU.token);
    return user;
  }
  return {};
};

const getAllUsersWithParams = async (
  token,
  limit = "",
  page = "",
  isActive = ""
) => {
  // endPoint, data = {}, method = 'GET', token
  try {
    const resp = await fetchWithToken(
      `users?limit=${limit}&page=${page}&isActive=${isActive}`,
      {},
      "GET",
      token
    );
    const body = await resp.json();
    if (body.success) {
      const {
        data: { users, customers, count },
      } = body;
      const usersFromRequest = users ? users : customers;
      const _users = usersFromRequest.map((user) => {
        return {
          ...user,
          checked: false,
        };
      });
      return {
        count,
        users: _users,
      };
    } else {
      redirectToNoAuthorizedURL(resp);
    }
  } catch (error) {
    console.error(error);
  }
};

const editUser = async (dispatch, data, token, userId) => {
  try {
    const resp = await fetchWithToken(`users/${userId}`, data, "PATCH", token);

    const body = await resp.json();
    if (body.success) {
      const { data } = body;
      const { user, customer } = data;

      dispatch({
        type: types.userGetOne,
        payload: user ? user : customer,
      });
    } else {
      redirectToNoAuthorizedURL(resp);
    }
    return body;
  } catch (error) {
    console.error(error);
  }
};
const updatePassword = async (dispatch, data, token, userId) => {
  try {
    const resp = await fetchWithToken(
      `users/password/${userId}`,
      data,
      "PATCH",
      token
    );

    const body = await resp.json();
    return body;
    // if (body.success) {
    //     return body;
    // } else {
    //     redirectToNoAuthorizedURL(resp);
    // }
  } catch (error) {
    console.error(error);
  }
};

const deleteUser = async (dispatch, token, userId) => {
  try {
    const resp = await fetchWithToken(`users/${userId}`, {}, "DELETE", token);
    const body = await resp.json();

    if (body.success) {
      dispatch({ type: types.userDelete, payload: userId });
      return body.success;
    } else {
      redirectToNoAuthorizedURL(resp);
      return body;
    }
  } catch (error) {
    console.error(error);
  }
};

//upload logo of client
const uploadUserImage = async (userId, token, file) => {
  try {
    const resp = await uploadImage(
      `users/uploads/photo/${userId}`,
      file,
      token
    );
    const body = await resp.json();
    if (body.success) {
      // return customers;
    } else {
      return "";
    }
  } catch (error) {
    console.error(error);
  }
};

const resetPassword = async (username) => {
  try {
    const resp = await fetchSimple(
      `users/forgotpassword`,
      {
        username,
      },
      "PATCH"
    ); //= (endPoint, data = {}, method = 'GET')
    const body = await resp.json();
    return body;
  } catch (error) {
    console.error(error);
  }
};
const getUsersByFilter = async (
  filters,
  limit = 200,
  page = "",
  isActive = ""
) => {
  try {
    const name = filters?.name ? filters.name : "";
    const email = filters?.email ? filters.email : "";
    const username = filters?.username ? filters.username : "";
    const customer = filters?.customer ? filters.customer : "";
    const role = filters?.role ? filters.role : "";
    const url = `users?name=${name}&email=${email}&username=${username}&customer=${customer}&role=${role}&limit=${limit}&page=${page}`;
    const resp = await fetchWithToken(url, {}, "GET");
    const body = await resp.json();

    if (body.success) {
      const {
        data: { users },
      } = body;
      const _users = users.map((user) => {
        return {
          ...user,
          checked: false,
        };
      });
      return {
        users: _users,
      };
    } else {
      redirectToNoAuthorizedURL(resp);
    }
  } catch (error) {
    console.error(error);
  }
};

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, []);
  const { getAllClients, state: stateClient } = useContext(ClientContext);
  const { setNextPage, setAllUsersLoaded } = usePagination();

  const getClientNameById = (clients, clientId) => {
    if (clients) {
      const filteredClient = clients.filter(
        (_client) => _client.id == clientId
      );

      if (filteredClient && filteredClient.length === 1) {
        return filteredClient[0].name;
      }
    }
    return "";
  };
  // const getAllUsers = async (token) => {
  //     try {
  //         const clients = await getAllClients(token);

  //         const roles = [
  //             {
  //                 id: "super_admin",
  //                 value: "Administrador"
  //             },
  //             {
  //                 id: "customer_admin",
  //                 value: "Administrador corporativo"
  //             },
  //             {
  //                 id: "user",
  //                 value: "Usuario"
  //             }
  //         ];

  //         const limitUsers = 200;
  //         let page = 1;
  //         let allUsers = [];
  //         let continueWithRequest = true;
  //         let countUsers = 0;
  //         do {
  //             const usersWithParams = await getAllUsersWithParams(token, limitUsers, page);
  //             const { users, count } = usersWithParams;
  //             if (users && users.length > 0) {
  //                 const _users = users.map((user) => {
  //                     return {
  //                         ...user,
  //                         checked: false,
  //                         fullName: `${user.firstName} ${user.lastName}`,
  //                         customerName: getClientNameById(clients, user.customerId),
  //                         role: roles?.filter((_item) => _item.id === user.role)[0].value
  //                     }
  //                 });

  //                 allUsers = [
  //                     ...allUsers,
  //                     ..._users
  //                 ]
  //                 if (countUsers >= count) {
  //                     continueWithRequest = false;
  //                 }
  //                 page++;
  //                 countUsers += 25;
  //             } else {
  //                 continueWithRequest = false;
  //             }
  //         } while (continueWithRequest);

  //         if (allUsers) {
  //             dispatch(setAllUsers(allUsers));
  //         }
  //     } catch (error) {
  //         console.error(error);
  //     }
  // }

  const getUsersWithPagination = async (params) => {
    try {
      const { token, limit, page: _page, userRole, isActive = "" } = params;
      let clients = stateClient?.clients ? stateClient.clients : [];
      let getClients = false;
      let page = _page;
      if (userRole !== "" && userRole === ROLES.ADMIN) {
        clients = await getAllClients(token);
        getClients = true;
      }

      let allUsers = [];
      const usersWithParams = await getAllUsersWithParams(
        token,
        limit,
        page,
        isActive
      );
      const { users } = usersWithParams;
      if (users && users.length > 0) {
        const _users = users.map((user) => {
          let userObj = {
            ...user,
            checked: false,
            fullName: `${user.firstName} ${user.lastName}`,
            customerName: "",
          };
          if (getClients) {
            userObj = {
              ...user,
              checked: false,
              fullName: `${user.firstName} ${user.lastName}`,
              customerName: getClientNameById(clients, user.customerId),
            };
          }
          return userObj;
        });

        if (_users.length < limit) {
          setAllUsersLoaded();
        }
        allUsers = [...allUsers, ..._users];
        page++;
        setNextPage(page);
      }

      if (allUsers) {
        dispatch(setAllUsers(allUsers));
        dispatch(setUserPagination(page));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getUserData = async (userId, token) => {
    try {
      const resp = await fetchWithToken(`users/${userId}`, {}, "GET", token);
      const body = await resp.json();

      if (body.success) {
        const { data } = body;
        const { user } = data;
        // user.allowEmailNotification = false;
        dispatch({
          type: types.userGetOne,
          payload: user,
        });

        return user;
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getUserRole = async (userId, token) => {
    try {
      const resp = await fetchWithToken(`users/${userId}`, {}, "GET", token);
      const body = await resp.json();

      if (body.success) {
        const { data } = body;
        const { user } = data;

        return user;
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const createUser = async (data, token) => {
    try {
      const resp = await fetchWithToken("users", data, "POST", token);

      const body = await resp.json();
      if (body.success) {
        // const { data } = body;
        // const { user } = data;
        return body;
      } else {
        // console.log
        dispatch({
          type: types.setErrorInCurrentSection,
          payload: body.message,
        });
        redirectToNoAuthorizedURL(resp);
        return body;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const resetSelectedProductsForUser = async () => {
    try {
      dispatch({
        type: types.userResetProducts,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const setReloadUsers = (payload) => {
    try {
      dispatch({
        type: types.usersReloadData,
        payload,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const value = {
    state,
    dispatch,
    // getAllUsers,
    createUser,
    uploadUserImage,
    getUserData,
    editUser,
    setSelectedUsers,
    deleteUser,
    updatePassword,
    getUserRole,
    getUserDataFromToken,
    resetPassword,
    toggleProductUser,
    resetSelectedProductsForUser,
    getClientNameById,
    getUsersWithPagination,
    filterUsers,
    setReloadUsers,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export { UserProvider, UserContext };
