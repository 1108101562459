/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 29/08/2022
 * @Description Actions for notification reducer
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import moment from "moment";
import { FILTER_ATTRIBUTES } from "../constants/filter";
import { SEND_TYPES } from "../constants/ntoificationSendTypes";
import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { populateEndpointWithParams } from "../utils/populateEndpointWithParams";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import ENDPOINTS from "./endpoints";

// NOTIFICATION: {
//      GET: {
//     LIST: 'notifications',                                  //Recuperar el listado de notificaciones
//     ONE: 'notifications',                                   //Recuperar una notificación
//     PENDING_COUNT: 'notifications/pending/number',          //Recuperar el número de notificaciones pendientes
//     CUSTOMERS_LIST: 'notifications/:id/customers',          //Recuperar los clientes de una notificación
//     USERS_LIST: 'notifications/:id/users'                   //Recuperar los usuarios de una notificación
//  },
//     PATCH: {
//         UPDATE_STATE: 'notifications/users/state'               //Actualizar el estado de la notificación como usuario
//     },
//     POST: {
//         CREATE: 'notifications'                                   //Enviar una notificación
//     }

// }
export const setNotificationToggleDatePickerInList = (payload) => ({
  type: types.notificationToggleDatePickerInList,
  payload,
});
const setNotificationGetList = (payload) => ({
  type: types.notificationGetList,
  payload,
});
const setNotificationGetFilterList = (payload) => ({
  type: types.notificationGetFilterList,
  payload,
});
const setNotificationGetOne = (payload) => ({
  type: types.notificationGetOne,
  payload,
});
const setNotificationGetPendingCount = (payload) => ({
  type: types.notificationGetPendingCount,
  payload,
});
const setNotificationSend = () => ({
  type: types.notificationSend,
});
// const setNotificationShowSendMessage = (payload) => ({
//     type: types.notificationShowSendMessage,
//     payload
// });

// const setNotificationSetIsUsingFilter = (payload) => ({
//     type: types.notificationSetIsUsingFilter,
//     payload
// });
const setNotificationSetNextPage = (payload) => ({
  type: types.notificationSetNextPage,
  payload,
});
const setNotificationSetFilters = (payload) => ({
  type: types.notificationSetFilters,
  payload,
});
const setNotificationSetAllLoaded = (payload) => ({
  type: types.notificationSetAllLoaded,
  payload,
});
export const setNotificationSetActiveTab = (payload) => ({
  type: types.notificationSetActiveTab,
  payload,
});
export const setNotificationSetSendTypeEmail = () => ({
  type: types.notificationSetSendType,
  payload: SEND_TYPES.EMAIL,
});
export const setNotificationSetSendTypePlatform = () => ({
  type: types.notificationSetSendType,
  payload: SEND_TYPES.PLATFORM,
});
export const setNotificationSetSendTypeBoth = () => ({
  type: types.notificationSetSendType,
  payload: SEND_TYPES.PLATFORM_EMAIL,
});
export const setNotificationSetSendType = (payload) => ({
  type: types.notificationSetSendType,
  payload,
});
export const setNotificationSetClientsList = (payload) => ({
  type: types.notificationSetClientsList,
  payload,
});
export const setNotificationSetUsersList = (payload) => ({
  type: types.notificationSetUsersList,
  payload,
});
export const setNotificationSetStopLoadSent = (payload) => ({
  type: types.notificationSetStopLoadSent,
  payload,
});
export const setNotificationSetStopLoadReceived = (payload) => ({
  type: types.notificationSetStopLoadReceived,
  payload,
});

const transformCreationDate = (args) => {
  const { language = null, list } = args;
  let newList = [];
  if (language) {
  } else {
    newList = list.map((item) => {
      const { creationDate } = item;
      const _creationDate = moment(creationDate).format("DD/MM/YYYY HH:mm");
      return {
        ...item,
        initialCreationDate: creationDate,
        creationDate: `${_creationDate}h`,
      };
    });
  }
  return newList;
};
const transformSendTypeToLanguage = (args) => {
  const { language = null, list } = args;
  let newList = [];
  if (language) {
  } else {
    newList = list.map((item) => {
      const { type } = item;
      let _type = "";
      switch (type) {
        case SEND_TYPES.EMAIL:
          _type = "Email";
          break;
        case SEND_TYPES.PLATFORM:
          _type = "Plataforma";
          break;
        case SEND_TYPES.PLATFORM_EMAIL:
          _type = "Email y Plataforma";
          break;
        default:
          break;
      }
      return {
        ...item,
        type: _type,
      };
    });
  }
  return newList;
};
export const getNotificationList = (args) => {
  return async (dispatch) => {
    try {
      const {
        limit = "",
        page = "",
        sent = false,
        state = "",
        type = "",
      } = args;
      const urlParams = `limit=${limit}&page=${page}&sent=${sent}&state=${state}&type=${type}`;
      const resp = await fetchWithToken(
        `${ENDPOINTS.NOTIFICATION.GET.LIST}?${urlParams}`,
        {},
        "GET"
      );
      const body = await resp.json();
      let _page = page;
      if (body.success) {
        if (body?.data) {
          const { data } = body;
          const { notifications } = data;
          if (notifications && notifications.length > 0) {
            if (notifications.length < limit) {
              dispatch(setNotificationSetAllLoaded(true));
            }
            _page++;
            dispatch(setNotificationSetNextPage(_page));
            let _notifications = transformSendTypeToLanguage({
              list: notifications,
            });
            _notifications = transformCreationDate({
              list: _notifications,
            });
            dispatch(setNotificationGetList(_notifications));
          } else {
            if (sent) {
              dispatch(setNotificationSetStopLoadSent(true));
              dispatch(setNotificationSetStopLoadReceived(false));
            } else {
              dispatch(setNotificationSetStopLoadSent(false));
              dispatch(setNotificationSetStopLoadReceived(true));
            }
          }
        }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getNotificationFilteredList = (params) => {
  return async (dispatch) => {
    try {
      const {
        filters = null,
        attribute = "",
        search = "",
        limit = 50,
        page = "",
        sent = false,
        state = "",
      } = params;
      let newFilters = {
        type: filters?.type ? filters?.type : "",
        startDate: filters?.startDate ? filters?.startDate : "",
        endDate: filters?.endDate ? filters?.endDate : "",
        _state: filters?._state ? filters._state : "",
      };

      switch (attribute) {
        case FILTER_ATTRIBUTES.NOTIFICATION_TYPE:
          newFilters.type = search;
          break;
        case FILTER_ATTRIBUTES.NOTIFICATION_STATE:
          newFilters._state = search;
          break;
        case FILTER_ATTRIBUTES.NOTIFICATION_DATES:
          const { startDate, endDate } = search;
          newFilters.startDate = startDate;
          newFilters.endDate = endDate;
          break;
        default:
          break;
      }
      const { type, _state, startDate, endDate } = newFilters;
      let urlParams = `state=${state}`;
      if (_state !== "") {
        urlParams = `state=${_state}`;
      }

      urlParams = `${urlParams}&limit=${limit}&page=${page}&sent=${sent}&type=${type}&startDate=${startDate}&endDate=${endDate}`;

      const resp = await fetchWithToken(
        `${ENDPOINTS.NOTIFICATION.GET.LIST}?${urlParams}`,
        {},
        "GET"
      );
      const body = await resp.json();
      if (body.success) {
        if (body?.data) {
          const { data } = body;
          const { notifications } = data;
          let _notifications = transformSendTypeToLanguage({
            list: notifications,
          });
          _notifications = transformCreationDate({
            list: _notifications,
          });
          dispatch(setNotificationGetFilterList(_notifications));

          dispatch(
            setNotificationSetFilters({
              attribute,
              search,
            })
          );
          dispatch(setNotificationSetAllLoaded(true));
        }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};
export const getNotificationOne = (args) => {
  return async (dispatch) => {
    try {
      const { payload, isForRead = false } = args;
      const resp = await fetchWithToken(
        `${ENDPOINTS.NOTIFICATION.GET.ONE}/${payload}`,
        {},
        "GET"
      );
      const body = await resp.json();
      if (body.success) {
        if (body?.data) {
          const { data } = body;
          const { notification } = data;
          const notificationType = notification?.type ? notification?.type : "";
          dispatch(setNotificationSetSendType(notificationType));
          const _notification = transformCreationDate({ list: [notification] });
          dispatch(setNotificationGetOne(_notification[0]));
          if (!isForRead) {
            dispatch(
              getCustomerList({
                id: payload,
              })
            );
            dispatch(
              getUsersList({
                id: payload,
              })
            );
          }
        }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getNotificationPendingCount = (args) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken(
        `${ENDPOINTS.NOTIFICATION.GET.PENDING_COUNT}`,
        {},
        "GET"
      );
      const body = await resp.json();
      if (body.success) {
        if (body?.data) {
          const { data } = body;
          const { notification } = data;
          dispatch(setNotificationGetPendingCount(notification));
        }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};
export const createNotification = (args) => {
  return async (dispatch) => {
    try {
      const { payload } = args;
      const resp = await fetchWithToken(
        `${ENDPOINTS.NOTIFICATION.POST.CREATE}`,
        payload,
        "POST"
      );
      const body = await resp.json();
      if (body.success) {
        dispatch(setNotificationSend());
        // if (body?.data) {
        //     const { data } = body;
        // }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
      return body;
    } catch (error) {
      console.error(error);
    }
  };
};

export const updateNotificationState = (args) => {
  return async (dispatch) => {
    try {
      const { payload, notificationId } = args;
      const resp = await fetchWithToken(
        `${ENDPOINTS.NOTIFICATION.PATCH.UPDATE_STATE}/${notificationId}`,
        payload,
        "PATCH"
      );
      const body = await resp.json();
      if (body.success) {
        if (body?.data) {
          // const { data } = body;
        }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getCustomerList = (args) => {
  return async (dispatch) => {
    try {
      const { payload = {}, id } = args;
      const _endpoint = ENDPOINTS.NOTIFICATION.GET.CUSTOMERS_LIST;
      const endpointParams = [
        {
          name: ":id",
          value: id,
        },
      ];
      const endpoint = populateEndpointWithParams({
        endpoint: _endpoint,
        endpointParams,
      });

      const resp = await fetchWithToken(`${endpoint}`, payload, "GET");
      const body = await resp.json();
      if (body.success) {
        if (body?.data) {
          const { data } = body;
          dispatch(setNotificationSetClientsList(data));
        }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const getUsersList = (args) => {
  return async (dispatch) => {
    try {
      const { payload = {}, id, limit = 10000, page = 1 } = args;
      let _page = page;
      let endpoint = ENDPOINTS.NOTIFICATION.GET.USERS_LIST;
      const endpointParams = [
        {
          name: ":id",
          value: id,
        },
      ];
      endpoint = populateEndpointWithParams({ endpoint, endpointParams });
      const resp = await fetchWithToken(
        `${endpoint}?limit=${limit}&page=${_page}`,
        payload,
        "GET"
      );
      const body = await resp.json();
      if (body.success) {
        if (body?.data) {
          const { data } = body;
          dispatch(setNotificationSetUsersList(data.users));

          _page++;
          sessionStorage.setItem("nextPageUserReceiverList", _page);

          if (data.users.length < limit) {
            sessionStorage.setItem("allUserReceiverLoaded", true);
          } else {
            sessionStorage.setItem("allUserReceiverLoaded", false);
          }
        }
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    } catch (error) {
      console.error(error);
    }
  };
};
