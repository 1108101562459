/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 19/07/2022                                        
* @Description Product reducer                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { FILTER_ATTRIBUTES } from "../constants/filter";
import { types } from "../types/types"


const initialState = {
    products: [],
    filteredProducts: [],
    list: [],
    loadByClient: false,
    allProductsLoaded: false,
    nextPage: 1,
    isUsingFilter: false,
    selectedProducts: [],
    filters: {
        name: '',
        url: '',
        customer: ''
    },
    data: {}
}


export const productReducer = (state = initialState, action) => {
    const listFromState = state.list;
    const stateFilters = state.filters;
    const filteredProductsFromState = state.filteredProducts;

    switch (action.type) {
        case types.productDelete:
            return {
                ...state,
                list: deleteProduct(listFromState, action.payload)
            }

        case types.productFilter:
            const { attribute, search } = action.payload;

            let newFilters = {
                ...stateFilters
            }
            switch (attribute) {
                case FILTER_ATTRIBUTES.NAME:
                    newFilters.name = search;
                    break;
                case FILTER_ATTRIBUTES.URL:
                    newFilters.url = search;
                    break;
                case FILTER_ATTRIBUTES.CUSTOMER:
                    newFilters.customer = search;
                    break;
                default:
                    break;
            }
            return {
                ...state,
                filters: {
                    ...stateFilters,
                    ...newFilters
                },
                isUsingFilter: true
            }
        case types.productGetAll:
            return {
                ...state,
                products: action.payload,
                isUsingFilter: false
            }
        case types.productResetData:
            return {
                ...state,
                data: {}
            }
        case types.productSelectAll:
            return {
                ...state,
                list: switchCheckedForAllProducts(listFromState, true),
                filteredProducts: switchCheckedForAllProducts(filteredProductsFromState, true)
            }

        case types.productSelectOne:
            return {
                ...state,
                list: switchCheckedForOneProduct(listFromState, action.payload),
                filteredProducts: switchCheckedForOneProduct(filteredProductsFromState, action.payload)
            }

        case types.productSetAllLoaded:
            sessionStorage.setItem('allProductsLoaded', action.payload);
            return {
                ...state,
                allProductsLoaded: action.payload
            }
        case types.productSetFilteredData:

            return {
                ...state,
                filteredProducts: action.payload
            }
        case types.productSetNewList:
            return {
                ...state,
                list: action.payload
            }
        case types.productSetData:

            return {
                ...state,
                data: action.payload
            }
        case types.productSetList:
            const newList = [
                ...listFromState,
                ...action.payload
            ]
            return {
                ...state,
                list: newList
            }
        case types.productSetNextPage:
            sessionStorage.setItem('nextPageProducts', action.payload);
            return {
                ...state,
                nextPage: action.payload
            }
        case types.productSetSelected:
            const listToGetSelectedProducts = filteredProductsFromState && filteredProductsFromState.length > 0 ? filteredProductsFromState : listFromState;
            // const getSelectedProducts = getSelectedProductsId(listToGetSelectedProducts);
            // const _selectedProducts = [...new Set([...selectedProductsFromState, ...getSelectedProducts])];

            return {
                ...state,
                selectedProducts: getSelectedProductsId(listToGetSelectedProducts)
            }
        case types.productSetLoadByClient:
            return {
                ...state,
                loadByClient: action.payload
            }
        case types.productUnselectAll:
            return {
                ...state,
                list: switchCheckedForAllProducts(listFromState, false),
                filteredProducts: switchCheckedForAllProducts(filteredProductsFromState, false)
            }
        case types.productUnselectOne:
            return {
                ...state,
                list: switchCheckedForOneProduct(listFromState, action.payload),
                filteredProducts: switchCheckedForOneProduct(filteredProductsFromState, action.payload)
            }
        default:
            return state;
    }
}

const deleteProduct = (list, productId) => {
    const _list = list.filter((item) => item.id !== productId);
    return _list;
}
const getSelectedProductsId = (list) => {
    let selectedProducts = list.filter((item) => item.checked);
    if (selectedProducts.length > 0) {
        selectedProducts = selectedProducts.map((item) => item.id);
    }

    return selectedProducts;
}

const switchCheckedForAllProducts = (list, checked) => {
    const _list = list.map((item) => {
        return {
            ...item,
            checked
        }
    })
    return _list;
}
const switchCheckedForOneProduct = (list, id) => {
    const _list = list.map((item) => {
        let checked = item.checked;
        if (item.id === id) {
            checked = !checked;
        }
        return {
            ...item,
            checked
        }
    })
    return _list;
}
