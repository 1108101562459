/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 15/09/2022                                        
* @Description Reporting table                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { useEffect, useRef } from 'react';
import { Button, Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
    getListByFilterWithPagination,
    setReportingCloseAllDatePickers,
    setReportingShowDatePickerForEndDate,
    setReportingShowDatePickerForStartDate
} from '../../actions/reporting';
import { getListByFilter, getListWithPagination } from '../../actions/reporting';
import { CalendarPicker } from '../../components/Form/CalendarPicker';
import { SHOW_DATE_PICKER } from '../../constants/datePicker';
import { DATES_VARIABLES, FILTER_ATTRIBUTES, ORDER_TYPE } from '../../constants/filter';
import { useAuth } from '../../context/AuthContext';
import moment from 'moment';
import ENDPOINTS from '../../actions/endpoints';
import { DICTIONARY } from '../../constants/dictionary';
import { translate } from '../../utils/translate';

const IndexReporting = () => {
    const { current: { dictionary } } = useSelector((state) => state.translation);
    const limit = 50;
    const dispatch = useDispatch();
    const { state: stateAuth } = useAuth();

    const donwloadLink = useRef(null);
    const {
        list,
        filteredList,
        isUsingFilter,
        // selectedProducts,
        filters,
        // filteredProducts
        datePicker
    } = useSelector((state) => state.reporting);

    const { showForStartDate, showForEndDate } = datePicker;
    const listData = (filteredList && filteredList.length > 0) || isUsingFilter ? filteredList : list;
    const { startDateOrder, endDateOrder, startDate, endDate, customer, username } = filters;
    let timer = null;

    const handleShowDatePicker = (datePicker) => {
        switch (datePicker) {
            case SHOW_DATE_PICKER.REPORTING.END_DATE:
                dispatch(setReportingShowDatePickerForEndDate(!showForEndDate));
                break;
            case SHOW_DATE_PICKER.REPORTING.START_DATE:
                dispatch(setReportingShowDatePickerForStartDate(!showForStartDate));
                break;
            default:
                break;
        }
    }
    const handleExport = () => {
        // const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
        // const parsedToken = JSON.parse(sessionStorage.getItem('ideauto_u'));
        // let _token = '';
        // if (parsedToken) {
        //     _token = parsedToken.token;
        // }
        // _token = _token ? _token : null;
        // if (!_token) {
        //     _token = getTokenFromUrl();
        // }
        // let url = `${apiURL}/${ENDPOINTS.REPORTING.GET.LIST}?token=${_token}&startDate=${startDate}&endDate=${endDate}&startDateOrder=${startDateOrder}&endDateOrder=${endDateOrder}&customer=${customer}&username=${username}`;
        // let url = `${apiURL}/${ENDPOINTS.REPORTING.GET.LIST}`;
        // url = `${url}?token=${_token}&startDate=${startDate}&endDate=${endDate}`;
        // url = `${url}&startDateOrder=${startDateOrder}&endDateOrder=${endDateOrder}`;
        // url = `${url}&customer=${customer}&username=${username}`;

        donwloadLink.current.click();
    }
    const handleFocusFilter = () => {

        dispatch(setReportingCloseAllDatePickers());
    }
    const handleFilter = (attribute, search) => {
        sessionStorage.setItem('nextPageReporting', 1);
        const payload = {
            filters,
            attribute,
            limit,
            search
        }
        const _FILTER_ATTRIBUTES = FILTER_ATTRIBUTES.REPORTING;
        switch (attribute) {
            case _FILTER_ATTRIBUTES.STARTDATE:
                payload.search = `${search}${DATES_VARIABLES.fromHour}`;
                break;
            case _FILTER_ATTRIBUTES.ENDDATE:
                payload.search = `${search}${DATES_VARIABLES.endHour}`;
                break;
            default:
                break;
        }
        const delayTime = 500;
        clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(getListByFilter(payload));
        }, delayTime);
    }

    const nextPage = sessionStorage.getItem('nextPageReporting');
    const handleScroll = () => {
        const allReportingLoaded = sessionStorage.getItem('allItemsLoaded');
        const actualScroll = window.innerHeight + window.scrollY + 400;
        if (!!allReportingLoaded && actualScroll >= document.body.offsetHeight) {
            if (!isUsingFilter) {
                const productPaginationObj = {
                    token: stateAuth.token,
                    limit,
                    page: nextPage
                }
                dispatch(getListWithPagination(productPaginationObj));
            } else {
                if (filters) {
                    dispatch(getListByFilterWithPagination({
                        filters,
                        limit,
                        page: nextPage
                    }));

                }
            }
            window.removeEventListener('scroll', handleScroll);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [nextPage, filters]);

    useEffect(() => {
        // getAllRoles(dispatchRole, stateAuth.token);
        if (!isUsingFilter) {
            sessionStorage.setItem('allItemsLoaded', false);
            sessionStorage.setItem('nextPageReporting', 1);
            if (list && list.length === 0) {
                const payloadObject = {
                    token: stateAuth.token,
                    limit,
                    page: 1
                }
                dispatch(getListWithPagination(payloadObject));
            }
        }
    }, [isUsingFilter]);


    const _iconFileDownload = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/img/default/file_download_white_24dp.svg`;
    const arrowDown = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_down_black_24dp.svg`;
    const arrowUp = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/default/arrow_drop_up_black_24dp.svg`;

    const startDateLabel = startDate !== '' ? moment(startDate).format(DATES_VARIABLES.spanishDateFormat) : translate(DICTIONARY.PLACEHOLDER.START_DATE, dictionary);
    const endDateLabel = endDate !== '' ? moment(endDate).subtract(1, 'day').format(DATES_VARIABLES.spanishDateFormat) : translate(DICTIONARY.PLACEHOLDER.END_DATE, dictionary);
    return (
        <Container
            fluid
        >
            <div
                style={{ display: 'none' }}
            >
                <a
                    ref={donwloadLink}
                    href={`${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}/${ENDPOINTS.REPORTING.GET.DOWNLOAD}?token=${stateAuth.token}&startDate=${startDate}&endDate=${endDate}&startDateOrder=${startDateOrder}&endDateOrder=${endDateOrder}&customer=${customer}&username=${username}`} download
                />
            </div>
            <div className="row mb-5">
                <div className="col-xl-4 col-lg-4 col-md-4 col-xs-5">
                    <div className='section-name'>
                        <div className='section-icon__img '>
                            <div className='section-name'>
                                <span className="material-icons-round section-icon">
                                    bar_chart
                                </span>
                                <div>{translate(DICTIONARY.TITLE.REPORTING_INDEX, dictionary)}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-2 col-md-2 col-xs-1"></div>
                <div className="col-xl-4 col-lg-6 col-md-6 col-xs-6 ">
                    <Button
                        variant="secondary"
                        onClick={handleExport}
                        className="button-export float-end ideauto-button ">
                        <div className="button-icon-container">
                            <div className="button-icon-container-image">
                                <img
                                    className='product-icon product-icon__tiny'
                                    src={_iconFileDownload}
                                    alt="download"
                                />
                            </div>
                            <div>{translate(DICTIONARY.BUTTON.EXPORT, dictionary)}</div>
                        </div>
                    </Button>
                </div>
            </div>
            <Row>
                <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    xxl="12"
                >
                    {
                        !list &&
                        <>
                            <div className="spinner">
                                <Spinner animation="border" role="status"></Spinner>
                            </div>
                            <div className="spinner">
                                <div className="spinner-message">{translate(DICTIONARY.LABEL.REPORTING_LOADING, dictionary)}</div>

                            </div>
                        </>
                    }
                    {
                        listData &&
                        <Table borderless responsive className="table-launcher">
                            <thead>
                                <tr>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <div className="filter-box tableHeader-filterAndOrder">
                                                    <div
                                                        className={`input-selectedOption form-control form-control__borderRadius ${showForStartDate ? 'input-select-optionsShow' : ''}`}
                                                        onClick={() => handleShowDatePicker(SHOW_DATE_PICKER.REPORTING.START_DATE)}
                                                    >
                                                        <div
                                                            className='input-selectedOption-value calendarPicker-selectedValue'
                                                            title={startDateLabel}
                                                        >
                                                            {
                                                                startDateLabel
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tableHeader-order"
                                                        onClick={
                                                            () => handleFilter(
                                                                FILTER_ATTRIBUTES.REPORTING.STARTDATEORDER,
                                                                startDateOrder === ORDER_TYPE.DESCENDENT ? ORDER_TYPE.ASCENDANT : ORDER_TYPE.DESCENDENT
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className="arrowUp"
                                                        >
                                                            <img
                                                                src={arrowUp}
                                                                alt="arrowUp"
                                                                className={startDateOrder === ORDER_TYPE.DESCENDENT ? 'arrowNotUsed' : ''}
                                                            />
                                                        </div>
                                                        <div
                                                            className="arrowDown"
                                                        // onClick={() => handleFilter(FILTER_ATTRIBUTES.REPORTING.STARTDATEORDER, ORDER_TYPE.DESCENDENT)}
                                                        >
                                                            <img
                                                                src={arrowDown}
                                                                alt="arrowDown"

                                                                className={startDateOrder === ORDER_TYPE.ASCENDANT ? 'arrowNotUsed' : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            showForStartDate &&
                                            <CalendarPicker
                                                callBackFilter={handleFilter}
                                                callBackToggleCalendar={() => handleShowDatePicker(SHOW_DATE_PICKER.REPORTING.START_DATE)}
                                                attribute={FILTER_ATTRIBUTES.REPORTING.STARTDATE}
                                                show={showForStartDate}
                                                extraClass="calendarPicker-column__first"
                                            />
                                        }
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <div className="filter-box tableHeader-filterAndOrder">
                                                    <div
                                                        className={`input-selectedOption form-control form-control__borderRadius ${showForEndDate ? 'input-select-optionsShow' : ''}`}
                                                        onClick={() => handleShowDatePicker(SHOW_DATE_PICKER.REPORTING.END_DATE)}
                                                    >
                                                        <div
                                                            className='input-selectedOption-value calendarPicker-selectedValue'
                                                            title={endDateLabel}
                                                        >
                                                            {
                                                                endDateLabel
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="tableHeader-order"
                                                        onClick={
                                                            () => handleFilter(
                                                                FILTER_ATTRIBUTES.REPORTING.ENDDATEORDER,
                                                                endDateOrder === ORDER_TYPE.DESCENDENT ? ORDER_TYPE.ASCENDANT : ORDER_TYPE.DESCENDENT
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className="arrowUp"
                                                        >
                                                            <img
                                                                src={arrowUp}
                                                                alt="arrowUp"
                                                                className={endDateOrder === ORDER_TYPE.DESCENDENT ? 'arrowNotUsed' : ''}
                                                            />
                                                        </div>
                                                        <div
                                                            className="arrowDown"
                                                        // onClick={() => handleFilter(FILTER_ATTRIBUTES.REPORTING.STARTDATEORDER, ORDER_TYPE.DESCENDENT)}
                                                        >
                                                            <img
                                                                src={arrowDown}
                                                                alt="arrowDown"

                                                                className={endDateOrder === ORDER_TYPE.ASCENDANT ? 'arrowNotUsed' : ''}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            showForEndDate &&
                                            <CalendarPicker
                                                callBackFilter={handleFilter}
                                                callBackToggleCalendar={() => handleShowDatePicker(SHOW_DATE_PICKER.REPORTING.END_DATE)}
                                                attribute={FILTER_ATTRIBUTES.REPORTING.ENDDATE}
                                                show={showForEndDate}
                                                extraClass="calendarPicker-column__first"
                                            />
                                        }
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <Form.Control
                                                    type="text"
                                                    placeholder={translate(DICTIONARY.PLACEHOLDER.CLIENT, dictionary)}
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.REPORTING.CUSTOMER, ev.target.value)}
                                                    defaultValue={filters?.customer && filters?.customer !== '' ? filters?.customer : ''}
                                                    onFocus={() => handleFocusFilter()}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <Form.Control
                                                    type="text"
                                                    placeholder={translate(DICTIONARY.PLACEHOLDER.USER, dictionary)}
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.REPORTING.USERNAME, ev.target.value)}
                                                    defaultValue={filters?.username && filters?.username !== '' ? filters?.username : ''}
                                                    onFocus={() => handleFocusFilter()}
                                                />
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <div>{translate(DICTIONARY.LABEL.ACCESS_OR_PRODUCT, dictionary)}</div>
                                                {/* <Form.Control
                                                    type="text"
                                                    placeholder="Acceso o Producto"
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.REPORTING.PRODUCT_NAME, ev.target.value)}
                                                    defaultValue={filters?.customer && filters?.customer !== '' ? filters?.customer : ''}
                                                /> */}
                                            </div>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="tableHeader-filter">
                                            <div className="tableHeader-filter__input">
                                                <div>{translate(DICTIONARY.LABEL.LOCATION, dictionary)}</div>
                                                {/* <Form.Control
                                                    type="text"
                                                    placeholder="Localización"
                                                    onInput={(ev) => handleFilter(FILTER_ATTRIBUTES.REPORTING.CUSTOMER, ev.target.value)}
                                                    defaultValue={filters?.customer && filters?.customer !== '' ? filters?.customer : ''}
                                                /> */}
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    listData && listData.length > 0 && listData.map((item, index) => {
                                        // const { checked, id, logo, name, url, customers } = item;
                                        // const tokenFromSession = JSON.parse(sessionStorage.getItem('ideauto_u'));
                                        // const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
                                        // const logoUrl = logo ?
                                        //     `${apiURL}/products/uploads/photo/${id}?token=${tokenFromSession.token}` :
                                        //     ``;

                                        const {
                                            id,
                                            customer,
                                            username,
                                            productName,
                                            ip,
                                            country,
                                            formatedStartDate,
                                            formatedEndDate } = item;

                                        const ipAndCountry = `${ip} (${country})`;

                                        return (
                                            <tr
                                                key={`${id}_${index}`}
                                            >
                                                <td>
                                                    {formatedStartDate}h
                                                </td>
                                                <td>
                                                    {formatedEndDate}h
                                                </td>
                                                <td

                                                    title={customer}
                                                    className="ellipsis"
                                                >
                                                    {customer}
                                                </td>
                                                <td>
                                                    {username}
                                                </td>
                                                <td>
                                                    {productName}
                                                </td>
                                                <td>
                                                    {ipAndCountry}
                                                </td>
                                            </tr>

                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default IndexReporting;