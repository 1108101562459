/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/01/2022                                        
* @Description Product context for manage products                                                           
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { createContext, useReducer } from 'react';
import ROLES from '../constants/roles';
import { fetchWithToken } from '../helpers/fetch';
import { getFromSession } from '../helpers/session';
import { types } from '../types/types';
import { redirectToNoAuthorizedURL } from '../utils/redirectToNoAuthorizedURL';
import { usePagination } from './PaginationContext';

const sessionKey = 'products';
const ProductCrudContext = createContext([]);

const initialState = {
    products: [],
    productsList: [],
    loadByClient: false
}

const productCrudReducer = (state = initialState, action) => {

    // const stateRoles = state.roles;
    switch (action.type) {
        case types.productSetList:
            return {
                ...state,
                productsList: action.payload
            }
        case types.productGetAll:
            return {
                ...state,
                products: action.payload
            }
        case types.productSetLoadByClient:
            return {
                ...state,
                loadByClient: action.payload
            }
        default:
            return state;
    }
}

const setProductsList = (payload) => ({
    type: types.productSetList,
    payload
})
const setAllProducts = (products) => ({
    type: types.productGetAll,
    payload: products
});

const setLoadByClient = (payload) => ({
    type: types.productSetLoadByClient,
    payload
})

const getAllProducts = async (dispatch, query = '', limit = '100', page = '1') => {
    try {
        const valuesFromSession = getFromSession(sessionKey);

        if (valuesFromSession) {
            dispatch(setAllProducts(valuesFromSession));
        } else {
            const resp = await fetchWithToken(
                `products?name=&customerName=&url=&limit=${limit}&page=${page}`,
                {},
                'GET'
            );
            const body = await resp.json();
            if (body.success) {
                const { data: { products } } = body;

                sessionStorage.setItem('products', JSON.stringify(products))
                dispatch(setAllProducts(products));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        }

    } catch (error) {
        console.error(error);
    }
}


const ProductCrudProvider = ({ children }) => {
    const [state, dispatch] = useReducer(productCrudReducer, []);
    const { setNextPageProducts, setAllProductsLoaded } = usePagination();


    const getAllProductsWithParams = async (token, limit = '', page = '1') => {
        try {
            const resp = await fetchWithToken(
                `products?name=&customerName=&url=&limit=${limit}&page=${page}`,
                {},
                'GET',
                token
            );
            const body = await resp.json();
            if (body.success) {
                const { data: { count, products } } = body;
                return {
                    count,
                    products
                }
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }

    }
    const getProductsWithPagination = async (params) => {
        try {
            const { token, limit, page: _page, userRole } = params;
            // let clients = stateClient?.clients ? stateClient.clients : [];
            let getClients = false;
            let page = _page;
            if (userRole !== '' && userRole === ROLES.ADMIN) {
                // clients = await getAllClients(token);
                getClients = true;
            }

            let allProducts = [];
            const productsWithParams = await getAllProductsWithParams(token, limit, page);
            const { products } = productsWithParams;
            if (products && products.length > 0) {
                const _products = products.map((prod) => {
                    let productObj = {
                        ...prod,
                        checked: false
                    }
                    if (getClients) {
                        productObj = {
                            ...prod,
                            checked: false
                            // customerName: getClientNameById(clients, user.customerId)
                        }
                    }
                    return productObj;
                });

                if (_products.length < limit) {
                    setAllProductsLoaded();
                }
                allProducts = [
                    ...allProducts,
                    ..._products
                ];
                page++;
                setNextPageProducts(page);
            }

            if (allProducts) {
                dispatch(setProductsList(allProducts));
                // dispatch(setUserPagination(page));
            }

        } catch (error) {
            console.error(error);
        }
    }

    const value = { state, dispatch, getAllProducts, setLoadByClient, getProductsWithPagination };
    return <ProductCrudContext.Provider value={value}>{children}</ProductCrudContext.Provider>;
}




export { ProductCrudProvider, ProductCrudContext }