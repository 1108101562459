import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { Container, Col, Image, Form, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useForm } from "../../hooks/useForm";
import Swal from "sweetalert2";
import COLORS from "../../constants/colors";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import { InputSelect } from "../../components/Form/InputSelect";
import { INPUT_SELECTS } from "../../constants/inputSelects";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllClients,
  switchClientMenu,
} from "../../actions/inputSelectActions";
import { createProduct, uploadProductImage } from "../../actions/product";
import { translate } from "../../utils/translate";
import { DICTIONARY } from "../../constants/dictionary";

const initialStateUser = {
  email: "",
  password: "",
  password2: "",
  firstName: "",
  lastName: "",
  role: "",
  customerId: "",
};
const CreateProduct = () => {
  const paramsForLoaded = "&_s=841110&_l=1";

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);

  const [photo, setPhoto] = useState({});
  const [srcImage, setSrcImage] = useState("");
  const [formUserValues, handleUserInputChange] = useForm(initialStateUser);
  const {
    state: { token },
  } = useAuth();
  const { clients } = useSelector((state) => state.inputSelect);
  const inputPhoto = useRef(null);
  const [isSwitchOnHasMobileVersion, setIsSwitchOnHasMobileVersion] =
    useState(false);

  const onSwitchActionHasMobileVersion = () => {
    setIsSwitchOnHasMobileVersion(!isSwitchOnHasMobileVersion);
  };
  const handleInputPhoto = (ev) => {
    inputPhoto.current.click();
  };

  const handleChangeFile = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSrcImage(imageUrl);
    setPhoto(file);
  };
  const handleCreateProduct = async (ev) => {
    ev.preventDefault();
    handleCloseMenu();
    const _customers = clients.options.filter((item) => item.checked);
    let customers = [];
    if (_customers.length > 0) {
      customers = _customers.map((item) => item.id);
    }
    const { name, url } = formUserValues;

    const payload = {
      name,
      url,
      customers,
      isAvailableMobile: isSwitchOnHasMobileVersion,
    };
    const response = await dispatch(createProduct(payload));
    if (response.success) {
      const {
        data: { product },
      } = response;
      const { id } = product;
      await uploadProductImage(id, token, photo);
      Swal.fire({
        ...sweetAlertProps,
        text: translate(DICTIONARY.MESSAGE.PRODUCT_CREATE, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        if (sweet.isConfirmed) {
          window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/product?_i=${token}${paramsForLoaded}`;
          // navigate(`/product?_i=${token}${paramsForLoaded}`, { replace: false });
        }
      });
    } else {
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${response?.message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {});
    }
  };

  const handleCloseMenu = () => {
    if (clients.isOpen) {
      dispatch(switchClientMenu(!clients.isOpen));
    }
  };
  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  const _icon = `${process.env.REACT_APP_BROWSER_ROUTE}/assets/img/default/apps_black_24dp.svg`;

  return (
    <>
      <Container fluid>
        <Row>
          <Col>
            <div className="section-name">
              <div className="section-icon__img ">
                <img
                  className="product-icon product-icon__tiny"
                  src={_icon}
                  alt={`Productos`}
                />
              </div>
              <div>
                {translate(DICTIONARY.TITLE.PRODUCT_CREATE, dictionary)}
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="section-content mt-3 ml-2">
            <div className="subsection-name">
              <div>{translate(DICTIONARY.TITLE.PRODUCT_DATA, dictionary)}</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form className="mt-3" onSubmit={handleCreateProduct}>
              <Row className="user-row">
                <Col xs="12" sm="5" md="4" lg="3" xl="3" xxl="3" className="">
                  <div className="avatar">
                    <div className="avatar-image">
                      {srcImage && (
                        <Image src={srcImage} className="client-image" />
                      )}
                    </div>
                    <div className="avatar-button">
                      <Form.Control
                        ref={inputPhoto}
                        style={{ display: "none" }}
                        type="file"
                        onChange={handleChangeFile}
                      />
                      <Button
                        variant="secondary"
                        className="avatar-change-button"
                        onClick={handleInputPhoto}
                      >
                        {translate(
                          DICTIONARY.BUTTON.PRODUCT_ADD_ICON,
                          dictionary
                        )}
                      </Button>
                    </div>
                  </div>
                </Col>
                <Col xs="12" sm="7" md="8" lg="9" xl="9" xxl="9">
                  <Row className="row-separation">
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Check
                        type="switch"
                        onChange={onSwitchActionHasMobileVersion}
                        id="switch-allowAccessMobileApp"
                        label={translate(
                          DICTIONARY.LABEL.PRODUCT_HAS_MOBILE_VERSION,
                          dictionary
                        )}
                        checked={isSwitchOnHasMobileVersion}
                        name="allowMobileAccess"
                        value={true}
                        className="input-switch"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <InputSelect
                        label=""
                        options={clients?.options}
                        inputId="customer"
                        filterType={INPUT_SELECTS.CLIENTS}
                        inputName="customer"
                        className="filter__xl"
                        inputType="checkbox"
                        visibleSelectedValue={
                          clients.selectedLabel === ""
                            ? `${translate(
                                DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
                                dictionary
                              )} *`
                            : clients.selectedLabel
                        }
                        hasSelectAll={true}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Form.Control
                        type="text"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.PRODUCT_NAME,
                          dictionary
                        )} *`}
                        className=""
                        name="name"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`${translate(
                          DICTIONARY.PLACEHOLDER.PRODUCT_URL,
                          dictionary
                        )} *`}
                        className=""
                        name="url"
                        onInput={handleUserInputChange}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row></Row>
              <Button
                variant="secondary"
                type="submit"
                className="w-25 float-end button-form"
              >
                {translate(DICTIONARY.BUTTON.PRODUCT_CREATE, dictionary)}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateProduct;
