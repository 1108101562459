/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 24/08/2022
 * @Description List of endpoints used in ideauto
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

const ENDPOINTS = {
  CONTACT: {
    DELETE: {
      ONE: "contacts/customers", //Eliminar el contacto asociado a un cliente
    },
    GET: {
      ONE: "contacts/customers", //Recuperar el contacto asociado a un cliente
      DEFAULT: "contacts/template", //Recuperar el contacto por defecto
    },
    POST: {
      CREATE: "contacts/template", //Insertar contacto por defecto
      OVERWRITE_DEFAULT: "contacts/customers", //Guardar contacto asociado a un cliente (si tiene permisos, los sobreescribe)
    },
  },
  NOTIFICATION: {
    GET: {
      LIST: "notifications", //Recuperar el listado de notificaciones
      ONE: "notifications", //Recuperar una notificación
      PENDING_COUNT: "notifications/pending/number", //Recuperar el número de notificaciones pendientes
      CUSTOMERS_LIST: "notifications/:id/customers", //Recuperar los clientes de una notificación
      USERS_LIST: "notifications/:id/users", //Recuperar los usuarios de una notificación
    },
    PATCH: {
      UPDATE_STATE: "notifications/users/state", //Actualizar el estado de la notificación como usuario
    },
    POST: {
      CREATE: "notifications", //Enviar una notificación
    },
  },
  PARAMETERS_ACCESS: {
    GET: {
      ACCESSS_PRODUCTS: "products/parameters/access",
    },
  },
  PRODUCT: {
    PATCH: {
      MAINTENANCE: "products/maintenance",
      UPDATE_AVAILABLE_MOBILE: "products/availability/mobile/:id",
    },
  },
  // api/v1/reporting?token=&startDate=&endDate=&startDateOrder=&endDateOrder=&customer=&username=
  REPORTING: {
    GET: {
      LIST: "reporting",
      DOWNLOAD: "reporting/download",
    },
  },
  TRANSLATION: {
    POST: {
      LIST: "translations",
    },
  },
  USER: {
    GET: {
      PROFILE: "users/:id",
    },
  },
};

export default ENDPOINTS;
