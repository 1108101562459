/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 21/01/2022
 * @Description Helper for fetch data (get/post/patch/delete)
 * ----------------------------------------------------------------------------------------
 * @Inputs fetch
 *  - @param options: Object with different options
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import { SESSION_KEYS } from "../constants/session";
import getTokenFromUrl from "../utils/getTokenFromUrl";
import hasParamIsFromMobile from "../utils/hasParamIsFromMobile";

const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
// const apiURL = proces;

const fetchSimple = (endPoint, data = {}, method = "GET") => {
  const url = `${apiURL}/${endPoint}`;
  const languageCode = sessionStorage.getItem(SESSION_KEYS.USER.LANGUAGE_CODE);

  if (method === "GET") {
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        language: languageCode,
        isMobileApp: hasParamIsFromMobile(),
      },
      body: JSON.stringify(data),
    });
  }
};

const fetchWithToken = (endPoint, data, method = "GET", token) => {
  const url = `${apiURL}/${endPoint}`;
  const languageCode = sessionStorage.getItem(SESSION_KEYS.USER.LANGUAGE_CODE);
  const parsedToken = JSON.parse(sessionStorage.getItem("ideauto_u"));
  let _token = token;
  if (parsedToken) {
    _token = parsedToken.token;
  }
  _token = _token ? _token : null;
  if (!_token) {
    _token = getTokenFromUrl();
  }

  const headers = {
    "Content-type": "application/json",
    token: _token,
    language: languageCode,
    isMobileApp: hasParamIsFromMobile(),
  };
  const formData = new URLSearchParams();
  if (method === "POST" && data) {
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
  }

  if (method === "GET") {
    return fetch(url, {
      method,
      headers: headers,
    });
  } else {
    return fetch(url, {
      method,
      headers: headers,
      body: JSON.stringify(data),
    });
  }
};

const uploadImage = (endPoint, file, token) => {
  const url = `${apiURL}/${endPoint}`;

  const parsedToken = JSON.parse(sessionStorage.getItem("ideauto_u"));
  let _token = token ? token : parsedToken.token;
  _token = _token ? _token : null;
  const headers = {
    token: `${_token}`,
    isMobileApp: hasParamIsFromMobile(),
  };

  let formData = new FormData();
  formData.append("image", file);

  return fetch(url, {
    method: "POST",
    headers: headers,
    body: formData,
  });
};

const getImage = (endPoint, token) => {
  const parsedToken = JSON.parse(sessionStorage.getItem("ideauto_u"));
  let _token = token ? token : parsedToken.token;
  _token = _token ? _token : null;

  const url = `${apiURL}/${endPoint}?token=${_token}`;

  const headers = {
    "Content-type": "image",
    isMobileApp: hasParamIsFromMobile(),
  };

  return fetch(url, { mehtod: "GET", headers });
};

export { fetchSimple, fetchWithToken, uploadImage, getImage };
