/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/07/2022                                        
* @Description Actions for user                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { MODAL_TYPES } from "../constants/modals";
import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import { setMessage } from "./modal";

const ENDPOINTS = {
    POST: {
        ACTIVATE: 'users/active'
    }
}

export const resetCopyProfileData = () => ({
    type: types.userResetCopyProfileData
})

export const setCopyProfileData = (payload) => ({
    type: types.userSetCopyProfileData,
    payload
});
export const activeDeactiveUser = (params) => {
    return async (dispatch) => {
        try {
            const {
                id,
                payload,
                succesMessage,
                errorMessage
            } = params;
            const resp = await fetchWithToken(
                `${ENDPOINTS.POST.ACTIVATE}/${id}`,
                payload,
                'PATCH'
            );
            const body = await resp.json();

            if (body.success) {
                dispatch(setMessage({
                    id: MODAL_TYPES.ACTIVATE_USERS,
                    message: succesMessage,
                    icon: 'success'
                }));
            } else {
                dispatch(setMessage({
                    id: MODAL_TYPES.ACTIVATE_USERS,
                    message: errorMessage,
                    icon: 'error'
                }));
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }

    }
}

// export const 