import React, { useEffect, useRef } from "react";
import { useContext } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { Container, Row, Col, Image, Form, Button } from "react-bootstrap";
import { ProductAvailableList } from "../../components/UI/ProductAvailableList/ProductAvailableList";
import { useAuth } from "../../context/AuthContext";
import { ClientContext } from "../../context/ClientContext";
import { useColor } from "../../context/ColorContext";
import { ProductContext } from "../../context/ProductContext";
import { useForm } from "../../hooks/useForm";
import { useNavigate } from "react-router-dom";
import { SketchPicker } from "react-color";
import COLORS from "../../constants/colors";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import { getDefaultContact } from "../../actions/contact";
import { useDispatch, useSelector } from "react-redux";
import ROLES from "../../constants/roles";
import { translate } from "../../utils/translate";
import { DICTIONARY } from "../../constants/dictionary";

const initialStateClient = {
  name: "",
  colorId: "",
  initialProductId: "",
  email: "",
  phone: "",
  address: "",
};
const CreateClients = () => {
  const paramsForLoaded = "&_s=841110&_l=1";
  const dispatch = useDispatch();
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);

  const inputPhoto = useRef(null);
  const [photo, setPhoto] = useState({});
  const [srcImage, setSrcImage] = useState("");
  const userRole = sessionStorage.getItem("userRole")
    ? sessionStorage.getItem("userRole")
    : "";
  let isAdmin = false;
  if (userRole !== "") {
    isAdmin = userRole === ROLES.ADMIN;
  }

  const handleInputPhoto = (ev) => {
    inputPhoto.current.click();
  };
  const handleChangeFile = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSrcImage(imageUrl);
    setPhoto(file);
  };

  const [formClientValues, handleClientInputChange] =
    useForm(initialStateClient);

  const { getAllProducts, dispatch: dispatchProduct } =
    useContext(ProductContext);
  const { dispatch: dispatchColor } = useColor();
  const {
    state: { token },
  } = useAuth();
  const {
    createClient,
    state: stateClient,
    uploadClientImage,
  } = useContext(ClientContext);
  const { selectedProducts } = stateClient;

  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const [colorHex, setColorHex] = useState("");
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [isSwitchOnHasMobileApp, setIsSwitchOnHasMobileApp] = useState(false);

  const onSwitchActionHasMobileApp = () => {
    setIsSwitchOnHasMobileApp(!isSwitchOnHasMobileApp);
  };
  let navigate = useNavigate();
  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleCreateClient = async (ev) => {
    ev.preventDefault();
    const { name, initialProductId, id } = formClientValues;
    const data = {
      id,
      name,
      colorHex: colorHex.hex,
      initialProductId:
        selectedProducts && selectedProducts.length > 0
          ? initialProductId
          : null,
      products: selectedProducts
        ? selectedProducts.map((sProduct) => {
            return sProduct.id;
          })
        : [],
      redirectToOld: isSwitchOn,
      allowMobileAccess: isSwitchOnHasMobileApp,
    };
    const response = await createClient(data, token);
    // const {
    //     contactName,
    //     email,
    //     phone
    // } = formClientValues;

    // const payloadContact = {
    //     name: contactName ? contactName : contact.name,
    //     email: email ? email : contact.email,
    //     phone: phone ? phone : contact.phone
    // }
    // const responseContact = await dispatch(overwriteDefaultContact({ payload: payloadContact }))

    if (response.success) {
      const {
        data: { customer },
      } = response;
      const { id } = customer;
      await uploadClientImage(id, token, photo);
      Swal.fire({
        ...sweetAlertProps,
        text: translate(DICTIONARY.MESSAGE.CLIENT_CREATE, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        if (sweet.isConfirmed) {
          window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/client?_i=${token}${paramsForLoaded}`;
          // navigate('/client', { replace: false });
        }
      });
    } else {
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${response?.message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        // if (sweet.isConfirmed) {
        //     navigate('/user', { replace: true });
        // }
      });
    }
  };

  useEffect(() => {
    getAllProducts(dispatchProduct);
  }, [dispatchProduct, token, dispatchColor, getAllProducts]);
  useEffect(() => {
    dispatch(getDefaultContact());
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="section-name">
            <span className="material-icons-round section-icon">business</span>
            <div>{translate(DICTIONARY.TITLE.CLIENT_CREATE, dictionary)}</div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section-content mt-3 ml-2">
          <div className="subsection-nam">
            <div className="title">
              <div>{translate(DICTIONARY.TITLE.CLIENT_DATA, dictionary)}</div>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form className="mt-3" onSubmit={handleCreateClient}>
            <Row className="user-row">
              <Col xs="12" sm="5" md="4" lg="3" xl="3" xxl="3" className="">
                <div className="avatar">
                  <div className="avatar-image">
                    {srcImage && (
                      <Image className="client-image" src={srcImage} />
                    )}
                  </div>
                  <div className="avatar-button">
                    <Form.Control
                      ref={inputPhoto}
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleChangeFile}
                    />
                    <Button
                      variant="secondary"
                      className="avatar-change-button"
                      onClick={handleInputPhoto}
                    >
                      {translate(
                        DICTIONARY.BUTTON.CLIENT_CHANGE_LOGO,
                        dictionary
                      )}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col
                xs="12"
                sm="7"
                md="8"
                lg="9"
                xl="9"
                xxl="9"
                className="offset-controls-end"
              >
                {isAdmin && (
                  <>
                    <Row>
                      <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                        <Form.Check
                          type="switch"
                          onChange={onSwitchActionHasMobileApp}
                          id="switch-allowAccessMobileApp"
                          label={translate(
                            DICTIONARY.LABEL.CLIENT_HAS_ACCESS_MOBILE_APP,
                            dictionary
                          )}
                          checked={isSwitchOnHasMobileApp}
                          name="allowMobileAccess"
                          value={true}
                          className="input-switch"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                        <Form.Check
                          type="switch"
                          onChange={onSwitchAction}
                          id="switch-allowEmailNotification"
                          label={translate(
                            DICTIONARY.LABEL.CLIENT_REDIRECT_OLD_PAGE,
                            dictionary
                          )}
                          checked={isSwitchOn}
                          name="redirect"
                          value={true}
                          className="input-switch"
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Control
                      type="text"
                      placeholder="ID *"
                      name="id"
                      onChange={handleClientInputChange}
                      onClick={() => setShowColorPicker(false)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <div
                      className="colorPicker-button form-control div-asInput"
                      onClick={() => setShowColorPicker((_state) => !_state)}
                    >
                      {colorHex ? (
                        <div className="colorPicker-preview">
                          <div
                            className="colorPicker-square"
                            style={{ backgroundColor: colorHex.hex }}
                          ></div>
                          {colorHex.hex}
                        </div>
                      ) : (
                        `${translate(
                          DICTIONARY.PLACEHOLDER.CLIENT_PRIMARY_COLOR,
                          dictionary
                        )} *`
                      )}
                    </div>
                    {showColorPicker && (
                      <div className="">
                        <SketchPicker
                          color={colorHex.hex}
                          onChange={(color) => setColorHex(color)}
                          onChangeComplete={(color) => setColorHex(color)}
                          disableAlpha={true}
                          presetColors={[]}
                          width={235}
                          className="colorPicker-picker"
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Control
                      type="text"
                      placeholder={`${translate(
                        DICTIONARY.PLACEHOLDER.CLIENT_NAME,
                        dictionary
                      )} *`}
                      name="name"
                      onChange={handleClientInputChange}
                      onClick={() => setShowColorPicker(false)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* <Row>
                            <Col
                                className='section-content mt-3 ml-2'
                            >
                                <div className="subsection-name">
                                    <div className="title">
                                        <div>Datos de</div>
                                        <div className="title-bold">&nbsp;contacto</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <ClientContact
                            handleClientInputChange={handleClientInputChange}
                            setShowColorPicker={setShowColorPicker}
                        /> */}
            <ProductAvailableList isUsedInClient={true} isUsedInUser={false} />
            <Row className="starting-products">
              <Col xs="3" sm="2" md="2" lg="3" xl="3" xxl="3">
                <Form.Select
                  defaultValue={`${
                    selectedProducts && selectedProducts.length > 0
                      ? `${translate(
                          DICTIONARY.PLACEHOLDER.CLIENT_STARTING_PRODUCT,
                          dictionary
                        )} *`
                      : translate(
                          DICTIONARY.PLACEHOLDER.CLIENT_STARTING_PRODUCT,
                          dictionary
                        )
                  }`}
                  name="initialProductId"
                  onChange={handleClientInputChange}
                >
                  <option disabled>{`${
                    selectedProducts && selectedProducts.length > 0
                      ? `${translate(
                          DICTIONARY.PLACEHOLDER.CLIENT_STARTING_PRODUCT,
                          dictionary
                        )} *`
                      : translate(
                          DICTIONARY.PLACEHOLDER.CLIENT_STARTING_PRODUCT,
                          dictionary
                        )
                  }`}</option>
                  {selectedProducts &&
                    selectedProducts.map((product, index) => {
                      const { id, name } = product;
                      return (
                        <option
                          key={`starting_app_${index} _${id} `}
                          value={id}
                        >
                          {name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Col>
            </Row>
            <Button
              variant="secondary"
              type="submit"
              className="w-25 float-end button-form"
            >
              {translate(DICTIONARY.BUTTON.CLIENT_CREATE, dictionary)}
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default CreateClients;
