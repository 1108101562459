/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 28/09/2022
 * @Description getCorrectListCodesForSection
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import { DICTIONARY } from "../constants/dictionary";
import { SECTION } from "../constants/section";
import { urlToSection } from "./urlToSection";

export const getCorrectListCodesForSection = () => {
  const currentSection = urlToSection();
  let listCodes = [];
  switch (currentSection) {
    case SECTION.CLIENT_CREATE:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.CLIENT_ADD_LOGO,
        DICTIONARY.BUTTON.CLIENT_CREATE,
        DICTIONARY.LABEL.CLIENT_HAS_ACCESS_MOBILE_APP,
        DICTIONARY.LABEL.CLIENT_REDIRECT_OLD_PAGE,
        DICTIONARY.MESSAGE.CLIENT_CREATE,
        DICTIONARY.PLACEHOLDER.CLIENT_NAME,
        DICTIONARY.PLACEHOLDER.CLIENT_PRIMARY_COLOR,
        DICTIONARY.PLACEHOLDER.CLIENT_STARTING_PRODUCT,
        DICTIONARY.TITLE.CLIENT_CREATE,
        DICTIONARY.TITLE.CLIENT_DATA,
        DICTIONARY.TITLE.PRODUCT_AVAILABLE,
      ];
      break;
    case SECTION.CLIENT_EDIT:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.CLIENT_CHANGE_LOGO,
        DICTIONARY.BUTTON.DELETE,
        DICTIONARY.BUTTON.SAVE,
        DICTIONARY.BUTTON.SHOW_AS_ADMIN,
        DICTIONARY.BUTTON.SHOW_AS_CLIENT,
        DICTIONARY.BUTTON.SHOW_PERMISSION,
        DICTIONARY.LABEL.CLIENT_HAS_ACCESS_MOBILE_APP,
        DICTIONARY.LABEL.CLIENT_REDIRECT_OLD_PAGE,
        DICTIONARY.PLACEHOLDER.CLIENT_NAME,
        DICTIONARY.PLACEHOLDER.CLIENT_PRIMARY_COLOR,
        DICTIONARY.PLACEHOLDER.CLIENT_STARTING_PRODUCT,
        DICTIONARY.PLACEHOLDER.CONTACT_PERSON,
        DICTIONARY.PLACEHOLDER.EMAIL,
        DICTIONARY.PLACEHOLDER.PHONE,
        DICTIONARY.TITLE.CONTACT_DATA,
        DICTIONARY.TITLE.CLIENT_DATA,
        DICTIONARY.TITLE.CLIENT_EDIT,
        DICTIONARY.TITLE.CLIENT_PERMISSION,
        DICTIONARY.TITLE.PRODUCT_AVAILABLE,
        DICTIONARY.MESSAGE.CLIENT_SAVE,
      ];
      break;
    case SECTION.CLIENT_INDEX:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.ACTIVATE_DEACTIVATE,
        DICTIONARY.BUTTON.CANCEL_DELETE,
        DICTIONARY.BUTTON.CONFIRM_DELETE,
        DICTIONARY.BUTTON.CREATE,
        DICTIONARY.BUTTON.DELETE,
        DICTIONARY.LABEL.ACTIVATE,
        DICTIONARY.LABEL.DEACTIVATE_FROM,
        DICTIONARY.LABEL.DEACTIVATE_SCHEDULED,
        DICTIONARY.LABEL.DEACTIVATE,
        DICTIONARY.MESSAGE.CLIENT_DELETE,
        DICTIONARY.MESSAGE.ERROR,
        DICTIONARY.MESSAGE.SUCCESS,
        DICTIONARY.PLACEHOLDER.SEARCHBAR,
        DICTIONARY.TITLE.ACTIVATE_DEACTIVATE_CLIENT,
        DICTIONARY.TITLE.CLIENT_INDEX,
      ];
      break;
    case SECTION.CONTACT_EDIT:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.SAVE,
        DICTIONARY.MESSAGE.CONTACT_SAVE,
        DICTIONARY.PLACEHOLDER.CONTACT_PERSON,
        DICTIONARY.PLACEHOLDER.EMAIL,
        DICTIONARY.PLACEHOLDER.PHONE,
        DICTIONARY.TITLE.CONTACT_DATA,
        DICTIONARY.TITLE.CONTACT_EDIT,
      ];
      break;
    case SECTION.NOTIFICATION_CREATE:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.CLIENT,
        DICTIONARY.BUTTON.NOTIFICATION_CREATE,
        DICTIONARY.BUTTON.USER,
        DICTIONARY.LABEL.CLIENT,
        DICTIONARY.LABEL.EMAIL_AND_PLATFORM,
        DICTIONARY.LABEL.EMAIL,
        DICTIONARY.LABEL.PLATFORM,
        DICTIONARY.LABEL.ROLE_CUSTOMER_ADMIN,
        DICTIONARY.LABEL.ROLE_SUPER_ADMIN,
        DICTIONARY.LABEL.ROLE_USER,
        DICTIONARY.LABEL.USER_LOADING,
        DICTIONARY.LABEL.USER,
        DICTIONARY.MESSAGE.NOTIFICATION_SAVE,
        DICTIONARY.PLACEHOLDER.CLIENT,
        DICTIONARY.PLACEHOLDER.EMAIL,
        DICTIONARY.PLACEHOLDER.MESSAGE,
        DICTIONARY.PLACEHOLDER.NAME,
        DICTIONARY.PLACEHOLDER.ROLE,
        DICTIONARY.PLACEHOLDER.SELECT_ALL,
        DICTIONARY.PLACEHOLDER.TITLE,
        DICTIONARY.PLACEHOLDER.USER,
        DICTIONARY.TITLE.NOTIFICATION_CREATE,
        DICTIONARY.TITLE.NOTIFICATION_DATA,
        DICTIONARY.TITLE.RECEIVERS,
        DICTIONARY.TITLE.SEND_METHOD,
      ];
      break;
    case SECTION.NOTIFICATION_RECEIVED_DETAIL:
      listCodes = [
        DICTIONARY.BUTTON.GO_BACK_TO_LIST,
        DICTIONARY.LABEL.RECEPTION_DATE,
        DICTIONARY.TITLE.NOTIFICATION_DATA,
        DICTIONARY.TITLE.NOTIFICATION_DETAIL,
      ];
      break;
    case SECTION.NOTIFICATION_RECEIVED_INDEX:
      listCodes = [
        DICTIONARY.LABEL.TITLE,
        DICTIONARY.LABEL.MESSAGE,
        DICTIONARY.LABEL.NOT_READ,
        DICTIONARY.LABEL.NOTIFICATION_LOADING,
        DICTIONARY.LABEL.READ,
        DICTIONARY.LABEL.STATE,
        DICTIONARY.PLACEHOLDER.SEND_DATE,
        DICTIONARY.PLACEHOLDER.SEND_METHOD,
        DICTIONARY.PLACEHOLDER.ALL,
        DICTIONARY.TITLE.NOTIFICATION_RECEIVED,
      ];
      break;
    case SECTION.NOTIFICATION_SENT_DETAIL:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.CLIENT,
        DICTIONARY.BUTTON.GO_BACK_TO_LIST,
        DICTIONARY.BUTTON.USER,
        DICTIONARY.LABEL.CLIENT,
        DICTIONARY.LABEL.EMAIL,
        DICTIONARY.LABEL.NAME,
        DICTIONARY.LABEL.ROLE_CUSTOMER_ADMIN,
        DICTIONARY.LABEL.ROLE_SUPER_ADMIN,
        DICTIONARY.LABEL.ROLE_USER,
        DICTIONARY.LABEL.ROLE,
        DICTIONARY.LABEL.USER,
        DICTIONARY.LABEL.PLATFORM,
        DICTIONARY.LABEL.EMAIL_AND_PLATFORM,
        DICTIONARY.TITLE.NOTIFICATION_DATA,
        DICTIONARY.TITLE.NOTIFICATION_DETAIL,
        DICTIONARY.TITLE.RECEIVERS,
        DICTIONARY.TITLE.SEND_METHOD,
      ];
      break;
    case SECTION.NOTIFICATION_SENT_INDEX:
      listCodes = [
        DICTIONARY.BUTTON.CREATE,
        DICTIONARY.LABEL.MESSAGE,
        DICTIONARY.LABEL.NO_NOTIFICATION,
        DICTIONARY.LABEL.NOTIFICATION_LOADING,
        DICTIONARY.LABEL.TITLE,
        DICTIONARY.LABEL.USER,
        DICTIONARY.PLACEHOLDER.SEND_DATE,
        DICTIONARY.PLACEHOLDER.SEND_METHOD,
        DICTIONARY.TITLE.NOTIFICATION_SEND,
      ];
      break;
    case SECTION.PERMISSION_EDIT:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.SAVE,
        DICTIONARY.MESSAGE.PRODUCT_SAVE,
        DICTIONARY.MESSAGE.PERMISSION_ERROR,
        DICTIONARY.TITLE.PERMISSION_EDIT,
        DICTIONARY.TITLE.BASE_TEMPLATE,
        DICTIONARY.MESSAGE.PERMISSION_SAVE,
      ];
      break;
    case SECTION.PRODUCT_CREATE:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.PRODUCT_ADD_ICON,
        DICTIONARY.BUTTON.PRODUCT_CREATE,
        DICTIONARY.LABEL.PRODUCT_HAS_MOBILE_VERSION,
        DICTIONARY.MESSAGE.PRODUCT_CREATE,
        DICTIONARY.PLACEHOLDER.ALL,
        DICTIONARY.PLACEHOLDER.PRODUCT_NAME,
        DICTIONARY.PLACEHOLDER.PRODUCT_URL,
        DICTIONARY.PLACEHOLDER.SELECT_ALL,
        DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
        DICTIONARY.PLACEHOLDER.SOME,
        DICTIONARY.TITLE.PRODUCT_CREATE,
        DICTIONARY.TITLE.PRODUCT_DATA,
      ];
      break;
    case SECTION.PRODUCT_EDIT:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.PRODUCT_ADD_ICON,
        DICTIONARY.BUTTON.SAVE,
        DICTIONARY.LABEL.PRODUCT_HAS_MOBILE_VERSION,
        DICTIONARY.LABEL.PRODUCT_MAINTENANCE,
        DICTIONARY.MESSAGE.PRODUCT_SAVE,
        DICTIONARY.PLACEHOLDER.ALL,
        DICTIONARY.PLACEHOLDER.PRODUCT_NAME,
        DICTIONARY.PLACEHOLDER.PRODUCT_URL,
        DICTIONARY.PLACEHOLDER.SELECT_ALL,
        DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
        DICTIONARY.PLACEHOLDER.SOME,
        DICTIONARY.TITLE.PRODUCT_DATA,
        DICTIONARY.TITLE.PRODUCT_EDIT,
      ];
      break;
    case SECTION.PRODUCT_INDEX:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.CANCEL_DELETE,
        DICTIONARY.BUTTON.CONFIRM_DELETE,
        DICTIONARY.BUTTON.CREATE,
        DICTIONARY.BUTTON.DELETE,
        DICTIONARY.LABEL.ICON,
        DICTIONARY.LABEL.PRODUCT_LOADING,
        DICTIONARY.MESSAGE.PRODUCT_DELETE,
        DICTIONARY.PLACEHOLDER.CLIENT,
        DICTIONARY.PLACEHOLDER.NAME,
        DICTIONARY.PLACEHOLDER.URL,
        DICTIONARY.TITLE.PRODUCT_INDEX,
      ];
      break;
    case SECTION.REPORTING_INDEX:
      listCodes = [
        DICTIONARY.BUTTON.EXPORT,
        DICTIONARY.LABEL.ACCESS_OR_PRODUCT,
        DICTIONARY.LABEL.LOCATION,
        DICTIONARY.LABEL.REPORTING_LOADING,
        DICTIONARY.PLACEHOLDER.START_DATE,
        DICTIONARY.PLACEHOLDER.END_DATE,
        DICTIONARY.PLACEHOLDER.CLIENT,
        DICTIONARY.PLACEHOLDER.USER,
        DICTIONARY.TITLE.REPORTING_INDEX,
      ];
      break;
    case SECTION.USER_CREATE:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.USER_ADD_PHOTO,
        DICTIONARY.BUTTON.USER_CREATE,
        DICTIONARY.LABEL.ALLOW_PUSH_NOTIFICATION,
        DICTIONARY.LABEL.ROLE_CUSTOMER_ADMIN,
        DICTIONARY.LABEL.ROLE_SUPER_ADMIN,
        DICTIONARY.LABEL.ROLE_USER,
        DICTIONARY.LABEL.SEND_NOTIFICATION_EMAIL,
        DICTIONARY.MESSAGE.USER_CREATE,
        DICTIONARY.PLACEHOLDER.EMAIL,
        DICTIONARY.PLACEHOLDER.LANGUAGE,
        DICTIONARY.PLACEHOLDER.NAME,
        DICTIONARY.PLACEHOLDER.PASSWORD,
        DICTIONARY.PLACEHOLDER.REPEAT_PASSWORD,
        DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
        DICTIONARY.PLACEHOLDER.SURNAME,
        DICTIONARY.PLACEHOLDER.USER_TYPE,
        DICTIONARY.PLACEHOLDER.USER,
        DICTIONARY.TITLE.PRODUCT_AVAILABLE,
        DICTIONARY.TITLE.USER_CREATE,
        DICTIONARY.TITLE.USER_DATA,
      ];
      break;
    case SECTION.USER_EDIT:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.COPY_PROFILE,
        DICTIONARY.BUTTON.DELETE,
        DICTIONARY.BUTTON.RESTORE_PASSWORD,
        DICTIONARY.BUTTON.SAVE,
        DICTIONARY.BUTTON.SHOW_PERMISSION,
        DICTIONARY.BUTTON.USER_ADD_PHOTO,
        DICTIONARY.LABEL.ALLOW_PUSH_NOTIFICATION,
        DICTIONARY.LABEL.RESET_PASSWORD_TOOLTIP,
        DICTIONARY.LABEL.ROLE_CUSTOMER_ADMIN,
        DICTIONARY.LABEL.ROLE_SUPER_ADMIN,
        DICTIONARY.LABEL.ROLE_USER,
        DICTIONARY.LABEL.SEND_NOTIFICATION_EMAIL,
        DICTIONARY.MESSAGE.PASSWORD_NOT_EQUAL,
        DICTIONARY.MESSAGE.USER_SAVE,
        DICTIONARY.PLACEHOLDER.EMAIL,
        DICTIONARY.PLACEHOLDER.LANGUAGE,
        DICTIONARY.PLACEHOLDER.NAME,
        DICTIONARY.PLACEHOLDER.NEW_PASSWORD,
        DICTIONARY.PLACEHOLDER.OLD_PASSWORD,
        DICTIONARY.PLACEHOLDER.REPEAT_PASSWORD,
        DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
        DICTIONARY.PLACEHOLDER.SURNAME,
        DICTIONARY.PLACEHOLDER.USER_TYPE,
        DICTIONARY.PLACEHOLDER.USER,
        DICTIONARY.TITLE.PRODUCT_AVAILABLE,
        DICTIONARY.TITLE.USER_DATA,
        DICTIONARY.TITLE.USER_EDIT,
        DICTIONARY.TITLE.USER_PERMISSION,
      ];
      break;
    case SECTION.PROFILE:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.COPY_PROFILE,
        DICTIONARY.BUTTON.DELETE,
        DICTIONARY.BUTTON.RESTORE_PASSWORD,
        DICTIONARY.BUTTON.SAVE,
        DICTIONARY.BUTTON.SHOW_PERMISSION,
        DICTIONARY.BUTTON.USER_ADD_PHOTO,
        DICTIONARY.LABEL.RESET_PASSWORD_TOOLTIP,
        DICTIONARY.LABEL.ROLE_CUSTOMER_ADMIN,
        DICTIONARY.LABEL.ROLE_SUPER_ADMIN,
        DICTIONARY.LABEL.ROLE_USER,
        DICTIONARY.LABEL.SEND_NOTIFICATION_EMAIL,
        DICTIONARY.LABEL.ALLOW_PUSH_NOTIFICATION,
        DICTIONARY.MESSAGE.PASSWORD_NOT_EQUAL,
        DICTIONARY.MESSAGE.USER_SAVE,
        DICTIONARY.PLACEHOLDER.EMAIL,
        DICTIONARY.PLACEHOLDER.LANGUAGE,
        DICTIONARY.PLACEHOLDER.NAME,
        DICTIONARY.PLACEHOLDER.NEW_PASSWORD,
        DICTIONARY.PLACEHOLDER.OLD_PASSWORD,
        DICTIONARY.PLACEHOLDER.REPEAT_PASSWORD,
        DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
        DICTIONARY.PLACEHOLDER.SURNAME,
        DICTIONARY.PLACEHOLDER.USER_TYPE,
        DICTIONARY.PLACEHOLDER.USER,
        DICTIONARY.TITLE.PRODUCT_AVAILABLE,
        DICTIONARY.TITLE.USER_DATA,
        DICTIONARY.TITLE.USER_EDIT,
        DICTIONARY.TITLE.USER_PROFILE,
        DICTIONARY.TITLE.USER_PERMISSION,
      ];
      break;
    case SECTION.USER_INDEX:
      listCodes = [
        DICTIONARY.BUTTON.ACCEPT,
        DICTIONARY.BUTTON.ACTIVATE_DEACTIVATE,
        DICTIONARY.BUTTON.CANCEL_DELETE,
        DICTIONARY.BUTTON.CONFIRM_DELETE,
        DICTIONARY.BUTTON.CREATE,
        DICTIONARY.BUTTON.DELETE,
        DICTIONARY.LABEL.ACTIVATE,
        DICTIONARY.LABEL.ACTIVE_UNTIL,
        DICTIONARY.LABEL.DEACTIVATE,
        DICTIONARY.LABEL.USER_LOADING,
        DICTIONARY.LABEL.ROLE_CUSTOMER_ADMIN,
        DICTIONARY.LABEL.ROLE_SUPER_ADMIN,
        DICTIONARY.LABEL.ROLE_USER,
        DICTIONARY.LABEL.STATE,
        DICTIONARY.MESSAGE.USER_DELETE,
        DICTIONARY.MESSAGE.ERROR,
        DICTIONARY.MESSAGE.SUCCESS,
        DICTIONARY.PLACEHOLDER.CLIENT,
        DICTIONARY.PLACEHOLDER.NAME,
        DICTIONARY.PLACEHOLDER.EMAIL,
        DICTIONARY.PLACEHOLDER.ROLE,
        DICTIONARY.PLACEHOLDER.USER,
        DICTIONARY.TITLE.ACTIVATE_DEACTIVATE_USER,
        DICTIONARY.TITLE.USER_INDEX,
      ];
      break;
    default:
      break;
  }

  return listCodes;
};
