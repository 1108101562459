/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 29/08/2022                                        
* @Description Notifications for selected users                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React from 'react'
import { Col, Row } from 'react-bootstrap'
import IndexUser from '../../pages/users/IndexUser'

export const UserNotification = (props) => {
    // const {show}
    const {
        isForDetailSentNotification = false,
        isActive = false,
        hideState = false
    } = props;

    const propsIndexUser = {
        showAsSection: true,
        isForDetailSentNotification,
        isActive,
        hideState
    }
    return (
        <>
            <Row
                className='mt-3 '><Col></Col>
            </Row>
            <IndexUser
                {...propsIndexUser}
            />
        </>
    )
}
