/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 21/01/2022
 * @Description Role context for get roles
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */

import React, { createContext, useContext, useReducer } from "react";
import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
const RoleContext = createContext([]);

const initialState = {
  roles: [],
};

const roleReducer = (state = initialState, action) => {
  // const stateRoles = state.roles;
  switch (action.type) {
    case types.roleGetAll:
      return {
        ...state,
        roles: action.payload,
      };
    default:
      return state;
  }
};

const setAllRoles = (roles) => ({
  type: types.roleGetAll,
  payload: roles,
});
const getAllRoles = async (dispatch, token) => {
  // endPoint, data = {}, method = 'GET', token
  try {
    const rolesSession = sessionStorage.getItem("roles");
    if (false) {
      dispatch(setAllRoles(JSON.parse(rolesSession)));
    } else {
      const resp = await fetchWithToken("permissions", {}, "GET", token);
      const body = await resp.json();
      if (body.success) {
        const {
          data: { permissions },
        } = body;
        const roles = permissions.map((_item) => {
          return {
            id: _item.value,
            value: _item.name,
          };
        });
        sessionStorage.setItem("roles", JSON.stringify(roles));
        dispatch(setAllRoles(roles));
      } else {
        redirectToNoAuthorizedURL(resp);
      }
    }
  } catch (error) {
    console.error(error);
  }
};

const RoleProvider = ({ children }) => {
  const [state, dispatch] = useReducer(roleReducer, []);
  const value = { state, dispatch, getAllRoles };

  return <RoleContext.Provider value={value}>{children}</RoleContext.Provider>;
};

const useRole = () => {
  const context = useContext(RoleContext);

  if (context === undefined) {
    throw new Error("useRole must be used with RoleProvider");
  }
  return context;
};

export { RoleProvider, useRole, RoleContext };
