/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 26/08/2022                                        
* @Description Reducer for date picker actions                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { types } from "../types/types";

const initialState = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
    show: false
}

export const datePickerReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.datePickerSetStartDate:
            return {
                ...state,
                startDate: action.payload
            }
        case types.datePickerSetEndDate:
            return {
                ...state,
                endDate: action.payload
            }
        case types.datePickerToggle:
            return {
                ...state,
                show: action.payload
            }
        default:
            return state;
    }
}