/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/07/2022                                        
* @Description Modal for activate/deactive users and clients                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { useContext, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMessage, setShowModal } from '../../actions/modal';
import { activeDeactiveUser } from '../../actions/user';
import { activeDeactiveCustomer } from '../../actions/client';
import { DICTIONARY } from '../../constants/dictionary';
import { MODAL_TYPES } from '../../constants/modals';
import { useAuth } from '../../context/AuthContext';
import { ClientContext } from '../../context/ClientContext';
import { UserContext } from '../../context/UserContext';
import { useForm } from '../../hooks/useForm';
import { translate } from '../../utils/translate';

const initialStateModal = {
    isActive: false,
    isActiveUpdateDate: ''
}
export const ModalActivateUsers = (props) => {
    const { current: { dictionary } } = useSelector((state) => state.translation)
    // const paramsForLoaded = '&_s=841110&_l=1';
    const { state: { token } } = useAuth();

    const { id, title, icon } = props;
    const dispatch = useDispatch();
    const { modals } = useSelector((state) => state.modal);
    const [formModalValue, handleModalValues] = useForm(initialStateModal);
    const { show, message } = modals.filter((item) => item.id === id)[0];
    const [showDeactiveOptions, setShowDeactiveOptions] = useState(null);
    const {
        state: userState,
        // filterUsers,
        // getUsersWithPagination,
        // filters,
        // dispatch: dispatchUser,
    } = useContext(UserContext);
    const { state: stateClient, getAllClients } = useContext(ClientContext);
    const { selectedClients } = stateClient;
    const { selectedUsers } = userState;

    const handleClose = () => {
        dispatch(setShowModal(
            id,
            !show
        ));
        setShowDeactiveOptions(null);
        dispatch(setShowMessage(true));
        if (message !== '') {
        }
    }

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const { isActive, isActiveUpdateDate } = formModalValue;
        const payload = {
            isActive: isActive === '1',
            isActiveUpdateDate: isActiveUpdateDate !== '' ? `${isActiveUpdateDate} 00:00:00:000` : null
        }
        switch (id) {
            case MODAL_TYPES.ACTIVATE_USERS:
                if (selectedUsers && selectedUsers.length > 0) {
                    selectedUsers.forEach(async (sUser) => {
                        const params = {
                            id: sUser,
                            payload,
                            succesMessage: translate(DICTIONARY.MESSAGE.SUCCESS, dictionary),
                            errorMessage: translate(DICTIONARY.MESSAGE.ERROR, dictionary)
                        }
                        await dispatch(activeDeactiveUser(params));
                    });
                    // if (!isUsingFilter) {
                    //     sessionStorage.setItem('allUsersLoaded', false);

                    //     // token, limit, page
                    //     const userPaginationObj = {
                    //         token: token,
                    //         limit: 25,
                    //         page: 1,
                    //         userRole: sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : ''
                    //     }
                    //     getUsersWithPagination(userPaginationObj);
                    // } else {
                    //     if (filters) {
                    //         filterUsers(dispatchUser, filters);
                    //     }
                    // }
                    handleClose();
                }
                break;
            case MODAL_TYPES.ACTIVATE_CUSTOMERS:
                if (selectedClients && selectedClients.length > 0) {
                    selectedClients.forEach(async (sUser) => {
                        const params = {
                            id: sUser,
                            payload,
                            succesMessage: translate(DICTIONARY.MESSAGE.SUCCESS, dictionary),
                            errorMessage: translate(DICTIONARY.MESSAGE.ERROR, dictionary)
                        }
                        await dispatch(activeDeactiveCustomer(params));
                    });
                    handleClose();
                    getAllClients(token);
                }
                break;
            default:
                break;
        }
    }

    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;
    currentMonth = currentMonth < 10 ? `0${currentMonth}` : currentMonth;
    currentDate = `${currentDate.getFullYear()}-${currentMonth}-${currentDate.getDate()}`;


    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="md"
            centered={true}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    <div className='section-name'>
                        <span className="material-icons-round section-icon">
                            {icon}
                        </span>
                        <div>{title}</div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    className=''
                    onSubmit={handleSubmit}
                >
                    <Container>
                        <Row

                        >
                            <Col
                                className="modal-formCheck"
                            >
                                <Form.Check
                                    className="modal-formCheck-input"
                                    type="radio"
                                    name="isActive"
                                    id="acivateUser"
                                    onChange={(ev) => {
                                        handleModalValues(ev);
                                        setShowDeactiveOptions(false)
                                    }}
                                    value={1}
                                />
                                <div>
                                    <Form.Check.Label className="modal-formCheck-label" htmlFor="acivateUser">
                                        {translate(DICTIONARY.LABEL.ACTIVATE, dictionary)}
                                    </Form.Check.Label>
                                </div>
                            </Col>
                        </Row>
                        <Row
                            className='mt-3 modal-rowDatePicker'

                        >
                            <Col
                                className="modal-formCheck"
                            >
                                <Form.Check
                                    className="modal-formCheck-input"
                                    type="radio"
                                    name="isActive"
                                    id="deacivateUser"
                                    onChange={(ev) => {
                                        handleModalValues(ev);
                                        setShowDeactiveOptions((state) => !state);
                                    }}
                                    value={0}
                                />
                                <Form.Check.Label className="modal-formCheck-label" htmlFor="deacivateUser">
                                    {translate(DICTIONARY.LABEL.DEACTIVATE, dictionary)}
                                </Form.Check.Label>
                            </Col>
                            <Col
                                className="modal-formCheck"
                            >
                                {
                                    showDeactiveOptions !== null && showDeactiveOptions &&
                                    <>

                                        <input
                                            type="date"
                                            name="isActiveUpdateDate"
                                            placeholder="Seleccione una fecha"
                                            className='form-control'
                                            onChange={(ev) => {
                                                handleModalValues(ev);
                                            }}
                                            min={currentDate}
                                        />
                                    </>
                                }
                            </Col>
                        </Row>


                    </Container>
                    <Button
                        variant="secondary"
                        type="submit"
                        className="w-25 float-end button-form"
                    >
                        {translate(DICTIONARY.BUTTON.ACCEPT, dictionary)}
                    </Button>

                </Form>
            </Modal.Body>
        </Modal>
    )
}
