export const INPUT_SELECTS = {
    CLIENTS: 'INPUT SELECT FOR CLIENT',
    NOTIFICATION: {
        SEND_TYPE: 'INPUT SELECT FOR LIST NOTIFICATION AS FILTER SEND TYPE',
        STATE: 'INPUT SELECT FOR LIST NOTIFICATION AS FILTER STATE'
    }
}

export const INPUT_SELECTS_STATE_VARIABLE = {
    CLIENTS: 'clients',
    NOTIFICATION: {
        SEND_TYPE: 'notificationFilterSendType',
        STATE: 'notificationFilterState'
    }
}