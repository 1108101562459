/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 22/07/2022                                        
* @Description Reducer for users/clients permissiones                                                           
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { types } from "../types/types";


const initialState = {
    base: {
        // parentIsTemplate: null,
        // parentIsCustomer: null,
        // template: {} 
        permission: {
            json: {}
        }
    },
    custom: {
        // parentIsTemplate: null,
        // parentIsCustomer: null,
        // template: {}
        permission: {
            json: {},
        },
        update: {},
    },
    error: null,
    isSubmited: false,
    isDeleted: false,
    message: ''
}

export const permissionReducer = (state = initialState, action) => {
    const customFromState = state.custom;
    const baseFromState = state.base;
    switch (action.type) {
        case types.permissionGetCustomTemplate:
            return {
                ...state,
            }
        case types.permissionGetTemplate:
            return {
                ...state,
            }
        case types.permissionSetError:
            return {
                ...state,
                error: action.payload
            }
        case types.permissionSetIsSubmited:
            return {
                ...state,
                isSubmited: action.payload
            }
        case types.permissionSetCustomTemplate:
            return {
                ...state,
                custom: action.payload
            }
        case types.permissionSetTemplate:
            return {
                ...state,
                base: action.payload
            }
        case types.permissionSetUpdateBaseTemplate:
            return {
                ...state,
                base: {
                    ...baseFromState,
                    update: action.payload
                }
            }
        case types.permissionSetUpdateCustomTemplate:
            return {
                ...state,
                custom: {
                    ...customFromState,
                    update: action.payload
                }
            }
        case types.permissionSetJsonCustom:
            const permissionFromState = customFromState.permission;
            return {
                ...state,
                custom: {
                    ...customFromState,
                    permission: {
                        ...permissionFromState,
                        json: action.payload
                    }

                }
            }
        case types.permissionSetMessage:
            return {
                ...state,
                message: action.payload
            }
        case types.permissionSetDeleted:
            return {
                ...state,
                isDeleted: action.payload
            }
        default:
            return state;
    }
}