import React, { useEffect } from 'react'
import {
    Container,
    Col,
    Button,
    Row,
    Tabs,
    Tab
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useDispatch, useSelector } from 'react-redux';
import { getAllClients, switchClientMenu } from '../../actions/inputSelectActions';
import { getNotificationOne, setNotificationSetActiveTab } from '../../actions/notification';
import { NotificationMessage } from '../../components/Notification/NotificationMessage';
import { NotificationSendType } from '../../components/Notification/NotificationSendType';
import { ClientReceiverList } from '../../components/Notification/ClientReceiverList';
import { UserNotification } from '../../components/Notification/UserNotification';
import { useParams } from 'react-router-dom';
import { translate } from '../../utils/translate';
import { DICTIONARY } from '../../constants/dictionary';


const DetailSentNotification = () => {
    const paramsForLoaded = '&_s=841110&_l=1';

    const { current: { dictionary } } = useSelector((state) => state.translation);
    const { notificationId } = useParams();
    const dispatch = useDispatch();;

    const { state: { token } } = useAuth();
    const { clients } = useSelector((state) => state.inputSelect);
    const { activeTab } = useSelector((state) => state.notification);


    const goBackToNotificationList = () => {
        window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}/notification?_i=${token}${paramsForLoaded}`;
        // navigate(`/notification?_i=${token}${paramsForLoaded}`, { replace: false });
    }
    const handleSelectTab = (ev) => {
        sessionStorage.setItem('notificationActiveTab', ev);
        handleCloseMenu();
        dispatch(setNotificationSetActiveTab(ev));
    }
    const handleCloseMenu = () => {
        if (clients.isOpen) {
            dispatch(switchClientMenu(!clients.isOpen));
        }
    }
    useEffect(() => {
        dispatch(getAllClients());
    }, []);


    useEffect(() => {
        const notificationActiveTab = sessionStorage.getItem('notificationActiveTab');
        if (notificationActiveTab) {
            const _notificationActiveTab = notificationActiveTab;
            dispatch(setNotificationSetActiveTab(_notificationActiveTab));
        }
    }, []);
    useEffect(() => {
        dispatch(getNotificationOne({ payload: notificationId }))
    }, []);

    // const limit = 25;
    // const nextPage = sessionStorage.getItem('nextPageUserReceiverList');
    // const handleScroll = () => {
    //     const _activeTab = sessionStorage.getItem('notificationActiveTab');

    //     if (_activeTab === 'user') {
    //         const _notificationId = sessionStorage.getItem('notificationId');
    //         const allUsersLoaded = sessionStorage.getItem('allUserReceiverLoaded');
    //         const actualScroll = window.innerHeight + window.scrollY + 400;

    //         if (!!allUsersLoaded && actualScroll >= document.body.offsetHeight) {
    //             const userPaginationObj = {
    //                 limit: limit,
    //                 page: nextPage,
    //                 id: _notificationId
    //             }
    //             // dispatch(getUsersList(userPaginationObj));
    //             window.removeEventListener('scroll', handleScroll);
    //         }
    //     }
    // }
    useEffect(() => {
        sessionStorage.setItem('notificationId', notificationId);
        sessionStorage.setItem('allUserReceiverLoaded', false);
    }, [notificationId]);

    return (
        <>
            <Container
                fluid
            >
                <Row>
                    <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        xxl="12">
                        <div className='section-name'>
                            <span className="material-icons-round section-icon">
                                notifications
                            </span>
                            <div>
                                {translate(DICTIONARY.TITLE.NOTIFICATION_DETAIL, dictionary)}
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NotificationMessage
                            titleName="title"
                            textName="text"
                            isRead={true}
                        />
                        <NotificationSendType
                            isRead={true}
                        />
                        <Row>
                            <Col
                                className='section-content mt-3 ml-2'
                            >
                                <div className='subsection-name'>
                                    <div>
                                        {translate(DICTIONARY.TITLE.RECEIVERS, dictionary)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className='section-content mt-3 notification-tabs'>
                                <Tabs
                                    defaultActiveKey="client"
                                    activeKey={activeTab}
                                    id="controlled-tab-example"
                                    onSelect={(ev) => handleSelectTab(ev)}
                                    className="justify-content-start tabs"
                                >
                                    <Tab
                                        eventKey="client"
                                        title={translate(DICTIONARY.BUTTON.CLIENT, dictionary)}
                                    >
                                    </Tab>
                                    <Tab
                                        eventKey="user"
                                        title={translate(DICTIONARY.BUTTON.USER, dictionary)}
                                    >
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>
                        {
                            activeTab === 'client' &&
                            <ClientReceiverList />
                        }
                        {
                            activeTab === 'user' &&
                            <>
                                {/* <UserReceiverList /> */}
                                <UserNotification
                                    isForDetailSentNotification={true}
                                />
                            </>
                        }
                        <Row>
                        </Row>
                        <Button
                            variant="secondary"
                            type="button"
                            className="w-25 float-end button-form"
                            onClick={goBackToNotificationList}

                        >
                            {translate(DICTIONARY.BUTTON.GO_BACK_TO_LIST, dictionary)}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DetailSentNotification;