/**
 * ----------------------------------------------------------------------------------------
 * @Author ismael.gomez@babooni.com
 * @Date 27/09/2022
 * @Description List codes for each component/scrren in app
 * ----------------------------------------------------------------------------------------
 * @Inputs NOTHING
 *  - @param
 * ----------------------------------------------------------------------------------------
 * @Outputs NOTHING
 *  - @param
 *-----------------------------------------------------------------------------------------
 */
export const CLIENT_INDEX = {
  BUTTON: {
    ACCEPT: "button_accept",
    ACTIVATE_DEACTIVATE: "button_activate_deactivate",
    CREATE: "button_create",
    CANCEL_DELETE: "button_cancel_delete",
    CONFIRM_DELETE: "button_confirm_delete",
    DELETE: "button_delete",
  },
  LABEL: {
    ACTIVATE: "label_activate",
    DEACTIVATE: "label_deactivate",
    DEACTIVATE_SCHEDULED: "label_deactivate_scheduled",
    DEACTIVATE_FROM: "label_deactivate_from",
  },
  MESSAGE: {
    CLIENT_DELETE: "message_client_delete",
    ERROR: "message_error",
    SUCCESS: "message_success",
  },
  PLACEHOLDER: {
    SEARCHBAR: "placeholder_searchbar",
  },
  TITLE: {
    ACTIVATE_DEACTIVATE_CLIENT: "title_activate_deactivate_client",
    CLIENT_INDEX: "title_clients",
  },
};
export const CLIENT_CREATE = {
  BUTTON: {
    CLIENT_CREATE: "button_client_create",
    CLIENT_ADD_LOGO: "button_client_add_logo",
  },
  LABEL: {
    CLIENT_HAS_ACCESS_MOBILE_APP: "label_client_allow_access_mobile_app",
    CLIENT_REDIRECT_OLD_PAGE: "label_client_redirect_old_page",
  },
  MESSAGE: {
    CLIENT_CREATE: "message_client_create",
  },
  PLACEHOLDER: {
    CLIENT_NAME: "placeholder_client_name",
    CLIENT_PRIMARY_COLOR: "placeholder_client_primary_color",
    CLIENT_STARTING_PRODUCT: "placeholder_client_starting_product",
  },
  TITLE: {
    CLIENT_CREATE: "title_client_create",
    CLIENT_DATA: "title_client_data",
    PRODUCT_AVAILABLE: "title_product_available",
  },
};
export const CLIENT_EDIT = {
  BUTTON: {
    CLIENT_CHANGE_LOGO: "button_client_change_logo",
    SAVE: "button_save",
    SHOW_AS_CLIENT: "button_show_as_client",
    SHOW_AS_ADMIN: "button_show_as_admin",
    SHOW_PERMISSION: "button_show_permission",
  },
  LABEL: {},
  MESSAGE: {
    CLIENT_SAVE: "message_client_save",
  },
  PLACEHOLDER: {
    CONTACT_PERSON: "placeholder_contact_person",
    EMAIL: "placeholder_email",
    PHONE: "placeholder_phone",
  },
  TITLE: {
    CONTACT_DATA: "title_contact_data",
    CLIENT_EDIT: "title_client_edit",
    CLIENT_PERMISSION: "title_client_permission",
  },
};

export const USER_INDEX = {
  BUTTON: {},
  LABEL: {
    ACTIVE_UNTIL: "label_active_until",
    ROLE_USER: "label_role_user",
    ROLE_CUSTOMER_ADMIN: "label_role_customer_admin",
    ROLE_SUPER_ADMIN: "label_role_super_admin",
    USER_LOADING: "label_user_loading",
    STATE: "label_state",
  },
  MESSAGE: {
    USER_DELETE: "message_user_delete",
  },
  PLACEHOLDER: {
    USER: "placeholder_user",
    NAME: "placeholder_name",
    ROLE: "placeholder_role",
    CLIENT: "placeholder_client",
  },
  TITLE: {
    ACTIVATE_DEACTIVATE_USER: "title_activate_deactivate_user",
    USER_INDEX: "title_user_index",
  },
};

export const USER_CREATE = {
  BUTTON: {
    USER_ADD_PHOTO: "button_user_add_photo",
    USER_CREATE: "button_user_create",
  },
  LABEL: {
    SEND_NOTIFICATION_EMAIL: "label_send_notification_email",
    ALLOW_PUSH_NOTIFICATION: "label_user_allow_push_notification",
  },
  MESSAGE: {
    PASSWORDS_NOT_EQUAL: "message_passwords_not_equal",
    USER_CREATE: "message_user_create",
  },
  PLACEHOLDER: {
    LANGUAGE: "placeholder_language",
    PASSWORD: "placeholder_password",
    REPEAT_PASSWORD: "placeholder_repeat_password",
    SELECT_CLIENT: "placeholder_select_client",
    SURNAME: "placeholder_surname",
    USER_TYPE: "placeholder_user_type",
  },
  TITLE: {
    USER_CREATE: "title_user_create",
    USER_DATA: "title_user_data",
  },
};
export const USER_EDIT = {
  BUTTON: {
    RESTORE_PASSWORD: "button_restore_password",
    COPY_PROFILE: "button_copy_profile",
  },
  LABEL: {
    RESET_PASSWORD_TOOLTIP: "label_reset_password_tooltip",
  },
  MESSAGE: {
    PASSWORD_NOT_EQUAL: "message_passwords_not_equal",
    USER_SAVE: "message_user_save",
  },
  PLACEHOLDER: {
    OLD_PASSWORD: "placeholder_old_password",
    NEW_PASSWORD: "placeholder_new_password",
    REPEAT_PASSWORD: "placeholder_repeat_password",
  },
  TITLE: {
    USER_EDIT: "title_user_edit",
    USER_PERMISSION: "title_user_permission",
    USER_PROFILE: "title_user_profile",
  },
};
export const PRODUCT_INDEX = {
  BUTTON: {},
  LABEL: {
    ICON: "label_icon",
    PRODUCT_LOADING: "label_product_loading",
  },
  MESSAGE: {
    PRODUCT_DELETE: "message_product_delete",
  },
  PLACEHOLDER: {
    URL: "placeholder_url",
  },
  TITLE: {
    PRODUCT_INDEX: "title_product_index",
  },
};
export const PRODUCT_CREATE = {
  BUTTON: {
    PRODUCT_ADD_ICON: "button_product_add_icon",
    PRODUCT_CREATE: "button_product_create",
  },
  LABEL: {
    PRODUCT_HAS_MOBILE_VERSION: "label_product_has_mobile_version",
  },
  MESSAGE: {
    PRODUCT_CREATE: "message_product_create",
  },
  PLACEHOLDER: {
    SELECT_ALL: "placeholder_select_all",
    SOME: "placeholder_some",
    ALL: "placeholder_all",
    PRODUCT_NAME: "placeholder_product_name",
    PRODUCT_URL: "placeholder_product_url",
  },
  TITLE: {
    PRODUCT_CREATE: "title_product_create",
    PRODUCT_DATA: "title_product_data",
  },
};
export const PRODUCT_EDIT = {
  BUTTON: {},
  LABEL: {
    PRODUCT_MAINTENANCE: "label_product_maintenance",
  },
  MESSAGE: {
    PRODUCT_SAVE: "message_product_save",
  },
  PLACEHOLDER: {},
  TITLE: {
    PRODUCT_EDIT: "title_product_edit",
  },
};

export const PERMISSION_EDIT = {
  BUTTON: {},
  LABEL: {},
  MESSAGE: {
    PERMISSION_SAVE: "message_permission_save",
    PERMISSION_ERROR: "message_permission_error",
  },
  PLACEHOLDER: {},
  TITLE: {
    PERMISSION_EDIT: "title_permission_edit",
    BASE_TEMPLATE: "title_base_template",
  },
};

export const CONTACT_EDIT = {
  BUTTON: {},
  LABEL: {},
  MESSAGE: {
    CONTACT_SAVE: "message_contact_save",
  },
  PLACEHOLDER: {},
  TITLE: {
    CONTACT_EDIT: "title_contact_edit",
  },
};

export const NOTIFICATION_SENT_INDEX = {
  BUTTON: {},
  LABEL: {
    MESSAGE: "label_message",
    NO_NOTIFICATION: "label_no_notification",
    NOTIFICATION_LOADING: "label_notification_loading",
    TITLE: "label_title",
  },
  MESSAGE: {},
  PLACEHOLDER: {
    SEND_DATE: "placeholder_send_date",
    SEND_METHOD: "placeholder_send_method",
  },
  TITLE: {
    NOTIFICATION_SEND: "title_notification_send",
  },
};

export const NOTIFICATION_RECEIVED_INDEX = {
  BUTTON: {},
  LABEL: {
    NOT_READ: "label_not_read",
    READ: "label_read",
  },
  MESSAGE: {},
  PLACEHOLDER: {},
  TITLE: {
    NOTIFICATION_RECEIVED: "title_notification_received",
  },
};
export const NOTIFICATION_CREATE = {
  BUTTON: {
    CLIENT: "button_client",
    NOTIFICATION_CREATE: "button_notification_create",
    USER: "button_user",
  },
  LABEL: {
    EMAIL_AND_PLATFORM: "label_email_and_platform",
    EMAIL: "label_email",
    PLATFORM: "label_platform",
  },
  MESSAGE: {
    NOTIFICATION_SAVE: "message_notification_save",
  },
  PLACEHOLDER: {
    TITLE: "placeholder_title",
    MESSAGE: "placeholder_message",
  },
  TITLE: {
    NOTIFICATION_CREATE: "title_notification_create",
    NOTIFICATION_DATA: "title_notification_data",
    RECEIVERS: "title_receivers",
    SEND_METHOD: "title_send_method",
  },
};
export const NOTIFICATION_RECEIVED_DETAIL = {
  BUTTON: {
    GO_BACK_TO_LIST: "button_go_back_to_list",
  },
  LABEL: {
    RECEPTION_DATE: "label_reception_date",
  },
  MESSAGE: {},
  PLACEHOLDER: {},
  TITLE: {
    NOTIFICATION_DETAIL: "title_notification_detail",
  },
};
export const NOTIFICATION_SENT_DETAIL = {
  BUTTON: {},
  LABEL: {
    USER: "label_user",
    NAME: "label_name",
    ROLE: "label_role",
    CLIENT: "label_client",
  },
  MESSAGE: {},
  PLACEHOLDER: {},
  TITLE: {},
};

export const REPORTING_INDEX = {
  BUTTON: {
    EXPORT: "button_export",
  },
  LABEL: {
    ACCESS_OR_PRODUCT: "label_access_or_product",
    LOCATION: "label_location",
    REPORTING_LOADING: "label_reporting_loading",
  },
  MESSAGE: {},
  PLACEHOLDER: {
    END_DATE: "placeholder_end_date",
    START_DATE: "placeholder_start_date",
  },
  TITLE: {
    REPORTING_INDEX: "title_reporting_index",
  },
};

export const DICTIONARY = {
  BUTTON: {
    ...CLIENT_CREATE.BUTTON,
    ...CLIENT_EDIT.BUTTON,
    ...CLIENT_INDEX.BUTTON,
    ...CONTACT_EDIT.BUTTON,
    ...NOTIFICATION_CREATE.BUTTON,
    ...NOTIFICATION_RECEIVED_DETAIL.BUTTON,
    ...NOTIFICATION_RECEIVED_INDEX.BUTTON,
    ...NOTIFICATION_SENT_DETAIL.BUTTON,
    ...NOTIFICATION_SENT_INDEX.BUTTON,
    ...PERMISSION_EDIT.BUTTON,
    ...PRODUCT_CREATE.BUTTON,
    ...PRODUCT_EDIT.BUTTON,
    ...PRODUCT_INDEX.BUTTON,
    ...REPORTING_INDEX.BUTTON,
    ...USER_CREATE.BUTTON,
    ...USER_EDIT.BUTTON,
    ...USER_INDEX.BUTTON,
  },
  LABEL: {
    ...CLIENT_CREATE.LABEL,
    ...CLIENT_EDIT.LABEL,
    ...CLIENT_INDEX.LABEL,
    ...CONTACT_EDIT.LABEL,
    ...NOTIFICATION_CREATE.LABEL,
    ...NOTIFICATION_RECEIVED_DETAIL.LABEL,
    ...NOTIFICATION_RECEIVED_INDEX.LABEL,
    ...NOTIFICATION_SENT_DETAIL.LABEL,
    ...NOTIFICATION_SENT_INDEX.LABEL,
    ...PERMISSION_EDIT.LABEL,
    ...PRODUCT_CREATE.LABEL,
    ...PRODUCT_EDIT.LABEL,
    ...PRODUCT_INDEX.LABEL,
    ...REPORTING_INDEX.LABEL,
    ...USER_CREATE.LABEL,
    ...USER_EDIT.LABEL,
    ...USER_INDEX.LABEL,
  },
  MESSAGE: {
    ...CLIENT_CREATE.MESSAGE,
    ...CLIENT_EDIT.MESSAGE,
    ...CLIENT_INDEX.MESSAGE,
    ...CONTACT_EDIT.MESSAGE,
    ...NOTIFICATION_CREATE.MESSAGE,
    ...NOTIFICATION_RECEIVED_DETAIL.MESSAGE,
    ...NOTIFICATION_RECEIVED_INDEX.MESSAGE,
    ...NOTIFICATION_SENT_DETAIL.MESSAGE,
    ...NOTIFICATION_SENT_INDEX.MESSAGE,
    ...PERMISSION_EDIT.MESSAGE,
    ...PRODUCT_CREATE.MESSAGE,
    ...PRODUCT_EDIT.MESSAGE,
    ...PRODUCT_INDEX.MESSAGE,
    ...REPORTING_INDEX.MESSAGE,
    ...USER_CREATE.MESSAGE,
    ...USER_EDIT.MESSAGE,
    ...USER_INDEX.MESSAGE,
  },
  PLACEHOLDER: {
    ...CLIENT_CREATE.PLACEHOLDER,
    ...CLIENT_EDIT.PLACEHOLDER,
    ...CLIENT_INDEX.PLACEHOLDER,
    ...CONTACT_EDIT.PLACEHOLDER,
    ...NOTIFICATION_CREATE.PLACEHOLDER,
    ...NOTIFICATION_RECEIVED_DETAIL.PLACEHOLDER,
    ...NOTIFICATION_RECEIVED_INDEX.PLACEHOLDER,
    ...NOTIFICATION_SENT_DETAIL.PLACEHOLDER,
    ...NOTIFICATION_SENT_INDEX.PLACEHOLDER,
    ...PERMISSION_EDIT.PLACEHOLDER,
    ...PRODUCT_CREATE.PLACEHOLDER,
    ...PRODUCT_EDIT.PLACEHOLDER,
    ...PRODUCT_INDEX.PLACEHOLDER,
    ...REPORTING_INDEX.PLACEHOLDER,
    ...USER_CREATE.PLACEHOLDER,
    ...USER_EDIT.PLACEHOLDER,
    ...USER_INDEX.PLACEHOLDER,
  },
  TITLE: {
    ...CLIENT_CREATE.TITLE,
    ...CLIENT_EDIT.TITLE,
    ...CLIENT_INDEX.TITLE,
    ...CONTACT_EDIT.TITLE,
    ...NOTIFICATION_CREATE.TITLE,
    ...NOTIFICATION_RECEIVED_DETAIL.TITLE,
    ...NOTIFICATION_RECEIVED_INDEX.TITLE,
    ...NOTIFICATION_SENT_DETAIL.TITLE,
    ...NOTIFICATION_SENT_INDEX.TITLE,
    ...PERMISSION_EDIT.TITLE,
    ...PRODUCT_CREATE.TITLE,
    ...PRODUCT_EDIT.TITLE,
    ...PRODUCT_INDEX.TITLE,
    ...REPORTING_INDEX.TITLE,
    ...USER_CREATE.TITLE,
    ...USER_EDIT.TITLE,
    ...USER_INDEX.TITLE,
  },
};
