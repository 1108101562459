/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 24/08/2022                                        
* @Description Actions for contact                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { redirectToNoAuthorizedURL } from "../utils/redirectToNoAuthorizedURL";
import ENDPOINTS from "./endpoints";

// CONTACT: {
//     DELETE: {
//         ONE: 'api/v1/contacts/customers' //api/v1/contacts/customers/:customerId
//     },
//     GET: {
//         ONE: 'api/v1/contacts/customers', //api/v1/contacts/customers/:customerId
//         DEFAULT: 'api/v1/contacts/template'
//     },
//     POST: {
//         CREATE: 'api/v1/contacts/template',
//         OVERWRITE: 'api/v1/contacts/customers'
//     },
// }


// const setContactCreate = (payload) => ({
//     type: types.contactCreate,
//     payload
// })
// const setContactDelete = (payload) => ({
//     type: types.contactDelete,
//     payload
// })
const setContactGetDefault = (payload) => ({
    type: types.contactGetDefault,
    payload
})
// const setContactGetOne = (payload) => ({
//     type: types.contactGetOne,
//     payload
// })
// const setContactOverwrite = (payload) => ({
//     type: types.contactOverwrite,
//     payload
// })
export const resetContactData = () => ({
    type: types.contactResetData
});
export const deleteContact = (args) => {
    return async (dispatch) => {
        try {
            const { customerId } = args;
            const resp = await fetchWithToken(
                `${ENDPOINTS.CONTACT.DELETE.ONE}/${customerId}`,
                {},
                'DELETE',
            );
            const body = await resp.json();
            if (body.success) {
                //TODO: DO SOMETHING AFTER DELETE
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(`deleteContact`, error);
        }
    }
}

export const getContactOfCustomer = (args) => {
    return async (dispatch) => {
        try {
            const { customerId } = args;
            const resp = await fetchWithToken(
                `${ENDPOINTS.CONTACT.GET.ONE}/${customerId}`,
                {},
                'GET',
            );
            const body = await resp.json();
            if (body.success) {
                const { data } = body;
                const _data = {
                    parentIsTemplate: data.parentIsTemplate,
                    contact: data.permission
                }
                dispatch(setContactGetDefault(_data));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(`getContactOfCustomer`, error);

        }
    }
}
export const getDefaultContact = (args) => {
    return async (dispatch) => {
        try {
            const resp = await fetchWithToken(
                `${ENDPOINTS.CONTACT.GET.DEFAULT}`,
                {},
                'GET',
            );
            const body = await resp.json();
            if (body.success) {
                const { data } = body;
                dispatch(setContactGetDefault(data));
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(`getDefaultContactOfCustomer`, error);

        }
    }
}

export const createContact = (args) => {
    return async (dispatch) => {
        try {
            const { payload } = args;
            const resp = await fetchWithToken(
                `${ENDPOINTS.CONTACT.POST.CREATE}`,
                payload,
                'POST',
            );
            const body = await resp.json();
            if (body.success) {
                //TODO: DO SOMETHING AFTER GET CUSTOMER CONTACT DATA
                return body;
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(`createContact`, error);

        }
    }
}

export const overwriteDefaultContact = (args) => {
    return async (dispatch) => {
        try {
            const { payload } = args;
            const resp = await fetchWithToken(
                `${ENDPOINTS.CONTACT.POST.OVERWRITE_DEFAULT}`,
                payload,
                'POST',
            );
            const body = await resp.json();
            if (body.success) {
                //TODO: DO SOMETHING AFTER GET CUSTOMER CONTACT DATA
                return body;
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(`createContact`, error);

        }
    }
}