/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 21/07/2022                                        
* @Description Actions for modalReducer                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import { types } from "../types/types";


export const setShowModal = (id, show) => ({
    type: types.modalSetShow,
    payload: {
        id,
        show
    }
})
export const setShowMessage = (payload) => ({
    type: types.modalSetShowMesage,
    payload
})
export const setMessage = (payload) => ({
    type: types.modalSetMessage,
    payload
});

export const setDisabledButtons = (payload) => ({
    type: types.modalSetDisabledButtons,
    payload
})