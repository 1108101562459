import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { Container, Col, Image, Form, Button, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { ProductAvailableList } from "../../components/UI/ProductAvailableList/ProductAvailableList";
import { useAuth } from "../../context/AuthContext";
import { ClientContext } from "../../context/ClientContext";
import { useRole } from "../../context/RoleContext";
import { UserContext } from "../../context/UserContext";
import { useForm } from "../../hooks/useForm";
import Swal from "sweetalert2";
import {
  APP_SECTIONS,
  userAccessManager,
} from "../../components/UI/Launcher/checkPermissions";
import { getUserData } from "../../components/UI/Launcher/fetchLauncher";
import COLORS from "../../constants/colors";
import { sweetAlertProps } from "../../constants/sweetAlertProps";
import ROLES from "../../constants/roles";
import { MODAL_TYPES } from "../../constants/modals";
import { ModalJSONPermissions } from "../../components/Modal/ModalJSONPermissions";
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "../../actions/modal";
import { getCustomTemplate } from "../../actions/permission";
import { setCopyProfileData } from "../../actions/user";
import { useNavigate } from "react-router-dom";
import { DICTIONARY } from "../../constants/dictionary";
import { translate } from "../../utils/translate";

const EditUser = (props) => {
  const dispatch = useDispatch();
  const {
    current: { dictionary },
  } = useSelector((state) => state.translation);
  let navigate = useNavigate();
  const { isOwnProfile } = props;

  const {
    state: { token },
  } = useAuth();
  const { languageOptions } = useSelector((state) => state.user);
  const { userId } = useParams();
  const {
    dispatch: dispatchUser,
    state: stateUser,
    editUser,
    getUserData: getUserDataFromContext,
    uploadUserImage,
    updatePassword,
    resetPassword,
    resetSelectedProductsForUser,
  } = useContext(UserContext);
  const { userData } = stateUser;
  const { dispatch: dispatchRole, state: stateRoles, getAllRoles } = useRole();
  const { roles } = stateRoles;

  // const isAdmin = userData?.role && userData.role === ROLES.ADMIN;
  const userRole = sessionStorage.getItem("userRole");
  const isAdmin = userRole === ROLES.ADMIN;

  const initialStateUser = {
    username: userData ? userData.username : "",
    email: userData ? userData.email : "",
    oldPassword: "",
    password: "",
    password2: "",
    firstName: userData ? userData.firstName : "",
    lastName: userData ? userData.lastName : "",
    role: userData ? userData.role : "",
    customerId: userData ? userData.customerId : "",
    allowEmailNotification:
      userData && userData?.allowEmailNotification
        ? userData.allowEmailNotification
        : true,
  };

  const { username, email, password, firstName, lastName } = initialStateUser;

  const [photo, setPhoto] = useState({});
  const [srcImage, setSrcImage] = useState("");
  const [formUserValues, handleUserInputChange] = useForm(initialStateUser);
  const [isSwitchOn, setIsSwitchOn] = useState(true);
  const [isSwitchOnAllowPushNotification, setIsSwitchAllowPushNotification] =
    useState(true);

  const {
    dispatch: dispatchClient,
    state: stateClient,
    getAllClients,
    getClientData,
    getClientOfUser,
  } = useContext(ClientContext);

  const { selectedProducts, clients, clientData, clientDataOfUser } =
    stateClient;

  const [allowSelectClient, setAllowSelectClient] = useState(false);
  const [allowResetPassword, setAllowResetPassword] = useState(false);
  const [defaultValueSelectClient, setDefaultValueSelectClient] = useState(
    translate(DICTIONARY.PLACEHOLDER.SELECT_CLIENT, dictionary)
  );
  const inputPhoto = useRef(null);
  const inputUserClient = useRef(null);
  const [clientId, setClientId] = useState("");

  const onSwitchAction = () => {
    setIsSwitchOn(!isSwitchOn);
  };
  const onSwitchActionAllowPushNotification = () => {
    setIsSwitchAllowPushNotification(!isSwitchOnAllowPushNotification);
  };
  const handleInputPhoto = (ev) => {
    inputPhoto.current.click();
  };
  const loadClientProducts = async (ev, _clientId = "") => {
    resetSelectedProductsForUser();
    const clientId = _clientId === "" ? ev?.target?.value : _clientId;
    setClientId(clientId);
    if (ev) {
      handleUserInputChange(ev);
    }
    await getClientData(dispatchClient, clientId, token);
  };
  const handleChangeFile = (ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    const file = ev.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setSrcImage(imageUrl);
    setPhoto(file);
  };

  const handleEditUser = async (ev) => {
    ev.preventDefault();
    const {
      username,
      email,
      oldPassword,
      password,
      firstName,
      lastName,
      role,
      customerId,
      repeat_password,
      languageCode,
    } = formUserValues;

    let plainSelectedProducts = [];
    if (selectedProducts && selectedProducts.length > 0) {
      plainSelectedProducts = selectedProducts.map((_item) => _item.id);
    }

    const deletedProducts = plainSelectedProducts.filter(
      (_prodc) => !userData.products.includes(_prodc)
    );
    const data = {
      username: username ? username : userData.username,
      email: email ? email : userData.email,
      firstName: firstName ? firstName : userData.firstName,
      lastName: lastName ? lastName : userData.lastName,
      role: role ? role : userData.role,
      customerId: customerId ? customerId : userData.customerId,
      products: userData.products,
      deletedProducts,
      allowEmailNotification: isSwitchOn,
      allowPushNotification: isSwitchOnAllowPushNotification,
      languageCode: languageCode ? languageCode : userData.languageCode,
    };

    if (oldPassword !== "") {
      if (password === repeat_password) {
        const updatePasswordResponse = await updatePassword(
          dispatchUser,
          {
            password,
            oldPassword,
          },
          token,
          userId
        );
        if (!updatePasswordResponse.success) {
          Swal.fire({
            ...sweetAlertProps,
            text: `${updatePasswordResponse?.message}`,
            confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
            confirmButtonColor: COLORS.button,
          }).then((sweet) => {
            if (sweet.isConfirmed) {
              // navigate('/user', { replace: true });
            }
          });
          return;
        }
      } else {
        Swal.fire({
          ...sweetAlertProps,
          text: translate(DICTIONARY.MESSAGE.PASSWORDS_NOT_EQUAL, dictionary),
          confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
          confirmButtonColor: COLORS.button,
        }).then((sweet) => {
          if (sweet.isConfirmed) {
            // navigate('/user', { replace: true });
          }
        });
        return;
      }
    }

    const response = await editUser(dispatchUser, data, token, userId);
    if (response.success) {
      await uploadUserImage(userId, token, photo);
      Swal.fire({
        ...sweetAlertProps,
        text: translate(DICTIONARY.MESSAGE.USER_SAVE, dictionary),
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        if (sweet.isConfirmed) {
          // navigate('/user', { replace: true });
        }
      });
    } else {
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${response?.message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        // if (sweet.isConfirmed) {
        //     navigate('/user', { replace: true });
        // }
      });
    }
  };

  const handleResetPassword = async () => {
    const { username } = formUserValues;
    const _username = username ? username : userData.username;

    const response = await resetPassword(_username);
    if (response.success) {
      Swal.fire({
        ...sweetAlertProps,
        text: `${response?.message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        if (sweet.isConfirmed) {
          // navigate('/user', { replace: true });
        }
      });
    } else {
      Swal.fire({
        ...sweetAlertProps,
        icon: "error",
        text: `${response?.message}`,
        confirmButtonText: translate(DICTIONARY.BUTTON.ACCEPT, dictionary),
        confirmButtonColor: COLORS.button,
      }).then((sweet) => {
        // if (sweet.isConfirmed) {
        //     navigate('/user', { replace: true });
        // }
      });
    }
  };

  useEffect(() => {
    getAllRoles(dispatchRole, token);
  }, []);

  useEffect(() => {
    //Get data from user id
    const getUserDataAsync = async () => {
      const tokenFromSession = sessionStorage.getItem("token");
      const _user = await getUserDataFromContext(userId, tokenFromSession);
      const apiURL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_VERSION}`;
      const logoUrl = `${apiURL}/users/uploads/photo/${_user?.id}?token=${tokenFromSession}`;
      setSrcImage(logoUrl);

      let _isSwitchOn =
        _user && _user.allowEmailNotification
          ? _user.allowEmailNotification
          : false;

      const allowPushNotification =
        _user && _user?.allowPushNotification
          ? _user?.allowPushNotification
          : false;

      setIsSwitchOn(_isSwitchOn);
      setIsSwitchAllowPushNotification(allowPushNotification);
    };
    getUserDataAsync();

    dispatch(
      getCustomTemplate({
        id: userId,
        modal: MODAL_TYPES.PERMISSIONS_USERS,
      })
    );
  }, [userId]);

  useEffect(() => {
    const getUserFromToken = async () => {
      const tokenFromSession = sessionStorage.getItem("token");
      const userData = await getUserData(tokenFromSession);
      if (userData) {
        const allowMenuAdmin = userAccessManager(
          APP_SECTIONS.editSelectedClient,
          userData.role
        );
        const _allowResetPassword = userAccessManager(
          APP_SECTIONS.resetPassword,
          userData.role
        );
        setAllowResetPassword(_allowResetPassword);
        if (!allowMenuAdmin) {
          setDefaultValueSelectClient(
            userData && userData.customerId !== "" ? userData.customerId : "-1"
          );
          getClientData(dispatchClient, userData.customerId, token);
        }
        setAllowSelectClient(allowMenuAdmin);
      }
    };
    getUserFromToken();
  }, []);

  useEffect(() => {
    if (!(!allowSelectClient || isOwnProfile)) {
      getAllClients(token);
    }
    getClientOfUser(userId, token);
  }, []);
  const userDataProducts = userData ? userData.products : [];

  const handleOnclickShowPermissions = () => {
    dispatch(setShowModal(MODAL_TYPES.PERMISSIONS_USERS, true));
  };

  const handleCopyUser = (ev) => {
    const { role, customerId } = formUserValues;

    let plainSelectedProducts = [];
    if (selectedProducts && selectedProducts.length > 0) {
      plainSelectedProducts = selectedProducts.map((_item) => _item.id);
    }

    const deletedProducts = plainSelectedProducts.filter(
      (_prodc) => !userData.products.includes(_prodc)
    );

    const data = {
      username: "",
      email: "",
      firstName: "",
      lastName: "",
      role: role ? role : userData.role,
      customerId: customerId ? customerId : userData.customerId,
      products: userData.products,
      deletedProducts,
    };

    sessionStorage.setItem("u:start", JSON.stringify(data));

    dispatch(setCopyProfileData(data));
    //
    const tokenFromSession = sessionStorage.getItem("token");
    const urlUserCreate = `/user/create?_i=${tokenFromSession}&_s=841110&_l=1`;

    window.location.href = `${process.env.REACT_APP_BROWSER_ROUTE}${urlUserCreate}`;
    // navigate(urlUserCreate, { replace: false });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="section-name">
            <span className="material-icons-round section-icon">person</span>
            {isOwnProfile ? (
              <div>{translate(DICTIONARY.TITLE.USER_PROFILE, dictionary)}</div>
            ) : (
              <div>{translate(DICTIONARY.TITLE.USER_EDIT, dictionary)}</div>
            )}
          </div>
        </Col>
      </Row>
      <Row>
        <Col className="section-content mt-3 ml-2">
          <div className="subsection-name">
            {translate(DICTIONARY.TITLE.USER_DATA, dictionary)}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form className="mt-3" onSubmit={handleEditUser}>
            <Row className="user-row">
              <Col xs="12" sm="5" md="4" lg="3" xl="3" xxl="3" className="">
                <div
                  className={`avatar ${
                    isOwnProfile ? "avatar__ownProfile" : ""
                  }`}
                >
                  <div className="avatar-image">
                    {srcImage && <Image roundedCircle src={srcImage} />}
                  </div>
                  <div className="avatar-button">
                    <Form.Control
                      ref={inputPhoto}
                      style={{ display: "none" }}
                      type="file"
                      onChange={handleChangeFile}
                    />
                    <Button
                      variant="secondary"
                      className="avatar-change-button"
                      onClick={handleInputPhoto}
                    >
                      {translate(DICTIONARY.BUTTON.USER_ADD_PHOTO, dictionary)}
                    </Button>
                  </div>
                </div>
              </Col>
              <Col xs="12" sm="7" md="8" lg="9" xl="9" xxl="9">
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Select
                      defaultValue={defaultValueSelectClient}
                      name="customerId"
                      onChange={loadClientProducts}
                      disabled={!allowSelectClient || isOwnProfile}
                      ref={inputUserClient}
                    >
                      {!(!allowSelectClient || isOwnProfile) && clients && (
                        <option disabled>
                          {translate(
                            DICTIONARY.PLACEHOLDER.SELECT_CLIENT,
                            dictionary
                          )}
                        </option>
                      )}
                      {!(!allowSelectClient || isOwnProfile) && clients ? (
                        clients.map((client, index) => {
                          const { id, name } = client;
                          const isSelected = userData?.customerId
                            ? id === userData?.customerId
                            : false;
                          return (
                            <option
                              key={`client_opt_${index}_${id}`}
                              value={id}
                              selected={isSelected}
                            >
                              {name}
                            </option>
                          );
                        })
                      ) : (
                        <option
                          value={defaultValueSelectClient}
                          selected={true}
                        >
                          {clientData?.name ? clientData?.name : ""}
                        </option>
                      )}
                      {(!allowSelectClient || isOwnProfile) && (
                        <option value={clientDataOfUser?.id} selected={true}>
                          {clientDataOfUser?.name}
                        </option>
                      )}
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Select
                      defaultValue={translate(
                        DICTIONARY.PLACEHOLDER.USER_TYPE,
                        dictionary
                      )}
                      name="role"
                      onChange={handleUserInputChange}
                      disabled={isOwnProfile}
                    >
                      <option disabled>
                        {translate(
                          DICTIONARY.PLACEHOLDER.USER_TYPE,
                          dictionary
                        )}
                      </option>
                      {roles &&
                        roles.map((_role, index) => {
                          const { id, value } = _role;
                          const isSelected = userData?.role
                            ? _role.id === userData?.role
                            : false;
                          return (
                            <option
                              key={`role_opt_${index}_${id}`}
                              value={id}
                              selected={isSelected}
                            >
                              {value}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Select
                      defaultValue={translate(
                        DICTIONARY.PLACEHOLDER.LANGUAGE,
                        dictionary
                      )}
                      className=""
                      name="languageCode"
                      onChange={handleUserInputChange}
                    >
                      <option disabled>
                        {translate(DICTIONARY.PLACEHOLDER.LANGUAGE, dictionary)}
                      </option>
                      {languageOptions &&
                        languageOptions.map((languageItem, index) => {
                          const { id, value } = languageItem;
                          const isSelected = userData?.languageCode
                            ? id === userData?.languageCode
                            : false;
                          return (
                            <option
                              key={`language_option_${index}_${id}`}
                              value={id}
                              selected={isSelected}
                            >
                              {value}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Control
                      type="text"
                      placeholder={translate(
                        DICTIONARY.PLACEHOLDER.USER,
                        dictionary
                      )}
                      className=""
                      name="username"
                      defaultValue={username}
                      onInput={handleUserInputChange}
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="switch"
                      onChange={onSwitchAction}
                      id="switch-allowEmailNotification"
                      label={translate(
                        DICTIONARY.LABEL.SEND_NOTIFICATION_EMAIL,
                        dictionary
                      )}
                      checked={isSwitchOn}
                      name="allowEmailNotification"
                      // value={true}
                      className="input-switch"
                    />
                  </Col>
                  <Col>
                    <Form.Check
                      type="switch"
                      onChange={onSwitchActionAllowPushNotification}
                      id="switch-allowPushNotification"
                      label={translate(
                        DICTIONARY.LABEL.ALLOW_PUSH_NOTIFICATION,
                        dictionary
                      )}
                      checked={isSwitchOnAllowPushNotification}
                      name="allowPushNotification"
                      //   value={true}
                      className="input-switch"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Control
                      type="text"
                      placeholder={translate(
                        DICTIONARY.PLACEHOLDER.NAME,
                        dictionary
                      )}
                      className=""
                      name="firstName"
                      defaultValue={firstName}
                      onInput={handleUserInputChange}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder={translate(
                        DICTIONARY.PLACEHOLDER.SURNAME,
                        dictionary
                      )}
                      className=""
                      name="lastName"
                      defaultValue={lastName}
                      onInput={handleUserInputChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                    <Form.Control
                      type="email"
                      placeholder={translate(
                        DICTIONARY.PLACEHOLDER.EMAIL,
                        dictionary
                      )}
                      name="email"
                      defaultValue={email}
                      onInput={handleUserInputChange}
                    />
                  </Col>
                  {allowResetPassword && !isOwnProfile && (
                    <Col xs="12" sm="6" md="6" lg="5" xl="5" xxl="4">
                      <Button
                        variant="secondary"
                        type="button"
                        size="sm"
                        onClick={handleResetPassword}
                        className="w-100 button-form button-form__inline"
                      >
                        {translate(
                          DICTIONARY.BUTTON.RESTORE_PASSWORD,
                          dictionary
                        )}
                      </Button>
                    </Col>
                  )}
                </Row>
                {isOwnProfile && (
                  <>
                    <Row>
                      <Col>
                        <Form.Control
                          type="password"
                          placeholder={translate(
                            DICTIONARY.PLACEHOLDER.OLD_PASSWORD,
                            dictionary
                          )}
                          // placeholder="***********"
                          name="oldPassword"
                          onInput={handleUserInputChange}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="password"
                          placeholder={translate(
                            DICTIONARY.PLACEHOLDER.NEW_PASSWORD,
                            dictionary
                          )}
                          name="password"
                          onInput={handleUserInputChange}
                        />
                      </Col>
                      <Col>
                        <Form.Control
                          type="password"
                          placeholder={translate(
                            DICTIONARY.PLACEHOLDER.REPEAT_PASSWORD,
                            dictionary
                          )}
                          defaultValue={password}
                          onInput={handleUserInputChange}
                          name="repeat_password"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="float-end resetPassword-tooltip">
                          {translate(
                            DICTIONARY.LABEL.RESET_PASSWORD_TOOLTIP,
                            dictionary
                          )}
                          .
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </Row>

            {userData && (
              <ProductAvailableList
                loadByClient={true}
                userProducts={userDataProducts}
                clientId={userData.customerId ? userData.customerId : clientId}
                isOwnProfile={isOwnProfile}
                isUsedInClient={false}
                isUsedInUser={true}
                // isAdmin={isAdmin}
              />
            )}

            <Row></Row>
            <Button
              variant="secondary"
              type="submit"
              className="w-25 float-end button-form button"
            >
              {translate(DICTIONARY.BUTTON.SAVE, dictionary)}
            </Button>
            {isAdmin && (
              <>
                <Button
                  variant="secondary"
                  className="w-25 float-end button-form button"
                  onClick={handleOnclickShowPermissions}
                >
                  {translate(DICTIONARY.BUTTON.SHOW_PERMISSION, dictionary)}
                </Button>
                <ModalJSONPermissions
                  id={userId}
                  modalId={MODAL_TYPES.PERMISSIONS_USERS}
                  title={translate(
                    DICTIONARY.TITLE.USER_PERMISSION,
                    dictionary
                  )}
                  icon={`people`}
                />
                {!isOwnProfile && (
                  <Button
                    variant="secondary"
                    className="w-25 float-end button-form "
                    onClick={handleCopyUser}
                  >
                    {translate(DICTIONARY.BUTTON.COPY_PROFILE, dictionary)}
                  </Button>
                )}
              </>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default EditUser;
