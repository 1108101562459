import getTokenFromUrl from "./getTokenFromUrl";

const getToken = () => {
    const parsedToken = JSON.parse(sessionStorage.getItem('ideauto_u'));
    let _token = null;
    if (parsedToken) {
        _token = parsedToken.token;
    }
    _token = _token ? _token : null;
    if (!_token) {
        _token = getTokenFromUrl();
    }
    return _token;
}

export default getToken;