/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 28/02/2022                                        
* @Description User context for user actions                                                           
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/

import React, { createContext, useReducer } from 'react';
import { fetchWithToken, uploadImage } from '../helpers/fetch';
import { types } from '../types/types';
import { redirectToNoAuthorizedURL } from '../utils/redirectToNoAuthorizedURL';

const ClientContext = createContext([]);

const initialState = {
    clients: [],
    selectedProducts: [],
    clientData: {},
    deletedProducts: [],
    filteredClients: [],
    selectedClients: [],
    clientDataOfUser: {}
}

const clientReducer = (state = initialState, action) => {

    const { selectedProducts, selectedClients } = state;
    const _selectedClients = selectedClients ? selectedClients : [];
    const _selectedProducts = selectedProducts ? selectedProducts : [];
    switch (action.type) {
        case types.clientOfUser:
            return {
                ...state,
                clientDataOfUser: action.payload
            }
        case types.clientSetSelect:
            let toggledClients = [];
            const existSelectedClient = _selectedClients?.filter((_item) => _item === action.payload);
            if (existSelectedClient && existSelectedClient.length > 0) {
                toggledClients = _selectedClients.filter((_client) => _client !== action.payload);
            } else {
                toggledClients = [
                    ..._selectedClients,
                    action.payload
                ];
            }
            return {
                ...state,
                selectedClients: toggledClients
            }
        case types.clientAddAllProducts:
            return {
                ...state,
                selectedProducts: action.payload
            }
        case types.clientAddOneProduct:
            const _selectedProducts2 = [
                ..._selectedProducts,
                action.payload
            ];
            return {
                ...state,
                selectedProducts: _selectedProducts2

            }
        case types.clientGetAll:
            return {
                ...state,
                clients: action.payload
            }
        case types.clientGetOne:
            return {
                ...state,
                clientData: action.payload
            }
        case types.clientRemoveOneProduct:
            let filteredProducts = selectedProducts.filter((_prodId) => _prodId.id !== action.payload);
            return {
                ...state,
                selectedProducts: filteredProducts
            }
        case types.clientToggleProduct:
            let toggledProducts = [];
            const existProduct = _selectedProducts.filter((_item) => _item.id === action.payload.id);
            if (existProduct && existProduct.length > 0) {
                toggledProducts = _selectedProducts.filter((_prodId) => _prodId.id !== action.payload.id);
            } else {
                toggledProducts = [
                    ..._selectedProducts,
                    action.payload
                ];
            }

            return {
                ...state,
                selectedProducts: toggledProducts
            }
        case types.clientFilter:
            const search = action.payload;
            let _filteredClients = state.filteredClients;

            if (search !== '') {
                _filteredClients = filterClients(state.clients, search);
            } else {
                _filteredClients = [];
            }
            return {
                ...state,
                filteredClients: _filteredClients
            }
        case types.clientResetFilter:
            return {
                ...state,
                filteredClients: []
            }
        case types.clientResetData:
            return {
                ...state,
                clientData: {}
            }
        default:
            return state;
    }
}

const setSelectedClients = (dispatch, clientId) => {
    dispatch({
        type: types.clientSetSelect,
        payload: clientId
    })
}

const filterClients = (clients, search) => {
    // id, fullName, email, roles, checked
    const searchLowerCase = search.toLowerCase();
    return clients?.filter((client) => client?.name.toLowerCase().includes(searchLowerCase));
}
const setClientGetAll = (clients) => ({
    type: types.clientGetAll,
    payload: clients
});
const removeProductClient = (dispatch, productId) => {
    dispatch({
        type: types.clientRemoveOneProduct,
        payload: productId,
    })
}

const addProductToClient = (dispatch, product) => {
    dispatch({
        type: types.clientAddOneProduct,
        payload: product,
    })
}
const toggleProductClient = (dispatch, product) => {
    dispatch({
        type: types.clientToggleProduct,
        payload: product
    })
}
const deleteClient = async (dispatch, token, clientId) => {
    try {
        // const customersFromSession = JSON.parse(sessionStorage.getItem('customers'));

        const resp = await fetchWithToken(
            `customers/${clientId}`,
            {},
            'DELETE',
            token
        );
        const body = await resp.json();
        if (body.success) {
            return body.success;
        } else {
            redirectToNoAuthorizedURL(resp);
        }

    } catch (error) {
        console.error(error);
    }
}
//upload logo of client
const uploadClientImage = async (clientId, token, file) => {
    try {
        const resp = await uploadImage(
            `customers/uploads/photo/${clientId}`,
            file,
            token
        );
        const body = await resp.json();

        if (body.success) {
            // return customers;
        } else {
            return '';
        }
    } catch (error) {
        console.error(error);
    }
}

const createClient = async (data, token) => {
    try {
        const resp = await fetchWithToken(
            `customers`,
            data,
            'POST',
            token
        );
        const body = await resp.json();
        if (body.success) {
        } else {
            redirectToNoAuthorizedURL(resp);

        }

        return body;

        // if (body.success) {
        //     const { data } = body;
        //     const { customer } = data;
        //     return customer;
        // } else {
        // }
    } catch (error) {
        console.error(error);
    }

}

const editClient = async (dispatch, data, token, clientId) => {
    try {
        const resp = await fetchWithToken(
            `customers/${clientId}`,
            data,
            'PATCH',
            token
        );
        const body = await resp.json();

        if (body.success) {
            // dispatch({
            //     type: types.clientResetData
            // })
            const { data } = body;
            const { customer } = data;
            const customerProducts = customer?.products.map((_product) => {
                return {
                    id: _product
                }
            });
            dispatch({
                type: types.clientAddAllProducts,
                payload: customerProducts
            });
            dispatch({
                type: types.clientGetOne,
                payload: customer
            });
        } else {
            redirectToNoAuthorizedURL(resp);
        }
        return body;
    } catch (error) {
        console.error(error);
    }
}

const updateRedirect = async (dispatch, data, token, clientId) => {
    try {
        const resp = await fetchWithToken(
            `customers/redirect/${clientId}`,
            data,
            'PATCH',
            token
        );
        const body = await resp.json();

        if (body.success) {
        } else {
            redirectToNoAuthorizedURL(resp);
        }
        return body;
    } catch (error) {
        console.error(error);
    }
}

const ClientProvider = ({ children }) => {
    const [state, dispatch] = useReducer(clientReducer, []);

    const getAllClients = async (token, query = '', limit = '100', page = '') => {
        try {
            // const customersFromSession = JSON.parse(sessionStorage.getItem('customers'));
            const customersFromSession = false;
            if (customersFromSession) {
                // dispatch(setClientGetAll(customersFromSession));
            } else {
                const resp = await fetchWithToken(
                    `customers?query=${query}&limit=${limit}&page=${page}`,
                    {},
                    'GET',
                    token
                );
                const body = await resp.json();
                if (body.success) {
                    const { data: { customers: _customers } } = body;
                    dispatch(setClientGetAll(_customers));
                    sessionStorage.setItem('customers', JSON.stringify(_customers));
                    return _customers;
                } else {
                    redirectToNoAuthorizedURL(resp);
                }
            }

        } catch (error) {
            console.error(error);
        }
    }

    const getClientData = async (dispatch, clientId, token) => {
        try {
            const resp = await fetchWithToken(
                `customers/${clientId}`,
                {},
                'GET',
                token
            );
            const body = await resp.json();

            if (body.success) {
                const { data } = body;
                const { customer } = data;
                const customerProducts = customer?.products.map((_product) => {
                    return {
                        id: _product
                    }
                });
                dispatch({
                    type: types.clientAddAllProducts,
                    payload: customerProducts
                });
                dispatch({
                    type: types.clientGetOne,
                    payload: customer
                });

                return customer;
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getClientOfUser = async (userId, token) => {
        try {
            const resp = await fetchWithToken(
                `customers/user/${userId}`,
                {},
                'GET',
                token
            );
            const body = await resp.json();

            if (body.success) {
                const { data } = body;
                const { customer } = data;


                dispatch({
                    type: types.clientOfUser,
                    payload: customer
                });

                return customer;
            } else {
                redirectToNoAuthorizedURL(resp);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const resetClientFilters = () => {
        dispatch({
            type: types.clientResetFilter
        })
    }

    const value = {
        state,
        dispatch,
        getAllClients,
        uploadClientImage,
        createClient,
        addProductToClient,
        removeProductClient,
        getClientData,
        toggleProductClient,
        editClient,
        updateRedirect,
        setSelectedClients,
        deleteClient,
        resetClientFilters,
        getClientOfUser
    };

    return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
}

// const useClient = () => {
//     const context = useContext(ClientContext);

//     if (context === undefined) {
//         throw new Error('useClient must be used with ClientProvider');
//     }
//     return context;
// }


export {

    ClientContext,
    ClientProvider
}