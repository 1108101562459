import ROLES from "../../../constants/roles";

export const userAccessManager = (section, role) => {
    let answer = false;
    switch (role) {
        case ROLES.USER:

            if (section !== APP_SECTIONS.buttonAdmin &&
                section !== APP_SECTIONS.deleteCreateCustomer &&
                section !== APP_SECTIONS.clients &&
                section !== APP_SECTIONS.editSelectedClient &&
                section !== APP_SECTIONS.resetPassword) {
                answer = true;
            }
            break;
        case ROLES.CUSTOMER:
            if (
                section === APP_SECTIONS.buttonAdmin ||
                section === APP_SECTIONS.deleteCreateCustomer ||
                section === APP_SECTIONS.resetPassword) {
                answer = true;
            }
            break;

        case ROLES.ADMIN:
            answer = true;
            break;
        default:
            break;
    }

    return answer;
}


export const APP_SECTIONS = {
    buttonAdmin: 'Menu admin for clients/users',
    clients: 'Show clients',
    deleteCreateCustomer: 'Manage create/delete clients',
    editSelectedClient: 'Allow select client for user',
    resetPassword: 'Allow reset password without knowing old password',
    showProductsAsClient: 'Show products as client, in order to access them'
};