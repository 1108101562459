/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 26/08/2022                                        
* @Description Reducer for contact actions                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/


import { types } from "../types/types";

const initialState = {
    data: {}
}
// 22680ff2-517b-44bd-b8a1-d1f75fe8c91f
export const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.contactCreate:
            return state;
        case types.contactDelete:
            return state;
        case types.contactGetDefault:
            return {
                ...state,
                data: action.payload
            }
        case types.contactGetOne:
            return state;
        case types.contactOverwrite:
            return state;
        case types.contactResetData:
            return initialState;
        default:
            return state;
    }
}