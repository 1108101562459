/**
* ----------------------------------------------------------------------------------------
* @Author ismael.gomez@babooni.com                                                        
* @Date 05/09/2022                                        
* @Description Date picker                                                          
* ----------------------------------------------------------------------------------------
* @Inputs NOTHING                                                                   
*  - @param                                                                               
* ----------------------------------------------------------------------------------------
* @Outputs NOTHING                                                                  
*  - @param                                                                               
*-----------------------------------------------------------------------------------------
*/


import React from 'react';
import { createStaticRanges, DateRangePicker } from 'react-date-range';
import { useDispatch, useSelector } from 'react-redux';
import { setDatePickerSetEndDate, setDatePickerSetStartDate } from '../../actions/datePicker';
import * as rdrLocales from 'react-date-range/dist/locale';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DATES_VARIABLES } from '../../constants/filter';

moment.locale('es');
// const today = 
const defineds = {
    // startOfYear: moment().startOf('year')._d,
    // endOfYear: moment().endOf('year')._d,

    startOfToday: moment().startOf('day')._d,
    endOfToday: moment().endOf('day')._d,

    startOfYesterday: moment().subtract(1, 'days').startOf('day')._d,
    endOfYesterday: moment().subtract(1, 'days').endOf('day')._d,

    startOfWeek: moment().startOf('isoWeek')._d,
    endOfWeek: moment().endOf('isoWeek')._d,

    startOfLastWeek: moment().subtract(7, 'days').startOf('isoWeek')._d,
    endOfLastWeek: moment().subtract(7, 'days').endOf('isoWeek')._d,

    startOfMonth: moment().startOf('month')._d,
    endOfMonth: moment().endOf('month')._d,

    startOfLastMonth: moment().subtract(1, 'month').startOf('month')._d,
    endOfLastMonth: moment().subtract(1, 'month').endOf('month')._d,
};

const sideBarOptions = () => {
    const customDateObjects = [
        // {
        //     label: 'Todo el año',
        //     range: () => ({
        //         startDate: defineds.startOfYear,
        //         endDate: defineds.endOfYear
        //     })
        // },
        {
            label: 'Hoy',
            range: () => ({
                startDate: defineds.startOfToday,
                endDate: defineds.endOfToday
            })
        },
        {
            label: 'Ayer',
            range: () => ({
                startDate: defineds.startOfYesterday,
                endDate: defineds.endOfYesterday
            })
        },
        {
            label: 'Esta semana',
            range: () => ({
                startDate: defineds.startOfWeek,
                endDate: defineds.endOfWeek
            })
        },
        {
            label: 'Semana anterior',
            range: () => ({
                startDate: defineds.startOfLastWeek,
                endDate: defineds.endOfLastWeek
            })
        },
        {
            label: 'Este mes',
            range: () => ({
                startDate: defineds.startOfMonth,
                endDate: defineds.endOfMonth
            })
        },
        {
            label: 'Mes anterior',
            range: () => ({
                startDate: defineds.startOfLastMonth,
                endDate: defineds.endOfLastMonth
            })
        },
    ];

    return customDateObjects;
};

export const DatePicker = (props) => {
    const {
        callBackFilter,
        extraClass,
        attribute,
        show

    } = props;
    const dispatch = useDispatch();
    const { datePicker } = useSelector((state) => state);
    const { startDate, endDate, key } = datePicker;
    // const momentDate = moment()._d;
    const handleSelectDate = (item) => {
        const { startDate, endDate } = item.selection;
        dispatch(setDatePickerSetStartDate(startDate));
        dispatch(setDatePickerSetEndDate(endDate));
        // dispatch(setDatePickerToggle(false));
        callBackFilter({
            attribute,
            search: {
                startDate: `${moment(startDate).format(DATES_VARIABLES.dateFormat)}${DATES_VARIABLES.fromHour}`,
                endDate: `${moment(endDate).format(DATES_VARIABLES.dateFormat)}${DATES_VARIABLES.endHour}`,
            }
        });
    }

    const sideBar = sideBarOptions();
    const staticRanges = [
        ...createStaticRanges(sideBar)
    ];

    const minDate = moment('2022-08-29').startOf('day')._d;
    // const minDate = moment('2021-01-01').startOf('day')._d;
    const maxDate = moment().endOf('day')._d;

    const rangeColors = [
        '#0d6efd'
    ]

    return (
        <>
            {
                show &&
                <DateRangePicker
                    staticRanges={staticRanges}
                    onChange={handleSelectDate}
                    editableDateInputs={false}
                    showDateDisplay={true}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={true}
                    months={2}
                    ranges={[{
                        startDate,
                        endDate,
                        key
                    }]}
                    direction="horizontal"
                    preventSnapRefocus={true}
                    calendarFocus="backwards"
                    shownDate={new Date()}
                    showMonthArrow={true}
                    weekStartsOn={1}
                    locale={rdrLocales.es}
                    className={`datePicker  ${extraClass}`}
                    maxDate={maxDate}
                    minDate={minDate}
                    rangeColors={rangeColors}
                />
            }
        </>
    )
}
